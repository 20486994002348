import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
import { StyledFooter } from '../../style';
import { useRootStore } from '~/stores/RootStore';
import useSettingsStore from '../../store/useSettingsStore';

const PinFooter = (): JSX.Element => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  const {
    pinStore: { isDisableButton, changePinCode },
  } = useSettingsStore();
  return (
    <StyledFooter>
      <Button
        onClick={changePinCode}
        disabled={isDisableButton || isTemporaryTokenAuth}
      >
        Изменить
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(PinFooter);
