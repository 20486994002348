/** библиотеки */
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** компоненты библиотеки */
import {
  Button,
  ButtonStyleTypes,
  Snoska,
  defaultTheme,
} from 'cordis-core-ui-planeta';

/** константы */
import { desktop940 } from '~/components/Grid/constants';
import { ACTION_MAP } from '~/components/Blocks/Templates/Pab2c/Devices/constants';

/** типы */
import { DeviceCardWizardPurchaseFooterProps } from './types';
/** styles */
import { StyledFooter } from './style';
/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Блок pab2cDevices. SidePageFooter выкупа оборудования
 */
const DeviceCardWizardPurchaseFooter: FC<DeviceCardWizardPurchaseFooterProps> = ({
  device,
  devicePurchase,
}: DeviceCardWizardPurchaseFooterProps) => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();
  const isMobile = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const getAction = (): string => {
    const ownershipKey = device?.ownership;
    const sizeKey = isMobile ? 'desktop' : 'mobile';
    return ACTION_MAP[ownershipKey][sizeKey];
  };

  return (
    <StyledFooter>
      <Button
        styleType={ButtonStyleTypes.MAIN}
        onClick={() => devicePurchase()}
        disabled={isTemporaryTokenAuth}
      >
        {getAction()}
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(DeviceCardWizardPurchaseFooter);
