/** библиотеки */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';
/** компоненты библиотеки */
import {
  H2,
  H3,
  Text,
  Icons,
  Icon,
  defaultTheme,
  Button,
  ButtonStyleTypes,
  Snoska,
  Loader,
} from 'cordis-core-ui-planeta';
/** компоненты */
import LinkWrapper from '~/components/LinkWrapper';
/** стили */
import { StyledDeferredPayment } from './DeferredPayment.style';
/** константы */
import {
  desktop1100,
  desktop1280,
  desktop350,
  desktop700,
  desktop940,
} from '~/components/Grid/constants';
/** утилиты */
import { pluralizeAll } from '~/utils/utils';
/** api */
import { startContractTrustedResume } from '~/api/api';
/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Блок "Отложенный платёж"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=594244114
 */
const DeferredPayment: React.FC = () => {
  // Для чтения параметров из url
  const router = useRouter();
  const {
    /** Данные услуги "Отложенный платёж" */
    deferredPaymentStore: {
      isDeferredPayment,
      isActivated,
      isAvailable,
      isUsed,
      durationHours,
      durationSecondsLeft,
      getContractTrustedResume,
    },
    autoPaymentInfoStore: { autoPaymentData, isLoadingAutoPayment },
    authStore: { isAuth, isTemporaryTokenAuth },
    contractStateStore: { updateAuthCondition },
  } = useRootStore();

  const [isLoadingDefPayment, setIsLoadingDefPayment] = useState<boolean>(
    false,
  );

  /** Оставшиеся часы */
  const [hours, setHours] = useState<number>(null);

  /** Оставшиеся минуты */
  const [minutes, setMinutes] = useState<number>(null);

  /** Размеры экрана */
  const isDesktop350 = useMediaQuery({
    query: `(min-width: ${desktop350}px)`,
  });
  const isDesktop700 = useMediaQuery({
    query: `(min-width: ${desktop700}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  // Активация услуги "Отложенный платёж"
  const activateDeferredPayment = async () => {
    try {
      setIsLoadingDefPayment(true);
      const start = await startContractTrustedResume();
      if (start.status && start.status === 200) {
        getContractTrustedResume();
        updateAuthCondition();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingDefPayment(false);
    }
  };

  // Вычисление оставшегося времени
  const duration = () => {
    const allMinutes = durationSecondsLeft / 60;
    setHours(Math.trunc(allMinutes / 60));
    setMinutes(Math.trunc(allMinutes % 60));
  };

  useEffect(() => {
    if (isActivated) duration();
  }, [isActivated]);

  // Вывод оставшегося времени
  const timeOutput = `${hours ? `${hours} ч` : ''} ${
    minutes ? `${minutes} мин` : ''
  }`;

  const openWizard = () => {
    router.replace(
      {
        query: { ...router.query, showAutopayment: true },
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  const isShowBanner = !isAuth || !durationHours;
  /** Доступность ОП для подключения сейчас */
  const canBeActivatedNow = isAvailable && !isActivated;
  /** Доступность ОП для подключения на продукте:
   * то есть ОП не может быть подключён сейчас из-за состояния договора,
   * но при наступлении условий может быть активирован в рамках данного продукта
   */
  const canBeActivatedInProduct =
    !isAvailable && !isActivated && Boolean(durationHours);

  const StatusIcon = () => {
    switch (true) {
      case isActivated:
        return <Icons.OkPlanetaIcon />;
      case isUsed:
        return <Icon icon={<Icons.OkIcon />} disabled />;
      case canBeActivatedInProduct:
        return <Icons.OkIcon />;
      case !isDeferredPayment:
        return <Loader small />;
      default:
        return <Icons.OkIcon />;
    }
  };

  const getStatusText = () => {
    switch (true) {
      case isActivated:
        return 'Активирован';
      case isUsed:
        return 'Уже воспользовались';
      case canBeActivatedInProduct:
        return 'Доступен на вашем продукте';
      case canBeActivatedNow:
        return 'Доступен';
      default:
        return 'Доступен на вашем продукте';
    }
  };

  return (
    <StyledDeferredPayment
      isShowBanner={isShowBanner}
      isActivated={isActivated}
    >
      {isDesktop1280 ? <H2>Отложенный платёж</H2> : <H3>Отложенный платёж</H3>}
      {isShowBanner ? (
        <Text lineHeight="24px" className="deferred-payment__unauthorized-text">
          Услуга отложенного платежа позволит вам на короткий срок возобновить
          пользование сервисами Планеты.
        </Text>
      ) : (
        <>
          <Text lineHeight="24px">
            Пользуйтесь всеми сервисами Планеты даже при отрицательном балансе
          </Text>
          <div className="deferred-payment__block-wrapper">
            <div className="deferred-payment__block-wrapper__block">
              <Text color={defaultTheme.colors.shadow} lineHeight="24px">
                Отложенный платёж
              </Text>
              <div className="deferred-payment__block-wrapper__block__status">
                <StatusIcon />
                <Text
                  color={
                    isActivated
                      ? defaultTheme.colors.planeta
                      : defaultTheme.colors.shadow
                  }
                  lineHeight="24px"
                >
                  {getStatusText()}
                </Text>
              </div>
            </div>
            <div className="deferred-payment__block-wrapper__block duration">
              <Text color={defaultTheme.colors.shadow} lineHeight="24px">
                {isActivated ? 'До окончания' : 'Срок действия'}
              </Text>
              <H3>
                {isUsed && 'Закончен'}
                {isActivated && timeOutput}
                {!isUsed &&
                  !isActivated &&
                  pluralizeAll(durationHours, ['час', 'часа', 'часов'])}
              </H3>
            </div>
          </div>
          <div className="deferred-payment__button-block-wrapper">
            <div className="deferred-payment__button-block-wrapper__button-block">
              {isActivated || isUsed ? (
                <LinkWrapper href="/payment">
                  <Button
                    className="deferred-payment__button-block-wrapper__balance"
                    styleType={ButtonStyleTypes.SECONDARY}
                  >
                    {isDesktop350 ? 'Пополнить баланс' : 'Пополнить'}
                  </Button>
                </LinkWrapper>
              ) : (
                <Button
                  className="deferred-payment__button-block-wrapper__button"
                  styleType={ButtonStyleTypes.SECONDARY}
                  onClick={activateDeferredPayment}
                  loading={!isDeferredPayment || isLoadingDefPayment}
                  disabled={canBeActivatedInProduct || isTemporaryTokenAuth}
                >
                  Активировать
                </Button>
              )}
              {!autoPaymentData?.isActive && (
                <Button
                  className="deferred-payment__button-block-wrapper__autopayment"
                  styleType={ButtonStyleTypes.SECONDARY}
                  onClick={openWizard}
                  loading={isLoadingAutoPayment}
                >
                  {!isDesktop700 || (isDesktop940 && !isDesktop1100)
                    ? 'Автоплатёж'
                    : 'Подключить автоплатёж'}
                </Button>
              )}
            </div>
            <Snoska>
              После активации услуги плата за&nbsp;обслуживание списывается
              в&nbsp;стандартном объёме.
              <br />
              Пополните счёт до&nbsp;истечения срока отложенного платежа, чтобы
              не&nbsp;прекращать обслуживание.
            </Snoska>
          </div>
        </>
      )}
    </StyledDeferredPayment>
  );
};

export default observer(DeferredPayment);
