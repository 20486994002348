/** библиотеки */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** компоненты библиотеки */
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import { desktop940 } from '~/components/Grid/constants';
import { ACTION_MAP, OWNERSHIP_CODES } from '../constants';

/** интерфейсы */
import { DevicePurchasesWizardFooterProps } from './types';

/** стили */
import { StyledFooter } from './style';
/** stores */
import { useRootStore } from '~/stores/RootStore';
/**
 * Блок pab2cDevices. SidePageFooter покупки оборудования/погашения рассрочки
 */
const DevicePurchasesWizardFooter: FC<DevicePurchasesWizardFooterProps> = ({
  device,
  devicePurchase,
  deviceOwnership,
  isPurchaseDevice,
  deviceLeasePurchaseCheckResult,
  isLoading,
  isBoughtAgreement,
}: DevicePurchasesWizardFooterProps) => {
  const {
    authStore: { auth, isTemporaryTokenAuth },
  } = useRootStore();
  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Баланс больше чем цена оборудования */
  const isBalanceMoreThanPrice = isPurchaseDevice.isLease
    ? auth.balance - deviceLeasePurchaseCheckResult?.leaseAnnuity >= 0
    : auth.balance - device?.purchasePrice >= 0;

  /** Текст для кнопки */
  const buttonText = () => {
    if (isPurchaseDevice.isLease)
      return isDesktop940
        ? ACTION_MAP.BuyInLeasing.desktop
        : ACTION_MAP.BuyInLeasing.mobile;
    if (deviceOwnership === OWNERSHIP_CODES.LEASING)
      return isDesktop940
        ? ACTION_MAP.Leasing.desktop
        : ACTION_MAP.Leasing.mobile;
    if (deviceOwnership === OWNERSHIP_CODES.BOUGHT_LEASING)
      return isDesktop940
        ? ACTION_MAP.BoughtLeasing.desktop
        : ACTION_MAP.BoughtLeasing.mobile;
    return '';
  };

  return (
    <StyledFooter>
      <Button
        className="device-purchases__buy-button"
        disabled={
          !isBalanceMoreThanPrice || !isBoughtAgreement || isTemporaryTokenAuth
        }
        loading={isLoading}
        onClick={() => devicePurchase(isPurchaseDevice.isLease)}
      >
        {buttonText()}
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(DevicePurchasesWizardFooter);
