import { BLOCK_NAMES } from '~/constants/common';

/** Каникулы */
export const VACATION_BLOCKS = [
  BLOCK_NAMES.pab2cContractState,
  BLOCK_NAMES.pab2cContract,
  BLOCK_NAMES.pab2cSubscriptions,
  BLOCK_NAMES.vacation,
];
/** Баланс */
export const PAB2C_BALANCE_BLOCKS = [
  BLOCK_NAMES.pab2cContract,
  BLOCK_NAMES.pab2cSubscriptions,
  BLOCK_NAMES.pab2cContractState,
];
/** Оборудование */
export const PAB2C_DEVICES_BLOCKS = [
  BLOCK_NAMES.pab2cDevices,
  BLOCK_NAMES.pab2cSubscriptions,
];
/** Отложенный платёж */
export const DEFERRED_PAYMENT_BLOCKS = [
  BLOCK_NAMES.pab2cContract,
  BLOCK_NAMES.contractTrusted,
];
export const CONTRACT_INFO_BLOCKS = [
  BLOCK_NAMES.pab2cContract,
  BLOCK_NAMES.pab2cInternet,
  BLOCK_NAMES.pab2cSubscriptions,
];
export const PRODUCT_HISTORY_BLOCKS = [BLOCK_NAMES.pab2cContract];
export const SUSPENDED_CONDITION_BLOCKS = [
  BLOCK_NAMES.pab2cContract,
  BLOCK_NAMES.pab2cContractState,
  BLOCK_NAMES.pab2cSubscriptions,
  BLOCK_NAMES.vacation,
];
export const CONTRACT_STATE_BLOCKS = [
  BLOCK_NAMES.pab2cContractState,
  BLOCK_NAMES.pab2cContract,
  BLOCK_NAMES.pab2cDevices,
  BLOCK_NAMES.pab2cSubscriptions,
  BLOCK_NAMES.pab2cInternet,
  BLOCK_NAMES.pab2cVoice,
  BLOCK_NAMES.vacation,
  BLOCK_NAMES.chatlesExp,
  BLOCK_NAMES.devices,
  BLOCK_NAMES.deviceList,
  BLOCK_NAMES.igrovoy,
  BLOCK_NAMES.myTV,
  BLOCK_NAMES.softline,
];
export const SUMMARY_DATA_BLOCKS = [
  BLOCK_NAMES.pab2cContract,
  BLOCK_NAMES.pab2cInternet,
  BLOCK_NAMES.pab2cVoice,
  BLOCK_NAMES.pab2cSettings,
  BLOCK_NAMES.pab2cSettings,
  BLOCK_NAMES.productSelector,
  BLOCK_NAMES.productSwitcher,
  BLOCK_NAMES.chatlesExp,
  BLOCK_NAMES.myTV,
  BLOCK_NAMES.summary,
];
export const TV_CHANNEL_AND_PACKAGES_DATA_BLOCKS = [
  BLOCK_NAMES.myTV,
  BLOCK_NAMES.pab2cContract,
];
export const TV_DATA_BLOCKS = [BLOCK_NAMES.myTV];
export const ALLOWED_TARIFF_BLOCKS = [
  BLOCK_NAMES.pab2cSubscriptions,
  BLOCK_NAMES.productSelector,
  BLOCK_NAMES.productSwitcher,
  BLOCK_NAMES.summary,
];
export const VOICE_BLOCKS = [
  BLOCK_NAMES.pab2cVoice,
  BLOCK_NAMES.pab2cSubscriptions,
];
export const PAYMENT_BLOCKS = [BLOCK_NAMES.autoPayment, BLOCK_NAMES.payment];
export const AUTO_PAYMENT_DATA_BLOCKS = [
  BLOCK_NAMES.contractTrusted,
  BLOCK_NAMES.pab2cContract,
  BLOCK_NAMES.paymentResult,
  BLOCK_NAMES.autoPayment,
];
export const SBP_BANKS_BLOCKS = [
  BLOCK_NAMES.autoPayment,
  BLOCK_NAMES.payment,
  BLOCK_NAMES.paymentResult,
];
export const SBP_BANKS_BLOCKS_NOT_AUTH = [
  BLOCK_NAMES.payment,
  BLOCK_NAMES.paymentResult,
];
export const SOFTLINE_BLOCKS = [
  BLOCK_NAMES.softline,
  BLOCK_NAMES.pab2cSoftline,
];

// todo: подумать над маппингом когда наберётся примеров
const checkDataFetching = async (
  condition: boolean,
  blocks: BLOCK_NAMES[],
  blockTemplates: string[],
  fetch: () => Promise<void>,
): Promise<void> => {
  if (!condition) return;
  if (blocks.some((item) => blockTemplates.includes(item))) await fetch();
};

export default checkDataFetching;
