import { colors } from 'cordis-core-ui-planeta/dist/themes/default';
import { Block } from '~/interfaces/BlockInterface';
import { TypeOfApplication } from '~/components/ConnectionWizard/constants';

/** поля блока */
export interface BannerFields {
  body?: string;
  header?: string;
  imgLink: string;
  smallImgLink?: string;
  title?: string;
  isCenterImg?: boolean;
  link?: string;
  size?: number;
  borderRadius?: string;
  textColor?: colors;
  /** стиль "story" для заголовков */
  isStoriesHeader?: boolean;
  platform?: string;
  target?: string;
  headerImgLink?: string;
  headerImgSize?: HeaderImgSize;
  buttonSettings?: ButtonSettings;
  autoText?: string;
  channelsLength: number;
}

export interface HeaderImgSize {
  height: number;
  width: number;
}

export interface ButtonSettings {
  buttonText?: string;
  buttonWidget?: buttonWidgetTypes;
  buttonLink?: string;
  buttonTextColor?: string;
  buttonBorderColor?: string;
  buttonBackgroundColor?: string;
  connectionParam?: typeof TypeOfApplication;
}

/** поля блока */
export interface BannerBlocks extends Block {
  fields: BannerFields;
  uId?: string;
  template?: string;
}

export interface BannerBlockFields {
  borderRadius?: string;
  channelsLength?: number;
}

/** контент блока */
export interface BannerContent {
  blocks?: BannerBlocks[];
  size?: number;
  fields?: BannerBlockFields;
}

/** свойства компонента */
export interface BannerContentProps {
  content: BannerContent;
}

/** свойства стилизованного блока Single Banner */
export interface StyledBannerImgDivProps {
  url: string;
  smallUrl: string;
  isCenterImg?: boolean;
  borderRadius?: string;
}

/** свойства обёртки Single Banner */
export interface SingleBannerLinkWrapperProps {
  href?: string;
}

/** Возможные варианты виджета */
export enum buttonWidgetTypes {
  CallbackWidget = 'CallbackWidget',
  ConnectWidget = 'ConnectWidget',
}

/** Возможные варианты стилей блока "Баннер" */
export enum bannerBlockStyleTypes {
  SMALL = 'small',
  HALF = 'half',
  MEDIUM = 'medium',
  LARGE = 'large',
}

/** Варианты отображения блока */
export enum DISPLAY_OPTIONS {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}
