/** libraries */
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  SidePage,
  Loader,
  Icons,
  Icon,
  H2,
  H3,
  Text,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledMyAppealsSP } from './styles';
/** api */
import {
  getDemandCommentList,
  getDemandList,
  createDemand,
} from '~/api/apiPab2c';
/** interfaces */
import {
  MY_APPEALS_STATE,
  AppealData,
  CommentData,
  AutocompleteProps,
  ResultProps,
} from '../Blocks/Templates/Pab2c/Help/interfaces';
/** components */
import MyAppeals from './MyAppeals';
import Appeal from './Components/Appeal/Appeal';
import MyAppealsFooter from './MyAppealsFooter';
import NewAppeal from './Components/NewAppeal/NewAppeal';
import SuccessScreen from '../Blocks/Templates/Pab2c/Help/Components/SuccessScreen/SuccessScreen';
import NewAppealChangeAContract from './Components/NewAppealChangeAContract/NewAppealChangeAContract';
/** constants */
import { desktop1100, desktop940 } from '~/components/Grid/constants';
import {
  TOO_MUCH_REQUESTS_ERROR_TEXT,
  DEMAND_QP,
  DEFAULT_ERROR,
  DEFAULT_RESULT,
  initialAutocomplete,
} from '../Blocks/Templates/Pab2c/Help/constants';
import {
  DEMAND_CAUSE,
  DEMAND_REQUEST_ERRORS,
  INITIAL_RESULT,
  WORKFLOW_IDS,
} from '~/constants/common';
import { WORLFLOW_NAMES } from './constants';
/** stores */
import useMyAppealsStore from './store/useMyAppealsStore';
import { useRootStore } from '~/stores/RootStore';

/** Мои обращения */
const MyAppealsSidePage = (): JSX.Element => {
  const {
    authStore: { isAuth, isTemporaryTokenAuth },
  } = useRootStore();
  const router = useRouter();

  const {
    isShowMyAppeals,
    setIsShowMyAppeals,
    isRead,
    setIsRead,
    typeOfApplication,
    setTypeOfApplication,
    state,
    setState,
  } = useMyAppealsStore();
  /** Загрузка */
  const [isLoading, setIsLoading] = useState<boolean>(true);
  /** Выбранное созданное обращение */
  const [appeal, setAppeal] = useState<AppealData>(null);
  /** Список обращений */
  const [appealsList, setAppealsList] = useState<AppealData[]>([]);
  /** Получает список обращений */
  const getList = async () => {
    setIsLoading(true);
    try {
      const res = await getDemandList();
      setAppealsList(res);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!isAuth) return;
    getList();
  }, [isAuth]);

  const openSPDemand = () => {
    if (typeOfApplication === WORLFLOW_NAMES.CHANGE_THE_CONTRACT) {
      setState(MY_APPEALS_STATE.CREATE_NEW_APPEAL_CHANGE_CONTRACT);
      return;
    }
    setState(MY_APPEALS_STATE.CREATE_NEW_APPEAL);
  };

  /** Главная страница */
  const isMainState = state === MY_APPEALS_STATE.MAIN;

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const headerText = () => {
    if (newAppealResult.isResult) return '';
    switch (state) {
      case MY_APPEALS_STATE.APPEAL:
        return `Обращение № ${appeal.id}`;
      case MY_APPEALS_STATE.CREATE_NEW_APPEAL:
        return `Новое обращение «${typeOfApplication}»`;
      case MY_APPEALS_STATE.CREATE_NEW_APPEAL_CHANGE_CONTRACT:
        return `Новое обращение «${typeOfApplication}»`;
      default:
        return isDesktop940 ? 'Мои обращения' : 'Мои обращения в техподдержку';
    }
  };

  /** Комментарии */
  const [comments, setComments] = useState<CommentData[]>([]);
  const [isLoadingComments, setIsLoadingComments] = useState<boolean>(false);

  /** Получает комментарии */
  const getCommentList = async () => {
    setIsLoadingComments(true);
    try {
      const res = await getDemandCommentList(appeal.id);
      setComments(res);
      setIsLoadingComments(false);
    } catch (e) {
      console.error('getDemandCommentList', e);
      setIsLoadingComments(false);
    }
  };
  useEffect(() => {
    if (appeal) getCommentList();
  }, [appeal]);

  /** Текст обращения */
  const [textareaValue, setTextareaValue] = useState<string>('');
  const [textareaError, setTextareaError] = useState<string>('');
  /** Имя */
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  /** Номер телефона */
  const [phoneNumber, setPhoneNumber] = useState<AutocompleteProps>(
    initialAutocomplete,
  );
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  /** Email */
  const [email, setEmail] = useState<AutocompleteProps>(initialAutocomplete);
  const [emailError, setEmailError] = useState<string>('');
  /** Загрузка создания нового обращения */
  const [isLoadingCreateApply, setIsLoadingCreateApply] = useState<boolean>(
    false,
  );
  /** Результат создания заявки */
  const [newAppealResult, setNewAppealResult] = useState<ResultProps>(
    DEFAULT_RESULT,
  );
  /** Тип ошибки при создания заявки */
  const [error, setError] = useState<string>('');

  /** Номер новой заявки */
  const [newAppealNumber, setNewAppealNumber] = useState<number>(null);

  const validation = () => {
    if (!textareaValue.length) setTextareaError('Введите текст обращения');
    if (!name.length) setNameError('Введите имя');
    if (phoneNumber === initialAutocomplete)
      setPhoneNumberError('Введите номер телефона');
    if (phoneNumber.value.length < 11)
      setPhoneNumberError('Введите корректный номер телефона');
    const reg = /^([а-яА-ЯёЁa-zA-Z0-9_\-\.])+\@([а-яА-ЯёЁa-zA-Z0-9_\-\.])+\.([а-яА-ЯёЁA-Za-z]{2,4})$/;
    if (email.value && !reg.test(email.value)) {
      setEmailError('Введите корректный e-mail');
    }
    if (
      textareaError.length ||
      nameError.length ||
      phoneNumberError.length ||
      emailError.length ||
      !textareaValue.length ||
      !name.length ||
      phoneNumber === initialAutocomplete ||
      phoneNumber.value.length < 11 ||
      (email.value && !reg.test(email.value))
    )
      return false;
    return true;
  };

  const validationChangeAContract = () => {
    if (!name.length) setNameError('Введите имя');
    if (phoneNumber === initialAutocomplete)
      setPhoneNumberError('Введите номер телефона');
    if (phoneNumber.value.length < 11)
      setPhoneNumberError('Введите корректный номер телефона');
    if (
      nameError.length ||
      phoneNumberError.length ||
      !name.length ||
      phoneNumber === initialAutocomplete ||
      phoneNumber.value.length < 11
    )
      return false;
    return true;
  };

  /** Создаёт новое обращение */
  const createApply = async () => {
    if (state === MY_APPEALS_STATE.CREATE_NEW_APPEAL && !validation()) return;
    if (
      state === MY_APPEALS_STATE.CREATE_NEW_APPEAL_CHANGE_CONTRACT &&
      !validationChangeAContract()
    )
      return;
    setIsLoadingCreateApply(true);
    const typeOfApplicationId = () => {
      if (typeOfApplication === WORLFLOW_NAMES.MALFUNCTION)
        return WORKFLOW_IDS.MALFUNCTION;
      return WORKFLOW_IDS.CONTACT_THE_CUSTOMER;
    };
    const comment = () => {
      if (typeOfApplication === WORLFLOW_NAMES.CHANGE_THE_CONTRACT) {
        return 'Клиент отправил заявку на переоформление текущего договора из разделе «Моя Планета». Необходимо связаться и проконсультировать.';
      }
      return textareaValue.length ? textareaValue : typeOfApplication;
    };
    try {
      const res = await createDemand(
        typeOfApplicationId(),
        typeOfApplication === WORLFLOW_NAMES.CHANGE_THE_CONTRACT
          ? 'Заявка на переоформление из раздела «Моя Планета»'
          : 'Заявка из раздела «Моя Планета».',
        comment(),
        name,
        phoneNumber.value,
        email.value ? email.value : null,
        typeOfApplication === WORLFLOW_NAMES.CHANGE_THE_CONTRACT
          ? DEMAND_CAUSE.ADDITIONAL_SERVICES
          : null,
      );
      if (res.status === 200) {
        setNewAppealNumber(res.data);
        setNewAppealResult({
          isResult: true,
          isCorrect: true,
        });
        getList();
      } else {
        setNewAppealResult({
          isResult: true,
          isCorrect: false,
        });
      }
    } catch (e) {
      const err = e?.errorMessage ? JSON.parse(e.errorMessage) : null;
      if (DEMAND_REQUEST_ERRORS.TOO_MUCH_REQUESTS === err.Type) {
        setError(DEMAND_REQUEST_ERRORS.TOO_MUCH_REQUESTS);
      }
      setNewAppealResult({
        isResult: true,
        isCorrect: false,
      });
    } finally {
      setIsLoadingCreateApply(false);
    }
  };

  /** Вычисление ширины экрана */
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  const zeroing = () => {
    const qP = { ...router?.query };
    delete qP?.[DEMAND_QP];

    router.replace(
      {
        query: { ...qP },
      },
      undefined,
      {
        shallow: true,
      },
    );

    if (state === MY_APPEALS_STATE.APPEAL && appeal.unreadCommentCount > 0) {
      getList();
    }
    setState(MY_APPEALS_STATE.MAIN);
    setAppeal(null);
    setComments([]);
    setNewAppealResult(INITIAL_RESULT);
    setNewAppealNumber(null);
    setTextareaValue('');
    setTextareaError('');
    setName('');
    setNameError('');
    setPhoneNumber(initialAutocomplete);
    setPhoneNumberError('');
    setEmail(initialAutocomplete);
    setEmailError('');
    setTypeOfApplication(null);
    setError('');
  };

  const onCloseSidePage = () => {
    setIsShowMyAppeals(false);
    zeroing();
  };

  return (
    <StyledMyAppealsSP state={state}>
      <SidePage
        show={isShowMyAppeals}
        headerText={headerText()}
        onCloseClick={onCloseSidePage}
        footerContainer={
          !newAppealResult.isResult && (
            <MyAppealsFooter
              state={state}
              openSPDemand={openSPDemand}
              appeal={appeal}
              getCommentList={getCommentList}
              setAppealsList={setAppealsList}
              createApply={createApply}
              isLoadingCreateApply={isLoadingCreateApply}
              isTemporaryTokenAuth={isTemporaryTokenAuth}
            />
          )
        }
        iconToLeftOfHeader={
          !isMainState && !newAppealResult.isResult && <Icons.BackIcon />
        }
        onClickIconToLeftOfHeader={zeroing}
        isOnlyMobileFooter
        removeScrollBar
        preventAutoscroll
      >
        {isLoading && (
          <div className="loader">
            <Loader />
          </div>
        )}
        {!isLoading && (
          <>
            {isMainState && (
              <MyAppeals
                list={appealsList}
                setAppeal={setAppeal}
                setState={setState}
              />
            )}
            {state === MY_APPEALS_STATE.APPEAL && (
              <Appeal
                appeal={appeal}
                comments={comments}
                isLoadingComments={isLoadingComments}
                isRead={isRead}
                setIsRead={setIsRead}
              />
            )}
            {[
              MY_APPEALS_STATE.CREATE_NEW_APPEAL,
              MY_APPEALS_STATE.CREATE_NEW_APPEAL_CHANGE_CONTRACT,
            ].includes(state) && (
              <>
                {newAppealResult.isResult && newAppealResult.isCorrect && (
                  <SuccessScreen
                    number={newAppealNumber}
                    goBackToViewingRequests={zeroing}
                  />
                )}

                {newAppealResult.isResult && !newAppealResult.isCorrect && (
                  <>
                    <div className="result-icon">
                      <Icon icon={<Icons.NotOkBigIcon />} />
                    </div>
                    {isDesktop1100 ? (
                      <H2>Что-то пошло не{'\u00A0'}так...</H2>
                    ) : (
                      <H3>Что-то пошло не{'\u00A0'}так...</H3>
                    )}
                    <Text>
                      {error === DEMAND_REQUEST_ERRORS.TOO_MUCH_REQUESTS
                        ? TOO_MUCH_REQUESTS_ERROR_TEXT
                        : DEFAULT_ERROR}
                    </Text>
                  </>
                )}
                {!newAppealResult.isResult && (
                  <>
                    {state === MY_APPEALS_STATE.CREATE_NEW_APPEAL ? (
                      <NewAppeal
                        textareaValue={textareaValue}
                        setTextareaValue={setTextareaValue}
                        textareaError={textareaError}
                        setTextareaError={setTextareaError}
                        name={name}
                        setName={setName}
                        nameError={nameError}
                        setNameError={setNameError}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        phoneNumberError={phoneNumberError}
                        setPhoneNumberError={setPhoneNumberError}
                        email={email}
                        setEmail={setEmail}
                        emailError={emailError}
                        setEmailError={setEmailError}
                      />
                    ) : (
                      <NewAppealChangeAContract
                        name={name}
                        setName={setName}
                        nameError={nameError}
                        setNameError={setNameError}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        phoneNumberError={phoneNumberError}
                        setPhoneNumberError={setPhoneNumberError}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </SidePage>
    </StyledMyAppealsSP>
  );
};

export default observer(MyAppealsSidePage);
