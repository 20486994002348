/** libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  PriceTag,
  PriceTagBackgroundColor,
  Text,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** styles */
import { StyledProlongationInformation } from './styles';
/** utils */
import { pluralizeAll } from '~/utils/utils';
import LinkWrapper from '~/components/LinkWrapper';
/** constants */
import { desktop400 } from '~/components/Grid/constants';
/** interfaces */
import { ProlongationProps } from '../../../Contract/types';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface ProlongationInformationProps {
  prolongationInfo: ProlongationProps;
}

/** Продление годового продукта */
const ProlongationInformation: FC<ProlongationInformationProps> = ({
  prolongationInfo,
}: ProlongationInformationProps): JSX.Element => {
  const {
    contractInfoStore: {
      promoTariffStateAutoDaysLeft,
      promoTariffStateProlongationPrice,
    },
  } = useRootStore();

  /** Вычисление ширины экрана */
  const isDesktop400 = useMediaQuery({
    query: `(min-width: ${desktop400}px)`,
  });

  return (
    <StyledProlongationInformation>
      <Text lineHeight="24px">
        Срок действия Продукта{' '}
        <Text lineHeight="24px" fontWeightBold>
          {prolongationInfo?.prolongation?.tariffName}
        </Text>{' '}
        истекает через{' '}
        <Text lineHeight="24px" fontWeightBold>
          {pluralizeAll(promoTariffStateAutoDaysLeft, ['день', 'дня', 'дней'])}
        </Text>
        .{' '}
        <Text lineHeight="24px">
          После внесения суммы Продукт продлится автоматически.
        </Text>
      </Text>
      <div className="prolongation__price-button">
        <PriceTag
          value={`${promoTariffStateProlongationPrice.toString()} ₽`}
          subscription={`Стоимость продления Продукта${'\u000A'}на следующий период`}
          backgroundColor={PriceTagBackgroundColor.OK}
          headerType="H3"
        />
        <LinkWrapper href="/payment">
          <Button>{`Пополнить ${isDesktop400 ? 'баланс' : ''}`}</Button>
        </LinkWrapper>
      </div>
    </StyledProlongationInformation>
  );
};

export default observer(ProlongationInformation);
