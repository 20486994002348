/** libraries */
import { FC, useEffect } from 'react';
import { H2, H3, Icon, Icons, SidePage } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** constants */
import {
  DEFAULT_ERROR,
  SP_STATE,
} from '~/components/Blocks/Templates/Pab2c/Settings/constants';
import { desktop1100 } from '~/components/Grid/constants';
/** components */
import VerificationContractAndNumber from './Components/VerificationContractAndNumber';
import CodeCheck from './Components/CodeCheck';
import CreatePasswordFooter from './CreatePasswordFooter';
import NewPassword from './Components/NewPassword';
import BlockedContract from '../BlockedContract/BlockedContract';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useCreatePasswordStore from './store/useCreatePasswordStore';

const CreatePasswordSidePage: FC = () => {
  const {
    authStore: { auth, isAuth },
  } = useRootStore();

  const {
    isShowCreatePasswordWizard,
    contractNumber,
    setContractNumber,
    state,
    isBlockedContract,
    blockedContractTime,
    result,
    resetState,
  } = useCreatePasswordStore();

  // Вычисление ширины экрана
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  useEffect(() => {
    if (!contractNumber.length)
      setContractNumber(isAuth ? auth.contractName : '');
  }, [auth.contractName]);

  return (
    <SidePage
      show={isShowCreatePasswordWizard}
      headerText={!result.isResult && !isBlockedContract && 'Создать пароль'}
      onCloseClick={() => resetState(auth.contractName)}
      footerContainer={
        !result.isResult && !isBlockedContract && <CreatePasswordFooter />
      }
      isOnlyMobileFooter
      removeScrollBar
    >
      {isBlockedContract ? (
        <BlockedContract
          contractName={contractNumber}
          blockMinutes={
            blockedContractTime ? blockedContractTime.toString() : '30'
          }
        />
      ) : (
        <>
          {result.isResult && result.isCorrect && (
            <>
              <div className="result-icon">
                <Icon icon={<Icons.SuccessIconAnimated />} />
              </div>
              {isDesktop1100 ? <H2>Пароль создан</H2> : <H3>Пароль создан</H3>}
            </>
          )}

          {result.isResult && !result.isCorrect && (
            <>
              <div className="result-icon">
                <Icon icon={<Icons.NotOkBigIcon />} />
              </div>
              {isDesktop1100 ? (
                <H2>{DEFAULT_ERROR}</H2>
              ) : (
                <H3>{DEFAULT_ERROR}</H3>
              )}
            </>
          )}

          {!result.isResult && (
            <>
              {state === SP_STATE.PHONE && <VerificationContractAndNumber />}
              {state === SP_STATE.CODE && <CodeCheck />}
              {state === SP_STATE.NEW_PASSWORD && <NewPassword />}
            </>
          )}
        </>
      )}
    </SidePage>
  );
};

export default observer(CreatePasswordSidePage);
