/** библиотеки */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

/** компоненты библиотеки */
import { defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import {
  desktop430,
  desktop940,
  desktop1100,
  desktop1280,
  desktop500,
  desktop475,
  desktop440,
} from '~/components/Grid/constants';

export const StyledQrSidePage = styled.div`
  .qr {
    &__code-block {
      display: flex;
      flex-direction: column;
    }
    &__text-block {
      margin: 16px 0 0 0;
      &__timer {
        margin-top: 16px;
        &__loader {
          display: flex;
          align-items: center;
          margin-top: 8px;

          > div:nth-of-type(2) {
            margin-left: 8px;
          }
        }
      }
    }

    &__warning {
      padding: 16px;
      background-color: ${defaultTheme.colors.backgroundWarning};
      margin-top: 16px;
      &__banks {
        cursor: pointer;
      }
    }

    &__snoska {
      margin-top: 32px;
      a {
        color: ${defaultTheme.colors.planeta};
      }
    }
  }

  @media (min-width: ${desktop940}px) {
    .qr {
      &__code-block {
        flex-direction: row;
      }
      &__text-block {
        margin: 0 0 0 24px;
        &__timer {
          margin-top: 24px;
        }
      }
      &__warning {
        margin-top: 40px;
      }
      &__snoska {
        position: absolute;
        bottom: 32px;
      }
    }
  }
`;

/** стилевой компонент пополнения баланса */
export const StyledPayment = styled.div<{ hasBorder?: boolean; size: number }>`
  ${({ hasBorder, size }) => {
    return css`
      height: auto;
      min-height: 400px;
      width: 100%;
      background: ${defaultTheme.colors.white};
      border: 1px solid ${hasBorder ? defaultTheme.colors.disable : 'none'};

      h3[data-test-sidepage-header] {
        white-space: pre-wrap;

        @media (min-width: ${desktop475}px) {
          white-space: nowrap;
        }
      }

      ${size === 1
        ? `@media (min-width: ${desktop430}px) {
              height: 400px;
            }`
        : ''}

      @media (min-width: ${desktop940}px) {
        min-height: 400px;
        height: 400px;
      }
    `;
  }};
`;

/** стилевой компонент пополнения баланса */
export const StyledPaymentForm = styled.div<{
  blockWidth: number;
  isOpenSBPCards?: boolean;
}>`
  ${({ blockWidth }) => {
    const handleReturnInputTextWidth = () => {
      switch (blockWidth) {
        case 400:
          return '200px';
        case 340:
          return '160px';
        case 280:
          return '130px';
        default:
          return '200px';
      }
    };

    const handleReturnInputMoneyWidth = () => {
      switch (blockWidth) {
        case 400:
          return '120px';
        case 340:
          return '100px';
        case 280:
          return '80px';
        default:
          return '120px';
      }
    };

    return css`
      padding: 28px ${blockWidth === 280 ? 30 : 32}px 22px;
      position: relative;
      min-height: 400px;

      H3 {
        margin-bottom: 25px;
        width: ${blockWidth ? '170px' : 'auto'};
      }

      .payment-form {
        &__wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: ${blockWidth ? 'space-between' : 'start'};

          svg {
            margin-left: ${blockWidth ? '0' : '30px'};
            display: none;
          }

          &__errorBlock {
            display: flex;
            flex-direction: column;
            min-height: 180px;
          }
        }

        &__input-wrapper {
          display: flex;
          flex-direction: column;

          &_money {
            margin-left: ${blockWidth ? '0' : '20px'};
          }
        }

        &__payment-method-select {
          width: 100%;
          margin-bottom: 16px;

          .select-wrapper {
            width: 100%;
          }

          .select-wrapper .select-overlay {
            width: 100%;
          }
        }

        &__payment-method-tabs {
          margin-bottom: 16px;

          .active {
            background-color: ${defaultTheme.colors.black};
          }
        }

        &__input-text {
          margin-bottom: 5px;
        }

        &__input-number {
          margin-bottom: 10px;
          width: 100%;
        }

        &__input-money {
          margin-bottom: 10px;
          width: 100%;
        }

        &__button-wrapper {
          width: ${blockWidth ? '100%' : '248px'};
          display: flex;
          flex-direction: column;

          button {
            width: ${blockWidth === 280 ? 180 : 248}px;
            margin: 16px 0 16px;
          }

          &__snoska {
            &__link {
              cursor: pointer;
            }
          }
        }
      }

      @media (min-width: ${desktop440}px) {
        .payment-form {
          &__wrapper {
            flex-direction: row;

            svg {
              display: ${blockWidth === 280 ? 'none' : 'block'};
            }
          }

          &__input-number {
            width: ${handleReturnInputTextWidth()};
          }

          &__input-money {
            width: ${handleReturnInputMoneyWidth()};
          }

          &__button-wrapper {
            position: absolute;
            bottom: 32px;
          }
        }
      }

      @media (min-width: ${desktop500}px) {
        .payment-form {
          &__payment-method-select {
            width: 388px;
          }
        }
      }

      @media (min-width: ${desktop940}px) {
        .payment-form {
          &__payment-method-select {
            width: 100%;
          }

          &__button-wrapper {
            &__snoska {
              padding-right: 60px;
            }
          }
        }
      }
    `;
  }}
`;

export const StyledPaymentX2Form = styled.div<{ isOpenSBPCards?: boolean }>`
  ${({ isOpenSBPCards }) => {
    return css`
      height: 100%;
      position: relative;
      padding: 32px;

      ${isOpenSBPCards &&
      `div[data-test-sidepage]:after {
        content: '';
        background: white;
        bottom: -20vh;
        left: 0;
        position: fixed;
        width: 100vw;
        height: 20vh;
        z-index: 999;
      }`}

      ${StyledCheckboxWrapper} ~ .payment-form__button-wrapper {
        margin-top: 48px;
      }

      .payment-form {
        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 32px;

          h2,
          h3 {
            margin: 0;
          }

          &__svg {
            display: flex;
            align-items: center;

            svg {
              display: none;
              margin-left: 30px;
            }
          }
        }

        &__payment-method-select {
          width: 100%;
          margin-bottom: 16px;

          .select-wrapper {
            width: 100%;
          }

          .select-wrapper .select-overlay {
            width: 100%;
          }
        }

        &__payment-method-tabs {
          margin-bottom: 16px;

          .active {
            background-color: ${defaultTheme.colors.black};
          }
        }

        &__text {
          margin-bottom: 16px;
        }

        &__wrapper {
          display: flex;
          flex-wrap: wrap;

          &__block {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 16px;

            input {
              width: 100%;
            }
            input: disabled {
              background-color: #fff;
            }
            .select-wrapper .select-overlay {
              width: 100%;
            }
          }

          &__block-text {
            margin-bottom: 5px;
          }

          &__block-card {
            .select-wrapper {
              width: 100%;
            }
            .expired {
              .option-text,
              .option-bottomText {
                color: ${defaultTheme.colors.planeta};
              }
            }
          }

          &__errorBlock {
            display: flex;
            flex-direction: column;
          }

          &__text {
            margin-bottom: 24px;
          }
        }

        &__checkbox {
          margin-bottom: 24px;
        }

        &__button-wrapper {
          display: flex;
          flex-direction: column;
          position: relative;

          ${StyledCheckboxWrapper} ~ button {
            margin-top: 48px;
          }

          button {
            width: 164px;
            margin-bottom: 8px;
          }

          &__snoska {
            &__link {
              cursor: pointer;
            }
          }
        }
      }

      .displayNone {
        display: none;
      }

      @media (min-width: 500px) {
        .payment-form {
          &__payment-method-select {
            .select-wrapper {
              width: 388px;
            }
          }
        }
      }

      @media (min-width: ${desktop940}px) {
        .payment-form {
          &__header {
            margin-bottom: 16px;
            svg {
              display: block;
            }
          }

          &__wrapper {
            margin-bottom: 0;
            &__block {
              width: auto;
              margin-bottom: 8px;
              input {
                width: 256px;
              }

              &__card-block-100 {
                width: 100%;

                .select-wrapper .select-overlay {
                  width: 100%;
                }
              }

              &__card-block-half {
                width: 256px;
                margin-right: 16px;

                .select-wrapper .select-overlay {
                  width: 256px;
                }
              }

              &__checkbox {
                display: flex;
                align-items: center;
                width: 256px;
                padding-top: 15px;
              }
            }
            &__block:first-of-type {
              margin-right: 16px;
            }
          }

          &__button-wrapper {
            position: absolute;
            bottom: 32px;
            flex-direction: row;
            align-items: center;

            ${StyledCheckboxWrapper} ~ button {
              margin-top: 0;
            }

            button {
              margin: 0 32px 0 0;
            }

            &__snoska {
              width: 270px;
            }
          }
        }
      }

      @media (min-width: ${desktop1100}px) {
        .payment-form {
          &__wrapper {
            &__block {
              input {
                width: 316px;
              }

              &__card-block-half {
                width: 316px;
                margin-right: 16px;

                .select-wrapper .select-overlay {
                  width: 316px;
                }
              }
            }
          }
        }
      }

      @media (min-width: ${desktop1280}px) {
        .payment-form {
          &__wrapper {
            flex-wrap: nowrap;
            &__block {
              margin-right: 16px;
              margin-bottom: 16px;

              input {
                width: 200px;
              }
            }

            &__block:nth-of-type(3) {
              width: 336px;

              .select-wrapper .select-overlay {
                width: 100%;
              }
            }
          }

          &__button-wrapper {
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            button {
              margin: 0 0 8px 0;
            }

            &__checkbox {
              width: 560px;
              margin-left: 24px;
            }

            &__snoska {
              width: 100%;
            }
          }
        }
      }
    `;
  }}
`;

export const StyledCheckboxWrapper = styled.div<{ $showSelector: boolean }>`
  position: absolute;
  top: 0;
  right: auto;

  ${({ $showSelector }) =>
    $showSelector
      ? css`
          @media (min-width: ${desktop940}px) {
            top: -95px;
            right: 0;
          }

          @media (min-width: ${desktop1100}px) {
            right: -55px;
          }
        `
      : css`
          @media (min-width: ${desktop940}px) {
            top: -60px;
            right: auto;
          }
        `};

  @media (min-width: ${desktop1280}px) {
    right: auto;
    top: 14px;
    left: 190px;
  }
`;
