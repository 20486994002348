/** библиотеки */
import * as React from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import Image from 'next/image';
import { observer } from 'mobx-react';
/** компоненты библиотеки */
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H3,
  Icon,
  Icons,
  Input,
  Select,
  SidePage,
  Snoska,
  Tabs,
  Text,
} from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledPaymentForm, StyledQrSidePage } from './style';

/** константы */
import {
  CARD_TEXT,
  CHECK_PAYMENT_SLUG,
  CHECK_URL_AMOUNT,
  CONTRACT_NOT_FOUND_TYPE,
  ERROR_MESSAGES,
  PAYMENT_BLOCK_ID,
  PAYMENT_TYPE_INTERFACE,
  SBP_TEXT,
} from './constants';
import {
  desktop1100,
  desktop380,
  desktop500,
  desktop940,
} from '~/components/Grid/constants';

/** типы */
import { PaymentFormProps } from './types';
import { PAYMENT_TYPE } from '~/interfaces/PromoInterface';

/** api */
import {
  checkCardPaymentOrderState,
  setCardPaymentRegisterOrder,
} from '~/api/api';

/** утилиты */
import { checkUrlAccess } from './utils';
import { scrollToBlockById } from '~/components/Blocks/Shared/Shared.utils';
import { formatNumber, removeLeadingZero } from '~/utils/utils';

/** компоненты */
import Promo from './Promo/Promo';
import Portal from '~/components/Portal/Portal';
import ListOfSbpBanks from '../../Shared/ListOfSbpBanks/ListOfSbpBanks';

/** hooks */
import { usePayment } from './PaymentHook';
/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Компонент формы пополнения баланса
 * @param {PaymentFormProps} result Флаг отображения результатов и успешности операции
 * @param {PaymentFormProps} blockWidth Ширина блока
 * @param {PaymentFormProps} setResult dispatch результата оплаты
 * @param {PaymentFormProps} paymentStyle стиль отображения блока
 */
const PaymentForm: React.FC<PaymentFormProps> = ({
  setResult,
  blockWidth,
  paymentStyle,
  promoListContent,
  activePromotion,
  setActivePromotion,
  getPromoInfo,
  setAnswer,
  cardLimit,
  sbpLimit,
}: PaymentFormProps) => {
  const {
    authStore: { isAuth, auth },
  } = useRootStore();
  // Для чтения параметров из url
  const router = useRouter();

  // Вычисление ширины экрана
  const isDesktop380 = useMediaQuery({
    query: `(min-width: ${desktop380}px)`,
  });
  const isDesktop500 = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  // tabs с иконками и текстом
  const tabsList = [
    {
      value:
        !isDesktop500 || isDesktop940 ? CARD_TEXT.BY_CARD : CARD_TEXT.BANK_CARD,
      icon: <Icons.WhiteCardIcon />,
      inactiveIcon: <Icons.SmallCardIcon />,
    },
    {
      value: SBP_TEXT.SBP,
      icon: <Icons.ColorSbpIcon />,
    },
  ];

  // Пополнение картой
  const payByCreditCard = async (newAmount?: number) => {
    fetchAmount.current = CHECK_URL_AMOUNT;
    try {
      const baseUrl = `${window.location.origin}/${CHECK_PAYMENT_SLUG}`;
      const cardAmount = newAmount ?? +amount.forSend;
      const paymentRegisterOrder = await setCardPaymentRegisterOrder(
        contractNumber,
        cardAmount,
        encodeURIComponent(
          `${baseUrl}?&contractNumber=${contractNumber}&amount=${cardAmount}`,
        ),
      );
      if (paymentRegisterOrder.authCode === 'None') {
        await checkUrlAccess(
          paymentRegisterOrder.formUrl,
          fetchAmount,
          setResult,
          null,
          setIsLoading,
        );
        return;
      }
      setResult(false);
    } catch (error) {
      const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
      if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
        setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
        setIsLoading(false);
        return;
      }
      setResult(false);
    } finally {
      setIsLoading(false);
    }
    setActivePromotion(null);
    fetchAmount.current = CHECK_URL_AMOUNT;
  };

  // Валидация
  const handleCheckIsValid = (): boolean => {
    if (!contractNumber) {
      setErrorMessage(ERROR_MESSAGES.CONTRACT_NUMBER);
      return false;
    }

    const isCardAmountError =
      isCardTab && cardLimit.minAmount && +amount.forSend < cardLimit.minAmount;
    const isSbpAmountError =
      isSbpTab && sbpLimit.minAmount && +amount.forSend < sbpLimit.minAmount;

    if (isCardAmountError || isSbpAmountError) {
      setErrorMessage(
        `Сумма менее ${
          isCardAmountError ? cardLimit.minAmount : sbpLimit.minAmount
        } рублей`,
      );
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const {
    contractNumber,
    setContractNumber,
    amount,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    fetchAmountRef: fetchAmount,
    isSidePageOpen,
    QRcode,
    activeTabIndex,
    setActiveTabIndex,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    paymentViaSBP,
    isSBP,
    isAvailableSBP,
    handleOnChangeInputMoney,
    snoska,
    tabsIcons,
    paymentMethodData,
    onCloseClickSbpDesktop,
    isOpenPromo,
    setIsOpenPromo,
    payHandler,
    isCardTab,
    isSbpTab,
  } = usePayment({
    paymentStyle,
    payByCreditCard,
    handleCheckIsValid,
    promoListContent,
    activePromotion,
    setActivePromotion,
    getPromoInfo,
    cardLimit,
    sbpLimit,
  });

  // Способы оплаты tabs
  const tabs =
    !isDesktop380 || (isDesktop940 && !isDesktop1100) ? tabsIcons : tabsList;

  // Отображение select
  const paymentMethodDataFormed = paymentMethodData.map((item) => {
    let itemLabel = '';
    switch (true) {
      case !isDesktop380 || (isDesktop940 && !isDesktop1100):
        itemLabel = item.value === '1' ? SBP_TEXT.SBP : CARD_TEXT.BY_CARD;
        break;
      case !isDesktop500 || isDesktop1100:
        itemLabel =
          item.value === '1'
            ? SBP_TEXT.TOP_UP_VIA_SBP
            : CARD_TEXT.TOP_UP_WITH_A_CARD;
        break;
      default:
        itemLabel =
          item.value === '1'
            ? SBP_TEXT.TOP_UP_ACCOUNT_VIA_SPB
            : CARD_TEXT.TOP_UP_ACCOUNT_WITH_A_BANK_CARD;
    }
    return {
      ...item,
      label: itemLabel,
    };
  });

  // При вводе номера договора
  const handleOnChangeInputText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.length > 8 || (newValue && /\D/g.test(newValue))) return;
    setContractNumber(newValue);
  };

  // Проверит информацию о состоянии платежа.
  const checkPaymentOrderState = async (contract: string, orderId: string) => {
    try {
      const orderState = await checkCardPaymentOrderState(contract, orderId);
      setResult(orderState.authCode === 'Ok');
    } catch (error) {
      const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
      if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
        setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
      }
      setResult(false);
    }
    scrollToBlockById(`${PAYMENT_BLOCK_ID}1`);
  };

  // Следит за адресной строкой
  useEffect(() => {
    if (
      router.query.contractNumber &&
      router.query.orderId &&
      router.query.size !== '2' &&
      !router.query.isAutoPayment
    ) {
      checkPaymentOrderState(
        router.query.contractNumber as string,
        router.query.orderId as string,
      );
    }
  }, [router.query.orderId]);

  /**
   * Обработка авторизации
   */
  useEffect(() => {
    setContractNumber(isAuth ? auth.contractName : '');
  }, [isAuth]);

  return (
    <StyledPaymentForm blockWidth={blockWidth}>
      {!paymentStyle && (
        <div className="payment-form__wrapper">
          <H3>Пополнение счёта</H3>
          <Icon icon={<Icons.CardIcon />} />
        </div>
      )}
      {paymentStyle?.type === PAYMENT_TYPE_INTERFACE.SELECT && (
        <Select
          className="payment-form__payment-method-select"
          onOptionClick={(option) => setSelectedPaymentMethod(option)}
          value={selectedPaymentMethod ? selectedPaymentMethod.value : ''}
          data={paymentMethodDataFormed}
          width="388px"
        />
      )}
      {paymentStyle?.type === PAYMENT_TYPE_INTERFACE.TABS && (
        <div className="payment-form__payment-method-tabs">
          <Tabs
            valueWithIcons={tabs}
            onChange={(tabIndex) => setActiveTabIndex(tabIndex)}
            activeTabIndex={activeTabIndex}
          />
        </div>
      )}
      <Portal>
        <SidePage
          show={isSidePageOpen}
          headerText="Пополнить счёт через СБП"
          onCloseClick={onCloseClickSbpDesktop}
        >
          <StyledQrSidePage>
            <div>
              <Image
                src={`data:image/png;base64, ${QRcode}`}
                alt="qrcode"
                width={212}
                height={212}
                quality={100}
              />
            </div>
            <Text lineHeight="24px">
              Отсканируйте QR-код камерой телефона и завершите оплату
              в&nbsp;мобильном приложении банка.
            </Text>
          </StyledQrSidePage>
        </SidePage>
        {activePromotion && promoListContent[activePromotion] && (
          <SidePage
            show={isOpenPromo}
            // eslint-disable-next-line dot-notation
            headerText={promoListContent[activePromotion].header}
            onCloseClick={() => {
              setIsOpenPromo(false);
              setActivePromotion(null);
            }}
            width={
              promoListContent[activePromotion]?.isCashbackBottoms
                ? '832px'
                : null
            }
          >
            <Promo
              // eslint-disable-next-line dot-notation
              content={promoListContent[activePromotion].content}
              payByCreditCard={payByCreditCard}
              paymentViaSBP={paymentViaSBP}
              contractName={contractNumber}
              amount={+amount.forSend}
              paymentPromoType={activePromotion}
              setAnswer={setAnswer}
              setIsOpenPromo={setIsOpenPromo}
              setIsLoading={setIsLoading}
              // eslint-disable-next-line dot-notation
              isCashbackBottoms={
                promoListContent[activePromotion]?.isCashbackBottoms
              }
              currentPaymentType={
                isSBP ? PAYMENT_TYPE.SBP : PAYMENT_TYPE.CARD_PAYMENT
              }
            />
          </SidePage>
        )}
      </Portal>

      {isSBP && !isAvailableSBP ? (
        <div className="payment-form__wrapper__errorBlock">
          <div className="payment-form__wrapper">
            <div className="payment-form__input-wrapper">
              <Text>Оплата через СБП временно недоступна.</Text>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="payment-form__wrapper__errorBlock">
            <div className="payment-form__wrapper">
              <div className="payment-form__input-wrapper">
                <Text className="payment-form__input-text">Номер договора</Text>
                <Input
                  className="payment-form__input-number"
                  type="text"
                  placeholder="до 8 цифр"
                  inputMode="numeric"
                  value={removeLeadingZero(contractNumber)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    handleOnChangeInputText(e);
                  }}
                  width="100%"
                  borderRadius="4px"
                />
              </div>
              <div className="payment-form__input-wrapper payment-form__input-wrapper_money">
                <Text className="payment-form__input-text">Сумма</Text>
                <Input
                  className="payment-form__input-money"
                  type="money"
                  placeholder="500 &#8381;"
                  value={amount.value}
                  onChangeCustomInput={handleOnChangeInputMoney}
                  width="100%"
                />
              </div>
            </div>
            {errorMessage && (
              <div className="payment-form__error">
                <Text color={defaultTheme.colors.planeta}>{errorMessage}</Text>
              </div>
            )}
          </div>
        </>
      )}

      <div className="payment-form__button-wrapper">
        <Button
          styleType={ButtonStyleTypes.SECONDARY}
          loading={isLoading}
          onClick={payHandler}
          disabled={isSBP && !isAvailableSBP}
        >
          Оплатить
        </Button>
        <Snoska className="payment-form__button-wrapper__snoska">
          Сумма платежа от&nbsp;
          {isCardTab
            ? formatNumber(cardLimit.minAmount)
            : formatNumber(sbpLimit.minAmount)}
          &nbsp;до&nbsp;
          {isCardTab
            ? formatNumber(cardLimit.maxAmount)
            : formatNumber(sbpLimit.maxAmount)}
          &nbsp;рублей.
          <br />
          {snoska()}
        </Snoska>
      </div>
      <Portal>
        <ListOfSbpBanks />
      </Portal>
    </StyledPaymentForm>
  );
};

export default observer(PaymentForm);
