/** библиотеки */
import { FC } from 'react';
import * as React from 'react';

/** Компоненты библиотеки */
import { Text, Input, defaultTheme, Icon, Icons } from 'cordis-core-ui-planeta';

/** утилиты */
import { pluralizeAll } from '~/utils/utils';
/** Стили */
import {
  ChatleWizardContainer,
  StyledChatleWizardSuccess,
} from './ChatleWizard.style';

/** Константы */
import { CHATLE } from '~/components/Blocks/Templates/Pab2c/Contract/constants';

/** Интерфейсы */
import { ChatleWizardProps } from '~/components/Blocks/Templates/Pab2c/Contract/ChatleWizard/ChatleWizard.types';

/**
 * Форма резервирования чатлов.
 */
const ChatleWizard: FC<ChatleWizardProps> = ({
  maxChatleAmount,
  chatleAmount,
  setChatleAmount,
  reserveChatles,
  validateChatleAmount,
  errorChatleAmount,
  isChatleCompleted,
  serverError,
}: ChatleWizardProps) => {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (!errorChatleAmount) {
        reserveChatles();
      }
      event.preventDefault();
    }
  };

  return (
    <>
      <ChatleWizardContainer>
        <form className="wizard">
          <div className="wizard__wrapper">
            {isChatleCompleted && (
              <StyledChatleWizardSuccess>
                <div className="success-icon">
                  <Icon icon={<Icons.SuccessIconAnimated />} />
                </div>
                <h2>Чатлы зарезервированы!</h2>
                <div className="wizard__success-text">
                  Вы использовали{' '}
                  <strong>{pluralizeAll(+chatleAmount, CHATLE)}</strong> для
                  компенсации услуг
                </div>
              </StyledChatleWizardSuccess>
            )}
            {!isChatleCompleted && (
              <>
                <div className="wizard__header">
                  <Text>
                    Укажите количество Чатлов, которые будут использованы для
                    компенсации услуг начиная с&nbsp;1&nbsp;числа текущего
                    месяца.
                  </Text>
                </div>
                <div className="wizard__input-item">
                  <div className="wizard__label">
                    <label htmlFor="chatleWizardAmount">
                      <Text color={defaultTheme.colors.shadow}>
                        Количество Чатлов
                      </Text>
                    </label>
                  </div>
                  <div>
                    <Input
                      id="chatleWizardAmount"
                      className="wizard__input wizard__input-amount"
                      type="number"
                      value={chatleAmount.toString()}
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        setChatleAmount(e.target.value);
                      }}
                      onBlur={validateChatleAmount}
                      error={errorChatleAmount}
                      onKeyPress={handleKeyPress}
                      onKeyUp={validateChatleAmount}
                      placeholder={`от 1 до ${pluralizeAll(
                        maxChatleAmount,
                        CHATLE,
                      )}`}
                    />
                  </div>
                  <Text
                    className="wizard__input-limit"
                    color={
                      errorChatleAmount
                        ? defaultTheme.colors.planeta
                        : defaultTheme.colors.black
                    }
                  >
                    Введите от 1 до {pluralizeAll(maxChatleAmount, CHATLE)}
                  </Text>
                </div>
              </>
            )}
            <div className="wizard__warning">
              <Text>
                <p>
                  Произойдёт перерасчёт уже оплаченных услуг текущего месяца.
                </p>

                <p>
                  Если количество зарезервированных Чатлов превышает расходы за
                  текущий месяц, то&nbsp;оставшееся количество будет
                  использовано в&nbsp;следующем месяце.
                </p>
              </Text>
            </div>
            {!isChatleCompleted && (
              <div className="wizard__legal">
                <Text>
                  Ознакомьтесь с{' '}
                  <a href="/documents" target="_blank">
                    Условиями бонусной программы «Чатл»
                  </a>
                </Text>
              </div>
            )}

            {serverError && (
              <Text
                className="wizard__server-error"
                color={defaultTheme.colors.planeta}
              >
                {serverError}
              </Text>
            )}
          </div>
        </form>
      </ChatleWizardContainer>
    </>
  );
};

export default ChatleWizard;
