/* Библиотеки */
import { useState, useEffect, useRef, RefObject } from 'react';

import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/* Типы */
import { H2, H3, Button } from 'cordis-core-ui-planeta';
import {
  CallToActionProps,
  CallToActionDeviceTypes,
  DataSelectPurchaseTypesProps,
  PurchaseTypes,
  CallToActionDeviceInfo,
} from './CallToAction.types';
import { PromoPriceInfoProps } from '~/components/Blocks/Shared/Shared.types';

/* Константы */
import {
  DEVICE_IMAGES,
  DEVICE_PURCHASE_TYPES,
  DEVICE_BUY_AMOUNT,
  CALL_TO_ACTION_BLOCK_ID,
} from './CallToAction.constants';
import { ConnectionMethod } from '~/constants/common';
import { BUSINESS_GROUP_CODE } from '../Summary/Summary.types';
import { desktop940 } from '~/components/Grid/constants';

/* Компоненты */
import CallToActionProduct from './CallToActionProduct/CallToActionProduct';
import CallToActionFee from './CallToActionFee/CallToActionFee';
import CallToActionConnection from './CallToActionConnection/CallToActionConnection';
import CallToActionInternet from './CallToActionInternet/CallToActionInternet';
import CallToActionTelevision from './CallToActionTelevision/CallToActionTelevision';
import CallToActionTimeShift from './CallToActionTimeShift/CallToActionTimeShift';
import CallToActionDevice from './CallToActionDevice/CallToActionDevice';
import CallToActionPrepayment from './CallToActionPrepayment/CallToActionPrepayment';
import CallToActionAnnual from './CallToActionAnnual/CallToActionAnnual';
import ConnectionWizard from '~/components/ConnectionWizard/ConnectionWizard';
import CallToActionFixedPrice from './CallToActionFixedPrice/CallToActionFixedPrice';

/* Стили */
import {
  CallToActionContainer,
  CallToActionConnectionWizard,
  CallToActionConnectionButtonSticky,
  CallToActionConnectionButton,
} from './CallToAction.style';

/* Утилиты */
import {
  getPromoPrice,
  scrollToBlockById,
} from '~/components/Blocks/Shared/Shared.utils';
import { formatNumber } from '~/utils/utils';

/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Блок "CallToAction"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=413528584
 * @param content
 */
const CallToAction: React.FC<CallToActionProps> = ({
  content,
}: CallToActionProps) => {
  const {
    fields: {
      marketingGroupCode,
      marketingGroupName,
      seriesCode,
      seriesName,
      marketingText,
      tags,
      priceInfo: {
        annual,
        daily,
        thirtyDays,
        migrationCostFirst,
        promoConnectionInfos,
        prepayment,
        connection,
      },
      channelsInfo,
      timeShiftInfo,
      devicesInfo: { devices },
      connectionMethod,
      speedBaseText,
      isTransformer,
      isAnnual,
      distortionDuration,
      fixedPrice,
      customHeader,
    },
  } = content;
  const { uhdChannelCount, hdChannelCount, allChannelCount, channelCount } = {
    ...channelsInfo,
  };
  const { isAvailable, price: timeshiftPrice } = {
    ...timeShiftInfo,
  };
  const {
    authStore: { isAuth },
  } = useRootStore();

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  // Контейнер с контентом
  const ctaRef: RefObject<HTMLDivElement> | null = useRef(null);

  // Данные для Select c выбором типа приобретения оборудования
  const dataSelectPurchase = DEVICE_PURCHASE_TYPES.map((item, index) => ({
    label: item,
    value: String(index),
  }));

  // Данные для Select c количеством оборудования, приобретаемого в собственность
  const dataSelectAmount = DEVICE_BUY_AMOUNT.map((item, index) => ({
    label: item,
    value: String(index),
  }));

  // Информация об устройствах. Роутер
  let routerInfo;
  // Информация об устройствах. Телеприставка
  let STBInfo;
  // Информация об устройствах. Пульт
  let remoteInfo;

  // Заполняем информацию об оборудовании
  Object.values(CallToActionDeviceTypes).forEach((key: string): void => {
    switch (key) {
      case CallToActionDeviceTypes.ROUTER:
        routerInfo = devices.find((item) => item.type === key);
        break;
      case CallToActionDeviceTypes.STB:
        STBInfo = devices.find((item) => item.type === key);
        break;
      case CallToActionDeviceTypes.REMOTE_CONTROL:
        remoteInfo = devices.find((item) => item.type === key);
        break;
      default:
        break;
    }
  });

  /* Формирует ссылку на изображение устройства */
  const getDeviceImg = (deviceType: CallToActionDeviceTypes): string => {
    return DEVICE_IMAGES[deviceType][connectionMethod];
  };

  /* Оставляет только доступные варианты приобретения оборудования */
  const filterPurchaseTypes = (
    purchaseTypes: DataSelectPurchaseTypesProps[],
    annuityPrice: number,
    ownershipPrice: number,
  ): DataSelectPurchaseTypesProps[] => {
    const result = purchaseTypes.filter((item) => {
      if (annuityPrice === null && ownershipPrice === null)
        return item.label === PurchaseTypes.PURCHASE;
      if (annuityPrice === null) return item.label !== PurchaseTypes.ANNUITY;
      if (ownershipPrice === null)
        return item.label !== PurchaseTypes.OWNERSHIP;
      return item;
    });
    return result;
  };

  /* Флаг отображения виджета */
  const [openConnectionWirad, setOpenConnectionWirard] = useState<boolean>(
    false,
  );
  /* Значение scroll до блока cta  */
  const [ctaScroll, setCtaScroll] = useState<number>(0);
  /* Ширина блока cta */
  const [ctaWidth, setCtaWidth] = useState<number>(0);

  /* Флаг отображения настроек продукта */
  const [showSettings, setShowSettings] = useState<boolean>(false);

  /* Флаг активации сервиса "Управление просмотром */
  const [enabledTimeShift, setEnabledTimeShift] = useState<boolean>(false);

  /* Флаг активации сервиса "Wi-Fi роутер" */
  const [enabledRouter, setEnabledRouter] = useState<boolean>(false);
  /* Стоимость сервиса "Wi-Fi роутер" */
  const [purchaseRouterPrice, setPurchaseRouterPrice] = useState<number>(
    getPromoPrice(routerInfo.promoPriceInfos, {
      currentPrice: routerInfo.price,
    }).promoPrice,
  );
  /* Вариант приобретения роутера */
  const [
    selectRouterTypes,
    setSelectRouterTypes,
  ] = useState<DataSelectPurchaseTypesProps>(
    filterPurchaseTypes(
      dataSelectPurchase,
      routerInfo.annuity,
      routerInfo.ownershipPriceOn,
    )[0],
  );

  /* Количество роутеров, приобретаемых в собственность */
  const [
    selectRouterAmount,
    setSelectRouterAmount,
  ] = useState<DataSelectPurchaseTypesProps>(dataSelectAmount[0]);

  /* Количество роутеров, приобретаемых по акции */
  const maxPromoRouters =
    getPromoPrice(promoConnectionInfos, {}).maxPromoDevice || 0;

  /* Флаг активации сервиса "Телеприставка" */
  const [enabledSTB, setEnabledSTB] = useState<boolean>(false);
  /* Стоимость сервиса "Телеприставка" */
  const [purchaseSTBPrice, setPurchaseSTBPrice] = useState<number>(
    STBInfo
      ? getPromoPrice(STBInfo.promoPriceInfos, { currentPrice: STBInfo.price })
          .promoPrice
      : 0,
  );
  /* Вариант приобретения приставки */
  const [
    selectSTBTypes,
    setSelectSTBTypes,
  ] = useState<DataSelectPurchaseTypesProps>(
    STBInfo
      ? filterPurchaseTypes(
          dataSelectPurchase,
          STBInfo.annuity,
          STBInfo.ownershipPriceOn,
        )[0]
      : null,
  );
  /* Количество приставок, приобретаемых в собственность */
  const [
    selectSTBAmount,
    setSelectSTBAmount,
  ] = useState<DataSelectPurchaseTypesProps>(dataSelectAmount[0]);

  /* Флаг активации сервиса "Пульт" */
  const [enabledRemote, setEnabledRemote] = useState<boolean>(false);
  /* Стоимость сервиса "Пульт" */
  const [purchaseRemotePrice, setPurchaseRemotePrice] = useState<number>(
    getPromoPrice(remoteInfo?.promoPriceInfos, {
      currentPrice: remoteInfo?.price,
    }).promoPrice,
  );
  /* Вариант приобретения пультов */
  const [
    selectRemoteTypes,
    setSelectRemoteTypes,
  ] = useState<DataSelectPurchaseTypesProps>(
    filterPurchaseTypes(
      dataSelectPurchase,
      remoteInfo?.annuity,
      remoteInfo?.ownershipPriceOn,
    )[0],
  );
  /* Количество пультов, приобретаемых в собственность */
  const [
    selectRemoteAmount,
    setSelectRemoteAmount,
  ] = useState<DataSelectPurchaseTypesProps>(dataSelectAmount[0]);

  /* Формирует сумму для устройств в составе абонентской платы исходя из выбранных настроек */
  const getDeviceFee = (
    enabled: boolean,
    info: CallToActionDeviceInfo,
    selectTypes: DataSelectPurchaseTypesProps,
  ) => {
    if (
      !enabled ||
      getPromoPrice(info.promoPriceInfos, { currentPrice: info.price })
        .promoPrice === 0
    )
      return 0;
    if (selectTypes.label === PurchaseTypes.ANNUITY) return info.annuity;
    if (selectTypes.label === PurchaseTypes.OWNERSHIP)
      return info.ownershipPriceOn;
    return 0;
  };

  /* Определяет значение прокрутки страницы относительно блока CallToAction */
  const getContainerScroll = () => {
    if (!ctaRef || !ctaRef.current || isDesktop940) return;
    const offsetTop = ctaRef.current.offsetTop - window.pageYOffset;
    const offset = Math.round(
      ((window.innerHeight - offsetTop) * 100) / ctaRef.current.clientHeight,
    );
    // Делим для получения приемлемых целочисленных значений
    setCtaScroll(offset / 18);
    // Вычитаем ширину с учётом отступов
    setCtaWidth(ctaRef.current.clientWidth - 56);
  };

  /* Обновляет цену единовременного платежа */
  useEffect(() => {
    getPrepaymentPrice();
  }, [
    showSettings,
    enabledTimeShift,
    enabledRouter,
    enabledSTB,
    enabledRemote,
    purchaseRouterPrice,
    purchaseSTBPrice,
    purchaseRemotePrice,
    selectRouterTypes,
    selectSTBTypes,
    selectRemoteTypes,
  ]);

  /* Добавляет слушатель на скролл */
  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', getContainerScroll);
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', getContainerScroll);
    };
  }, []);

  /* Scroll до элемента с идентификатором cta */
  useEffect(() => {
    const matcher = new RegExp(`${CALL_TO_ACTION_BLOCK_ID}`, 'g');
    if (matcher.test(window.location.href))
      setTimeout(() => {
        scrollToBlockById(CALL_TO_ACTION_BLOCK_ID);
      }, 200);
  }, []);

  /* Переход к виджету подключения */
  const scrollToWidget = () => {
    ctaRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };
  /* Обработка нажатия на кнопку "Подключить" */
  const openWizard = () => {
    setOpenConnectionWirard(true);
  };
  /* Флаг отображения визарда подключения */
  const showConnetcionWizard: boolean =
    !isDesktop940 || showSettings || openConnectionWirad;

  /* Флаг бизнес продукта */
  const isBusiness = marketingGroupCode === BUSINESS_GROUP_CODE;

  /* Выбирает стоимость подключения для LAN */
  const lanConnectionPrice =
    connectionMethod === ConnectionMethod.LAN && isBusiness
      ? connection
      : migrationCostFirst;

  /* Выбирает стоимость подключения */
  const productConnectionPrice =
    connectionMethod === ConnectionMethod.FTTH
      ? connection
      : lanConnectionPrice;

  /* Вычисляет бесплатность оборудования на продукте */
  const isFreeDevice = (
    devicePriceInfo: PromoPriceInfoProps,
    deviceOwnershipPriceOn: number,
  ): boolean => {
    // Флаг бесплатности сервиса
    const isFree = devicePriceInfo.promoPrice === 0;
    // Флаг возможности приобретения оборудования только через покупку
    const buyOnly = !devicePriceInfo.promoAnnuity && !deviceOwnershipPriceOn;
    // Флаг бесплатного пользования
    const isFreeOwnership = !isFree && !buyOnly && deviceOwnershipPriceOn === 0;
    return isFree || isFreeOwnership;
  };

  // Информация о стоимостях роутера
  const routerPrices =
    routerInfo?.promoPriceInfos &&
    getPromoPrice(routerInfo.promoPriceInfos, {
      currentPrice: routerInfo.price,
      currentAnnuity: routerInfo.annuity,
      currentDownPayment: routerInfo.downPayment,
    });

  // Информация о стоимостях приставки
  const STBPrices =
    STBInfo?.promoPriceInfos &&
    getPromoPrice(STBInfo.promoPriceInfos, {
      currentPrice: STBInfo.price,
      currentAnnuity: STBInfo.annuity,
      currentDownPayment: STBInfo.downPayment,
    });

  // Информация о стоимостях пульта
  const remotePrices =
    remoteInfo?.promoPriceInfos &&
    getPromoPrice(remoteInfo.promoPriceInfos, {
      currentPrice: remoteInfo.price,
      currentAnnuity: remoteInfo.annuity,
      currentDownPayment: remoteInfo.downPayment,
    });

  // Информация о стоимости подключения
  const connectionPrice = getPromoPrice(promoConnectionInfos, {
    currentPrice: connection,
  });

  // Информация о стоимости подключения к оптической сети
  const connectionFiberPrice = getPromoPrice(promoConnectionInfos, {
    currentPrice: productConnectionPrice,
  });

  /* Формирует сумму единовременного платежа исходя из выбранных настроек */
  const getPrepaymentPrice = () => {
    let prepaymentPrice =
      prepayment + (fixedPrice?.newPrice ?? migrationCostFirst);

    /* Если бизнес продукт, то добавляем стоимость подключения в сумму единовременного платежа */
    if (isBusiness) {
      prepaymentPrice += productConnectionPrice;
    }

    if (connectionMethod === ConnectionMethod.FTTH)
      prepaymentPrice += getPromoPrice(promoConnectionInfos, {
        currentPrice: connection,
      }).promoPrice;
    if (!showSettings) return prepaymentPrice;

    if (enabledRouter && selectRouterTypes) {
      if (selectRouterTypes.label === PurchaseTypes.PURCHASE) {
        prepaymentPrice += purchaseRouterPrice;
      } else if (selectRouterTypes.label === PurchaseTypes.OWNERSHIP) {
        prepaymentPrice +=
          getPromoPrice(routerInfo.promoPriceInfos, {
            currentPrice: routerInfo.price,
          }).promoPrice === 0
            ? 0
            : routerInfo.ownershipOneTimeCharge;
      } else if (selectRouterTypes.label === PurchaseTypes.ANNUITY) {
        prepaymentPrice +=
          getPromoPrice(routerInfo.promoPriceInfos, {
            currentPrice: routerInfo.price,
          }).promoPrice === 0
            ? 0
            : getPromoPrice(routerInfo.promoPriceInfos, {
                currentDownPayment: routerInfo.downPayment,
              }).promoDownPayment;
      }
    }
    if (enabledSTB && selectSTBTypes) {
      if (selectSTBTypes.label === PurchaseTypes.PURCHASE) {
        prepaymentPrice += purchaseSTBPrice;
      } else if (selectSTBTypes.label === PurchaseTypes.OWNERSHIP) {
        prepaymentPrice +=
          getPromoPrice(STBInfo.promoPriceInfos, {
            currentPrice: STBInfo.price,
          }).promoPrice === 0
            ? 0
            : STBInfo.ownershipOneTimeCharge;
      } else if (selectSTBTypes.label === PurchaseTypes.ANNUITY) {
        prepaymentPrice +=
          getPromoPrice(STBInfo.promoPriceInfos, {
            currentPrice: STBInfo.price,
          }).promoPrice === 0
            ? 0
            : getPromoPrice(STBInfo.promoPriceInfos, {
                currentDownPayment: STBInfo.downPayment,
              }).promoDownPayment;
      }
    }
    if (
      enabledRemote &&
      selectRemoteTypes &&
      selectRemoteTypes.label === PurchaseTypes.PURCHASE
    )
      prepaymentPrice += purchaseRemotePrice;

    return prepaymentPrice;
  };

  // Абонентская плата в день
  const [feePerDay, setFeePerDay] = useState<number>(daily);

  /* Формирует комментарий для СПА */
  const createSPAComment = (): string => {
    // Формирует информацию о типе приобретения оборудования
    const getDeviceInfo = (deviceType: CallToActionDeviceTypes): string => {
      // Определяет тип переданного устройства
      const expression =
        deviceType === CallToActionDeviceTypes.ROUTER
          ? selectRouterTypes.label
          : selectSTBTypes.label;
      // Определяет количество устройств, приобретаемых в собственность
      const amountDevices =
        deviceType === CallToActionDeviceTypes.ROUTER
          ? selectRouterAmount.label
          : selectSTBAmount.label;

      switch (expression) {
        case PurchaseTypes.PURCHASE:
          return `Покупка_${amountDevices.replace(' ', '_')}`;
        case PurchaseTypes.ANNUITY:
          return 'Рассрочка';
        case PurchaseTypes.OWNERSHIP:
          return 'Аренда';
        default:
          return 'Не нужен';
      }
    };
    // Информация о выбранных устройствах
    /* yaspeller ignore:start */
    // prettier-ignore
    const devicesComment = `Управление просмотром: ${
      enabledTimeShift ? 'Вкл' : 'Выкл'}\nРоутер: ${
      enabledRouter && showSettings
        ? getDeviceInfo(CallToActionDeviceTypes.ROUTER)
        : 'Не нужен'
    }\nТелеприставка: ${
      enabledSTB && showSettings
        ? getDeviceInfo(CallToActionDeviceTypes.STB)
        : 'Не нужен'
    }\nПульт ГУ: ${enabledRemote ? 'Вкл' : 'Выкл'}`;
    // prettier-ignore
    return `${
      showSettings ? `${devicesComment}\n` : ''}Абонентская плата: ${
      formatNumber(feePerDay)} руб. в день\nПодключение к сети: ${
      formatNumber(
      getPromoPrice(promoConnectionInfos, {currentPrice: migrationCostFirst}).promoPrice,
    )} руб.\nПодключение к продукту: ${
      formatNumber(getPrepaymentPrice())} руб.${
      isAnnual ? `\nПодключение на год: ${
        annual === 0 ? 'Бесплатно' : formatNumber(annual)} руб.` : ''
    }`;
    /* yaspeller ignore:end */
  };

  return (
    <CallToActionContainer id={CALL_TO_ACTION_BLOCK_ID} ref={ctaRef}>
      <div>
        <H2>
          Заявка на{'\u00A0'}подключение
          {isAuth && ` по${'\u00A0'}новому адресу`}
        </H2>
        <CallToActionProduct
          customHeader={customHeader}
          marketingGroupName={marketingGroupName}
          marketingText={marketingText}
          tags={tags}
          seriesName={seriesName}
          showSettings={showSettings}
          setShowSettings={setShowSettings}
          connectionType={connectionMethod}
          isAnnual={isAnnual}
          isSwitchOffTV={!allChannelCount || timeshiftPrice > 0}
          isSwitchOffRouter={
            !routerInfo ||
            !isFreeDevice(routerPrices, routerInfo.ownershipPriceOn)
          }
          isSwitchOffSTB={
            !STBInfo || !isFreeDevice(STBPrices, STBInfo.ownershipPriceOn)
          }
          isSwitchOffRemote={
            !remoteInfo ||
            !isFreeDevice(remotePrices, remoteInfo.ownershipPriceOn)
          }
          connectionPrice={migrationCostFirst}
          setEnabledTimeShift={setEnabledTimeShift}
          setEnabledRouter={setEnabledRouter}
          setEnabledSTB={setEnabledSTB}
          setEnabledRemote={setEnabledRemote}
          marketingGroupCode={marketingGroupCode}
        />
        {showSettings && (
          <>
            <CallToActionInternet
              connectionMethod={connectionMethod}
              speedBaseText={speedBaseText}
              connection={connectionPrice.promoPrice}
              finishDt={connectionPrice.finishDt}
              isAction={connection !== connectionPrice.promoPrice}
            />
            {channelCount && (
              <CallToActionTelevision
                allChannelCount={allChannelCount}
                hdChannelCount={hdChannelCount}
                uhdChannelCount={uhdChannelCount}
                channelCount={channelCount}
                isTransformer={isTransformer}
              />
            )}
            {isAvailable && allChannelCount && (
              <CallToActionTimeShift
                price={timeshiftPrice}
                enabledTimeShift={enabledTimeShift}
                setEnabledTimeShift={setEnabledTimeShift}
              />
            )}
            {routerInfo && (
              <CallToActionDevice
                {...routerInfo}
                urlDeviceImg={getDeviceImg(routerInfo.type)}
                enabledDevice={enabledRouter}
                setEnabledDevice={setEnabledRouter}
                purchaseDevicePrice={purchaseRouterPrice}
                setPurchaseDevicePrice={setPurchaseRouterPrice}
                price={routerInfo.price}
                annuity={routerPrices.promoAnnuity}
                downPayment={routerPrices.promoDownPayment}
                dataSelectPurchaseTypes={filterPurchaseTypes(
                  dataSelectPurchase,
                  routerInfo.annuity,
                  routerInfo.ownershipPriceOn,
                )}
                dataSelectAmountTypes={dataSelectAmount}
                setSelectTypes={setSelectRouterTypes}
                selectTypes={selectRouterTypes}
                selectAmount={selectRouterAmount}
                setSelectAmount={setSelectRouterAmount}
                // Станислав из маркетинга: акции у нас будут только на покупку
                isAction={routerInfo.price !== routerPrices.promoPrice}
                maxPromoDevice={maxPromoRouters}
              />
            )}
            {STBInfo && (
              <CallToActionDevice
                {...STBInfo}
                urlDeviceImg={getDeviceImg(STBInfo.type)}
                enabledDevice={enabledSTB}
                setEnabledDevice={setEnabledSTB}
                purchaseDevicePrice={purchaseSTBPrice}
                setPurchaseDevicePrice={setPurchaseSTBPrice}
                price={STBPrices.promoPrice}
                annuity={STBPrices.promoAnnuity}
                downPayment={STBPrices.promoDownPayment}
                dataSelectPurchaseTypes={filterPurchaseTypes(
                  dataSelectPurchase,
                  STBInfo.annuity,
                  STBInfo.ownershipPriceOn,
                )}
                dataSelectAmountTypes={dataSelectAmount}
                setSelectTypes={setSelectSTBTypes}
                selectTypes={selectSTBTypes}
                selectAmount={selectSTBAmount}
                setSelectAmount={setSelectSTBAmount}
                // Станислав из маркетинга: акции у нас будут только на покупку
                isAction={STBInfo.price !== STBPrices.promoPrice}
              />
            )}
            {remoteInfo && (
              <CallToActionDevice
                {...remoteInfo}
                urlDeviceImg={getDeviceImg(remoteInfo.type)}
                enabledDevice={enabledRemote}
                setEnabledDevice={setEnabledRemote}
                purchaseDevicePrice={purchaseRemotePrice}
                setPurchaseDevicePrice={setPurchaseRemotePrice}
                price={remotePrices.promoPrice}
                annuity={remotePrices.promoAnnuity}
                downPayment={remotePrices.promoDownPayment}
                dataSelectPurchaseTypes={dataSelectPurchase}
                dataSelectAmountTypes={dataSelectAmount}
                setSelectTypes={setSelectRemoteTypes}
                selectTypes={selectRemoteTypes}
                selectAmount={selectRemoteAmount}
                setSelectAmount={setSelectRemoteAmount}
                // Станислав из маркетинга: акции у нас будут только на покупку
                isAction={remoteInfo.price !== remotePrices.promoPrice}
              />
            )}
          </>
        )}
        {!isAnnual && (
          <CallToActionFee
            annual={isAnnual && migrationCostFirst}
            daily={daily}
            thirtyDays={thirtyDays}
            timeshiftPrice={enabledTimeShift ? timeshiftPrice : 0}
            distortionDuration={distortionDuration}
            routerFee={getDeviceFee(
              enabledRouter,
              routerInfo,
              selectRouterTypes,
            )}
            STBFee={
              STBInfo ? getDeviceFee(enabledSTB, STBInfo, selectSTBTypes) : 0
            }
            setFeePerDay={setFeePerDay}
          />
        )}
        {fixedPrice && (
          <CallToActionFixedPrice {...fixedPrice} annual={migrationCostFirst} />
        )}
        {isAnnual && !fixedPrice && (
          <CallToActionAnnual annual={migrationCostFirst} />
        )}
        {((isAnnual && connectionMethod === ConnectionMethod.FTTH) ||
          !isAnnual) && (
          <CallToActionConnection
            connectionMethod={connectionMethod}
            connection={connectionFiberPrice.promoPrice}
            finishDt={connectionFiberPrice.finishDt}
            isAction={
              productConnectionPrice !== connectionFiberPrice.promoPrice
            }
          />
        )}
        <CallToActionPrepayment prepaymentPrice={getPrepaymentPrice()} />
        <CallToActionConnectionButtonSticky
          amountScroll={ctaScroll}
          clientWidth={ctaWidth}
          showSettings={showSettings}
        >
          <Button onClick={scrollToWidget}>Подключить</Button>
        </CallToActionConnectionButtonSticky>
        {!showSettings && !showConnetcionWizard && (
          <CallToActionConnectionButton>
            <Button onClick={openWizard}>Подключить</Button>
          </CallToActionConnectionButton>
        )}
      </div>
      {showConnetcionWizard && (
        <CallToActionConnectionWizard>
          <H3>Подключение к Планете</H3>
          <ConnectionWizard
            isInCTA
            comment={createSPAComment()}
            tariff={seriesName}
            seriesCode={seriesCode}
          />
        </CallToActionConnectionWizard>
      )}
    </CallToActionContainer>
  );
};

export default observer(CallToAction);
