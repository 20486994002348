/** libraries */
import { useRouter } from 'next/router';
import { useEffect } from 'react';
/** api */
import { cookies } from '~/api/api';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
/** constants */
import { TOKEN, CONTRACT_AUTH, TEMPORARY_AUTH_QS } from '~/constants/common';
/** stores */
import { useRootStore } from '~/stores/RootStore';

export const useAuthDataFetching = (): void => {
  const router = useRouter();
  const {
    authStore: {
      isAuth,
      makeAuthData,
      setAuth,
      removeAuth,
      createAuth,
      getAuthDataByTemporaryToken,
      checkAuthAndSetContractData,
      getAuthDataByIp,
      setIsFirstAuth,
    },
  } = useRootStore();
  const { setIsLoading, setIsVisible, resetMakeAuth } = useMakeAuthStore();
  const currentToken = cookies.get(TOKEN);
  const contractAuth = cookies.get(CONTRACT_AUTH);
  useEffect(() => {
    if (contractAuth) setAuth({ ...contractAuth, token: currentToken });
  }, [contractAuth]);

  useEffect(() => {
    (async () => {
      if (makeAuthData) {
        const { authenticationMethod, token } = makeAuthData;
        removeAuth();
        if (router.query?.showAutopayment) {
          setIsFirstAuth(true);
        }
        createAuth(
          makeAuthData,
          token,
          null,
          setIsLoading,
          setIsVisible,
          authenticationMethod,
        );
      }
    })();
  }, [makeAuthData]);

  useEffect(() => {
    if (!router.isReady) return;
    const contactName = router.query?.[
      TEMPORARY_AUTH_QS.CONTACT_NAME
    ] as string;
    const verifyToken = router.query?.[
      TEMPORARY_AUTH_QS.TEMPORARY_TOKEN
    ] as string;

    /** уже выполнен вход */
    if (!contactName || !verifyToken) return;
    (async () => {
      getAuthDataByTemporaryToken(
        contactName,
        verifyToken,
        currentToken,
        null,
        setIsLoading,
        setIsVisible,
      );
    })();
  }, [router.query]);

  useEffect(() => {
    if (!router.isReady) return;
    const contactName = router.query?.[TEMPORARY_AUTH_QS.CONTACT_NAME];
    const verifyToken = router.query?.[TEMPORARY_AUTH_QS.TEMPORARY_TOKEN];
    if (!contactName || !verifyToken) {
      (async () => {
        await Promise.all([
          checkAuthAndSetContractData(currentToken, null, true),
          getAuthDataByIp(currentToken, null, setIsLoading, setIsVisible),
        ]);
      })();
    }
  }, [router.isReady]);

  useEffect(() => {
    if (!isAuth) {
      resetMakeAuth();
    }
  }, [isAuth]);
};
