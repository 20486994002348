/** libraries */
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Input,
  Link,
  PriceTag,
  Snoska,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import {
  HeaderDesktop,
  HeaderMobile,
  StyledAutopaymentSuggestion,
  StyledResult,
  SuccessHeaderDesktop,
  SuccessHeaderMobile,
  SuccessText,
} from './styles';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import {
  DOCUMENTS_LINK,
  VIEW_TYPES,
} from '~/components/Blocks/Shared/Autopayment/AutoPaymentWizard/constants';
import {
  AUTOPAYMENT_MIN_AMOUNT,
  AUTOPAYMENT_MAX_AMOUNT,
} from '~/components/Blocks/Shared/Autopayment/constants';
/** types */
import { AmountProps, StateFormErrorProps } from '../../../AutoPayment/types';
import { SbpBindingCheckInfoProps } from '../../interfaces';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import usePinWizardStore from '~/components/Blocks/Shared/PinWizard/store/usePinWizardStore';

interface AutopaymentSuggestionProps {
  contractNumber: string;
  token: string;
  sbpBindingCheckInfo: SbpBindingCheckInfoProps;
  linkedAccount: string;
  cardBindingId: number;
  linkedCardValue: string;
  setAmount: Dispatch<SetStateAction<AmountProps>>;
  amount: AmountProps;
  stateFormError: StateFormErrorProps;
  setIsAutoCount: Dispatch<SetStateAction<boolean>>;
  bindAutoPayment: (
    id?: number,
    token?: string,
    contractNameFromPaymentCheck?: string,
  ) => void;
  viewType: VIEW_TYPES;
  minAmountValue: number;
  errorMessage: string;
}

const AutopaymentSuggestion: FC<AutopaymentSuggestionProps> = ({
  contractNumber,
  token,
  sbpBindingCheckInfo,
  linkedAccount,
  cardBindingId,
  linkedCardValue,
  setAmount,
  amount,
  stateFormError,
  setIsAutoCount,
  bindAutoPayment,
  viewType,
  minAmountValue,
  errorMessage,
}: AutopaymentSuggestionProps): JSX.Element => {
  const {
    autoPaymentInfoStore: { autoPaymentData },
  } = useRootStore();
  const { setIsShowPinSidePage } = usePinWizardStore();

  /** Другая сумма */
  const [isOtherAmount, setIsOtherAmount] = useState<boolean>(!autoPaymentData);
  /** Подключить автоплатёж после авторизации */
  const [isBindAfterAuth, setIsBindAfterAuth] = useState<boolean>(false);

  /** id счёта для подключения автоплатежа */
  const accountId = useMemo(() => {
    return (
      sbpBindingCheckInfo?.paymentBindingId ??
      Number(linkedAccount) ??
      cardBindingId ??
      Number(linkedCardValue)
    );
  }, [
    linkedAccount,
    cardBindingId,
    linkedCardValue,
    sbpBindingCheckInfo?.paymentBindingId,
  ]);

  const maxPayment = autoPaymentData?.maxPayment || AUTOPAYMENT_MAX_AMOUNT;
  const minPayment = autoPaymentData?.minPayment || AUTOPAYMENT_MIN_AMOUNT;

  // При вводе суммы
  const handleOnChangeInputMoney = (params) => {
    const { value, forSend } = params;
    const maxAmountValue = String(maxPayment).replace(/\u00A0/, '');

    if (forSend > +maxAmountValue) {
      setAmount({
        value: `${maxAmountValue} \u20BD`,
        forSend: `${maxAmountValue}`,
      });

      return;
    }
    if (!Number.isNaN(forSend)) setAmount({ value, forSend });
    if (Number.isNaN(forSend)) setAmount({ value: '0 \u20BD', forSend: '0' });
  };

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Сумма автоплатежа */
  const autopaymentAmount = useMemo(() => {
    if (!autoPaymentData && Number(amount.forSend) < AUTOPAYMENT_MIN_AMOUNT)
      return AUTOPAYMENT_MIN_AMOUNT;
    if (!autoPaymentData && Number(amount.forSend) > AUTOPAYMENT_MAX_AMOUNT)
      return AUTOPAYMENT_MAX_AMOUNT;
    if (autoPaymentData?.autoCalcPayment < autoPaymentData?.minPayment)
      return autoPaymentData?.minPayment;
    if (autoPaymentData?.autoCalcPayment > autoPaymentData?.maxPayment)
      return autoPaymentData?.maxPayment;

    if (!autoPaymentData) return amount.forSend;

    return autoPaymentData?.autoCalcPayment;
  }, [autoPaymentData, amount]);

  useEffect(() => {
    setAmount({
      value: `${autopaymentAmount} \u20BD`,
      forSend: `${autopaymentAmount}`,
    });
  }, [autopaymentAmount, isOtherAmount]);

  useEffect(() => {
    if (!autoPaymentData) {
      setIsAutoCount(false);
      return;
    }

    if (!isBindAfterAuth) {
      setIsOtherAmount(false);
      return;
    }

    handleAutopayment();
  }, [autoPaymentData]);

  const result = () => {
    return (
      <StyledResult>
        {viewType === VIEW_TYPES.BIND_SUCCESS && (
          <>
            {isDesktop940 ? (
              <SuccessHeaderDesktop>Автоплатёж подключён!</SuccessHeaderDesktop>
            ) : (
              <SuccessHeaderMobile color={defaultTheme.colors.black}>
                Автоплатёж подключён!
              </SuccessHeaderMobile>
            )}
            <SuccessText lineHeight="24px">
              Вы&nbsp;можете управлять платежами на&nbsp;странице{' '}
              <Link href="/payment">Оплаты услуг Планеты</Link>
            </SuccessText>
          </>
        )}
      </StyledResult>
    );
  };

  if (viewType === VIEW_TYPES.BIND_SUCCESS) {
    return result();
  }

  const handlerAutoCalc = () => {
    setIsAutoCount(true);
    if (!autoPaymentData) {
      setIsShowPinSidePage(true);
      return;
    }
    setAmount({
      value: `${autopaymentAmount} \u20BD`,
      forSend: `${autopaymentAmount}`,
    });
    if (!isBindAfterAuth && isOtherAmount) setIsOtherAmount(false);
  };

  const handleAutopayment = () => {
    if (!autoPaymentData) {
      setIsShowPinSidePage(true);
      setIsBindAfterAuth(true);
      return;
    }
    bindAutoPayment(accountId, token, !accountId ? contractNumber : null);
  };

  return (
    <StyledAutopaymentSuggestion>
      {isDesktop940 ? (
        <HeaderDesktop>
          Подключите автоплатёж{' '}
          {contractNumber ? (
            `для договора ${contractNumber}`
          ) : (
            <>для этого счёта СБП</>
          )}
        </HeaderDesktop>
      ) : (
        <HeaderMobile color={defaultTheme.colors.black}>
          Подключите автоплатёж{' '}
          {contractNumber ? (
            `для договора ${contractNumber}`
          ) : (
            <>для этого счёта СБП</>
          )}
        </HeaderMobile>
      )}
      {isOtherAmount ? (
        <>
          <div className="autopayment__money">
            <div className="autopayment__money__block">
              <div>
                <Text className="autopayment__input-text">
                  Сумма автоплатежа
                </Text>
                <Input
                  className="autopayment__input-field"
                  type="money"
                  placeholder="500 &#8381;"
                  value={amount.value}
                  onChangeCustomInput={handleOnChangeInputMoney}
                  width="100%"
                />
              </div>
              <Text
                lineHeight="24px"
                color={defaultTheme.colors.planeta}
                onClick={handlerAutoCalc}
              >
                Рассчитывать при каждой оплате
              </Text>
            </div>
            <Text
              color={
                stateFormError.amount.error
                  ? defaultTheme.colors.planeta
                  : defaultTheme.colors.gray
              }
              lineHeight="24px"
            >
              Сумма {isDesktop940 && 'платежа'} от {minPayment} до {maxPayment}{' '}
              {isDesktop940 ? 'рублей' : '₽'}.
              <br />
              <Text color={defaultTheme.colors.gray} lineHeight="24px">
                Оплата произойдёт, когда баланс лицевого счета будет
                меньше&nbsp;100&nbsp;₽
              </Text>
            </Text>
          </div>
        </>
      ) : (
        <div className="autopayment__block">
          <PriceTag
            header="Сумма автоплатежа"
            value={`${autopaymentAmount} ₽`}
            subscription={`Оплата произойдёт, когда баланс${'\u000A'}лицевого счёта будет меньше${'\u00A0'}100${'\u00A0'}₽`}
            headerType="H2"
          />
          <div className="autopayment__warning-block">
            <Text lineHeight="24px">
              <Text lineHeight="24px" fontWeightBold>
                Сумма автоплатежа может меняться
              </Text>{' '}
              в&nbsp;зависимости от&nbsp;состава подключённых услуг. Расчёт
              производится автоматически перед каждой оплатой. Сумма
              рассчитывается как среднее значение списаний за&nbsp;30&nbsp;дней.
            </Text>
            <Text
              className="autopayment__warning-block__button"
              lineHeight="24px"
              color={defaultTheme.colors.planeta}
              onClick={() => {
                setIsAutoCount(false);
                setAmount({
                  value: `${autopaymentAmount} \u20BD`,
                  forSend: `${autopaymentAmount}`,
                });
                setIsOtherAmount(true);
              }}
            >
              Указать другую сумму
            </Text>
          </div>
        </div>
      )}
      <Button className="autopayment__button" onClick={handleAutopayment}>
        Подключить автоплатёж
      </Button>
      {errorMessage && (
        <Text
          className="autopayment__error"
          lineHeight="24px"
          color={defaultTheme.colors.planeta}
        >
          {errorMessage}
        </Text>
      )}
      <div className="autopayment__snoska">
        <Snoska>
          Для подключения автоплатежа мы&nbsp;спишем с&nbsp;карты{' '}
          {minAmountValue}&nbsp;₽&nbsp;и&nbsp;зачислим их&nbsp;на&nbsp;ваш
          Лицевой счёт.
          <br />
          Нажатием кнопки вы&nbsp;подтверждаете согласие с&nbsp;условиями
          подключения автоплатежа, указанными в{'\u00A0'}
          <Link href={DOCUMENTS_LINK} target="_blank">
            Регламенте
          </Link>
          .
        </Snoska>
      </div>
    </StyledAutopaymentSuggestion>
  );
};

export default observer(AutopaymentSuggestion);
