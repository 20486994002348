/** libraries */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Carousel,
  SliderButtons,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { nanoid } from 'nanoid';
import { observer } from 'mobx-react';
import { isMobile } from 'react-device-detect';
/** Components */
import NotificationCard from './Components/NotificationCard/NotificationCard';
import DeviceNotification from './Components/DeviceNotification/DeviceNotification';
/** constants */
import { OWNERSHIP_CODES } from '../Devices/constants';
import { OPERATING_STATE, SUSPEND_STATE } from '../Contract/constants';
/** styles */
import { StyledSubscriptions } from './styles';
/** components */
import ProlongationNotification from './Components/ProlongationNotification/ProlongationNotification';
import CloseToZombie from './Components/CloseToZombie/CloseToZombie';
import Zombie from './Components/Zombie/Zombie';
import VoluntarySuspension from './Components/VoluntarySuspension/VoluntarySuspension';
import SuspensionForUnknownReason from './Components/SuspensionForUnknownReason/SuspensionForUnknownReason';
import ProlongationInformation from './Components/ProlongationInformation/ProlongationInformation';
import SuspendedForNonPayment from './Components/SuspendedForNonPayment/SuspendedForNonPayment';
import Prepayment from './Components/Prepayment/Prepayment';
import NewContractMessage from './Components/NewContractMessage/NewContractMessage';
/** interfaces */
import {
  PassiveMessageProps,
  SubscriptionsProps,
  ZombieInfoProps,
} from './interfaces';
import { ProlongationProps } from '../Contract/types';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** api */
import {
  getNewContractInfo,
  getPassiveMessages,
  getProlongationInfo,
  getStrayInfo,
} from '~/api/apiPab2c';
/** constants */
import { NewContractProps, NEW_CONTRACT_STATUS } from '../ContractState/types';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVacationStore from '../../Vacation/store/useVacationStore';

const Subscriptions: FC<SubscriptionsProps> = ({
  content,
}: SubscriptionsProps) => {
  const forwardRef = useRef<HTMLDivElement | null>(null);

  const {
    authStore: { auth, isAuth },
    contractInfoStore: {
      promoTariffStateAutoDaysLeft,
      promoTariffStateProlongationPrice,
    },
    pab2cDevicesStore: { pab2cDevicesList },
    contractStateStore: { contractState, suspendCondition },
  } = useRootStore();
  const { isOrdered } = useVacationStore();

  /** Информация услуги пролонгация на договоре */
  const [prolongationInfo, setProlongationInfo] = useState<ProlongationProps>(
    null,
  );
  const getProlongation = async () => {
    try {
      const res = await getProlongationInfo();
      setProlongationInfo(res);
    } catch (e) {
      console.error('getProlongationInfo', e);
    }
  };
  useEffect(() => {
    if (isAuth) {
      getProlongation();
    } else {
      setProlongationInfo(null);
    }
  }, [isAuth]);

  /** Состояние договора для промо текста или для просмотра истории */
  const stateForPromo =
    [
      OPERATING_STATE.ON,
      OPERATING_STATE.CLIENT_BLOCK,
      OPERATING_STATE.PROVIDER_BLOCK_DEBT,
    ].includes(contractState) ||
    (suspendCondition?.suspendState === SUSPEND_STATE.order &&
      contractState === OPERATING_STATE.ON);

  /** Промо годового продукта  */
  const isAnnualPromo =
    stateForPromo &&
    prolongationInfo?.prolongation?.promoDaysInSeries === 365 &&
    prolongationInfo?.tariffDurationChangePromoInfo &&
    promoTariffStateAutoDaysLeft;

  /** Годовой продукт без промо */
  const isAnnual =
    stateForPromo && prolongationInfo?.prolongation?.promoDaysInSeries === 365;

  /** Продление годового промо продукта */
  const isProlongationPromoAnnual =
    isAnnualPromo && promoTariffStateAutoDaysLeft <= 180;

  /** Продление годового продукта */
  const isProlongationAnnual =
    isAnnual &&
    promoTariffStateAutoDaysLeft <= 30 &&
    auth.balance - promoTariffStateProlongationPrice < 0;

  /** Оборудование в пользовании с передачей в собственность */
  const devices = useMemo(() => {
    const filteredDevices = pab2cDevicesList
      .filter(
        (item) =>
          [
            OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP,
            OWNERSHIP_CODES.LEASING,
          ].includes(item.ownership) &&
          item.ownershipEndDays > 0 &&
          item.purchasePrice > 0,
      )
      .map((item) => {
        const addFields = content.fields.allDevices?.find(
          (device) => device.id === item.deviceModelId,
        );
        return {
          ...item,
          ...addFields,
          annuity:
            item.ownership === OWNERSHIP_CODES.BOUGHT_LEASING
              ? item?.annuity
              : addFields?.annuity,
        };
      });
    if (filteredDevices.length > 3) filteredDevices.length = 3;
    return filteredDevices;
  }, [pab2cDevicesList]);

  /** Информация о зомбировании */
  const [zombieInfo, setZombieInfo] = useState<ZombieInfoProps>(null);

  /** Договор близок к зомбированию */
  const isCloseToZombie = useMemo(() => {
    if (
      zombieInfo?.daysLeft &&
      zombieInfo?.daysLeft >= 0 &&
      zombieInfo?.daysMax
    ) {
      return (
        (zombieInfo.daysMax - zombieInfo.daysLeft) / zombieInfo.daysMax >= 0.25
      );
    }
    return false;
  }, [zombieInfo]);

  /** Получает информацию о зомбировании */
  const getStray = async () => {
    try {
      const res = await getStrayInfo();
      setZombieInfo(res);
    } catch (e) {
      console.error('getStrayInfoError', e);
    }
  };

  /** Информация о новом контракте */
  const [newContractInfo, setNewContractInfo] = useState<NewContractProps>(
    null,
  );
  const getNewContract = async () => {
    try {
      const res = await getNewContractInfo();
      setNewContractInfo(res);
    } catch (e) {
      console.error('getNewContractInfoError', e);
    }
  };

  /** Пассивные сообщения */
  const [passiveMessages, setPassiveMessages] = useState<PassiveMessageProps[]>(
    [],
  );
  const getPassiveNotification = async () => {
    try {
      const res = await getPassiveMessages();
      setPassiveMessages(res);
    } catch (e) {
      console.error('getPassiveMessagesError', e);
    }
  };
  /** Генерация ключей для списка */
  const ids = useMemo(
    () => (passiveMessages.length ? passiveMessages.map(() => nanoid(5)) : []),
    [passiveMessages],
  );

  /** Флаг открытия уведомлений пассивных сообщений */
  const [isOpenPassiveMessages, setIsOpenPassiveMessages] = useState<boolean>(
    true,
  );

  const changeOpenMessages = (isOpen: boolean) => {
    setIsOpenPassiveMessages(!isOpen);
  };

  const isClose = useMemo(() => {
    return !isOpenPassiveMessages;
  }, [isOpenPassiveMessages]);

  useEffect(() => {
    if (!isAuth) return;
    if (contractState === OPERATING_STATE.NEW) {
      getNewContract();
    }
    if (
      contractState === OPERATING_STATE.STRAY ||
      contractState === OPERATING_STATE.PROVIDER_BLOCK_DEBT
    ) {
      getStray();
    }
    getPassiveNotification();
  }, [contractState, isAuth]);

  /** Блокировка оператором по неуплате */
  const isProviderBlockForNonPayment =
    contractState === OPERATING_STATE.PROVIDER_BLOCK_DEBT && auth.balance <= 0;

  /** Приостановка по неизвестной причине */
  const isUnknownReason =
    (contractState &&
      ![
        OPERATING_STATE.ON,
        OPERATING_STATE.CLIENT_BLOCK,
        OPERATING_STATE.STRAY,
        OPERATING_STATE.NEW,
        OPERATING_STATE.PROVIDER_BLOCK_DEBT,
      ].includes(contractState)) ||
    (contractState === OPERATING_STATE.PROVIDER_BLOCK_DEBT && auth.balance > 0);

  const isNotification =
    [
      NEW_CONTRACT_STATUS.MountPlanning,
      NEW_CONTRACT_STATUS.Connecting,
      NEW_CONTRACT_STATUS.Unknown,
      NEW_CONTRACT_STATUS.NoPrepayment,
      NEW_CONTRACT_STATUS.Insufficiently,
    ].includes(newContractInfo?.status) ||
    (zombieInfo && isCloseToZombie) ||
    [OPERATING_STATE.STRAY].includes(contractState) ||
    (contractState === OPERATING_STATE.CLIENT_BLOCK && !isOrdered) ||
    isProviderBlockForNonPayment ||
    isProlongationAnnual ||
    isUnknownReason;

  return (
    <StyledSubscriptions
      isManyPassiveMessages={isMobile && passiveMessages.length > 1}
      isManyNotification={
        isMobile &&
        forwardRef?.current?.children[0]?.children[0]?.children?.length > 1
      }
    >
      {/* Устройства */}
      {devices.length > 0 && (
        <div className="subscriptions__block">
          <NotificationCard
            color={defaultTheme.colors.planeta}
            header="Оборудование станет вашим по сниженной стоимости!"
            isSpecialOfferTag
            isHide
          >
            <DeviceNotification devices={devices} />
          </NotificationCard>
        </div>
      )}
      {/* Баннер пролонгации */}
      {isProlongationPromoAnnual && (
        <div className="subscriptions__block">
          <NotificationCard
            color={defaultTheme.colors.planeta}
            header={parseHtml(content.fields?.prolongation?.header)}
            isSpecialOfferTag
            isHide
          >
            <ProlongationNotification
              prolongation={content.fields?.prolongation}
              prolongationInfo={prolongationInfo}
            />
          </NotificationCard>
        </div>
      )}
      {isNotification && (
        <div
          ref={forwardRef}
          className="subscriptions__block subscriptions__carousel-wrapper subscriptions__notification"
        >
          <Carousel duration={1000} widgets={[SliderButtons]}>
            {/* Договор близок к зомби */}
            {zombieInfo && isCloseToZombie && (
              <NotificationCard
                color={defaultTheme.colors.planeta}
                header="Демонтаж оборудования оператора"
              >
                <CloseToZombie zombieInfo={zombieInfo} />
              </NotificationCard>
            )}
            {/* Договор зомби */}
            {contractState === OPERATING_STATE.STRAY && (
              <NotificationCard
                color={defaultTheme.colors.planeta}
                header="Договор не обслуживается"
              >
                <Zombie />
              </NotificationCard>
            )}
            {/* Добровольная приостановка */}
            {contractState === OPERATING_STATE.CLIENT_BLOCK && (
              <NotificationCard
                color={defaultTheme.colors.planeta}
                header="Обслуживание приостановлено по вашей заявке"
              >
                <VoluntarySuspension />
              </NotificationCard>
            )}
            {/* Приостановка обслуживания по неизвестной причине */}
            {isUnknownReason && (
              <NotificationCard
                color={defaultTheme.colors.planeta}
                header="Предоставление услуг приостановлено оператором"
              >
                <SuspensionForUnknownReason />
              </NotificationCard>
            )}
            {/* Продление годового продукта */}
            {isProlongationAnnual && (
              <NotificationCard
                color={defaultTheme.colors.planeta}
                header="Продление продукта"
              >
                <ProlongationInformation prolongationInfo={prolongationInfo} />
              </NotificationCard>
            )}
            {/* Обслуживание приостановлено за неуплату */}
            {isProviderBlockForNonPayment && (
              <NotificationCard
                color={defaultTheme.colors.planeta}
                header="Обслуживание приостановлено за неуплату"
              >
                <SuspendedForNonPayment />
              </NotificationCard>
            )}
            {/* Аванс нового клиента */}
            {[
              NEW_CONTRACT_STATUS.NoPrepayment,
              NEW_CONTRACT_STATUS.Insufficiently,
            ].includes(newContractInfo?.status) && (
              <NotificationCard
                color={defaultTheme.colors.planeta}
                header={
                  newContractInfo?.leftToPay === 0
                    ? 'Внесите авансовый платёж'
                    : 'Недостаточно средств для аванса'
                }
              >
                <Prepayment newContractInfo={newContractInfo} />
              </NotificationCard>
            )}
            {[
              NEW_CONTRACT_STATUS.MountPlanning,
              NEW_CONTRACT_STATUS.Connecting,
              NEW_CONTRACT_STATUS.Unknown,
            ].includes(newContractInfo?.status) && (
              <NotificationCard
                color={
                  newContractInfo?.status === NEW_CONTRACT_STATUS.Unknown
                    ? defaultTheme.colors.black
                    : defaultTheme.colors.planeta
                }
                header={
                  // eslint-disable-next-line no-nested-ternary
                  newContractInfo?.status === NEW_CONTRACT_STATUS.MountPlanning
                    ? 'Договор заключён'
                    : newContractInfo?.status === NEW_CONTRACT_STATUS.Connecting
                    ? 'Назначена дата подключения'
                    : 'Процесс подключения приостановлен'
                }
              >
                <NewContractMessage newContractInfo={newContractInfo} />
              </NotificationCard>
            )}
          </Carousel>
        </div>
      )}
      {passiveMessages.length > 0 &&
        ![OPERATING_STATE.STRAY, OPERATING_STATE.DREGS].includes(
          contractState,
        ) && (
          <div
            className={`subscriptions__block subscriptions__carousel-wrapper subscriptions__passive-messages ${
              isClose ? 'trans' : ''
            }`}
          >
            <Carousel duration={1000} widgets={[SliderButtons]}>
              {passiveMessages.map((item, index) => {
                return (
                  <NotificationCard
                    color={defaultTheme.colors.green}
                    header="Уведомления"
                    key={ids[index]}
                    func={changeOpenMessages}
                    isOpenMessage={isOpenPassiveMessages}
                    isHide
                    isCarousel
                  >
                    <Text lineHeight="24px">{parseHtml(item.content)}</Text>
                  </NotificationCard>
                );
              })}
            </Carousel>
          </div>
        )}
    </StyledSubscriptions>
  );
};

export default observer(Subscriptions);
