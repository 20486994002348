/** libraries */
import { FC, useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  Button,
  defaultTheme,
  H2,
  H3,
  Icons,
  LeadingText,
  Link,
  Loader,
  Text,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import {
  LoaderWrapper,
  StyledPaymentResult,
  SuccessHeaderDesktop,
  SuccessHeaderMobile,
  SuccessText,
} from './styles';
/** constants */
import { RESULT_STATE } from './constants';
import { desktop940 } from '~/components/Grid/constants';
/** interfaces */
import { PaymentResultProps } from './interfaces';
/** utils */
import Portal from '~/components/Portal/Portal';
/** components */
import SbpBindingSuggestion from './Components/SbpBindingSuggestion/SbpBindingSuggestion';
import AutopaymentSuggestion from './Components/AutopaymentSuggestion/AutopaymentSuggestion';
import PinWizard from '../../Shared/PinWizard/PinWizard';
import ListOfSbpBanks from '../../Shared/ListOfSbpBanks/ListOfSbpBanks';
import Timer from '../../Shared/Timer/Timer';
import DeclinedSBPPayment from '~/components/Blocks/Templates/PaymentResult/Components/DeclinedSBPPayment';
/** hook */
import { usePaymentResult } from './PaymentResultHook';

/** Результат платежа
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=727888428
 */
const PaymentResult: FC<PaymentResultProps> = ({
  content,
}: PaymentResultProps): JSX.Element => {
  const router = useRouter();
  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const cardLimit = useMemo(() => content.fields?.cardLimit, []);
  const sbpLimit = useMemo(() => content.fields?.sbpLimit, []);

  const {
    resultHeader,
    sbpBindingCheckInfo,
    contractNumber,
    state,
    bind,
    timeIsUp,
    setTimeIsUp,
    sbpBindingInfo,
    orderId,
    token,
    linkedAccount,
    cardBindingId,
    linkedCardValue,
    setAutoPaymentAmount,
    getAutoPaymentAmount,
    stateFormError,
    setIsAutoCount,
    bindAutoPayment,
    viewType,
    setTimeIsUpFirstBind,
    amount,
    payByCreditCard,
    isLoadingRepeatPayment,
    errorMessage,
    isCard,
  } = usePaymentResult({
    cardLimit,
    sbpLimit,
  });

  if (!router.query) {
    return <></>;
  }

  return (
    <StyledPaymentResult>
      <div className="result__header">
        {isDesktop940 ? <H2>{resultHeader}</H2> : <H3>{resultHeader}</H3>}
        {sbpBindingCheckInfo && (
          <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
            Счёт {sbpBindingCheckInfo.bank} был привязан к договору{' '}
            {contractNumber}
          </Text>
        )}
      </div>
      <div className="result">
        {state === RESULT_STATE.WaitingForPayment && (
          <LoaderWrapper>
            <Loader small={!isDesktop940} />
          </LoaderWrapper>
        )}

        {state === RESULT_STATE.ACCOUNT_LINKED_SUCCESS && (
          <Text lineHeight="24px">
            Вы можете управлять платежами на странице{' '}
            <Link href="/payment">Оплаты услуг Планеты</Link>
          </Text>
        )}
        {state === RESULT_STATE.SbpBindingSuggestion && (
          <SbpBindingSuggestion
            contractNumber={contractNumber}
            bind={bind}
            sbpBinding={sbpBindingInfo}
            timeIsUp={timeIsUp}
            setTimeIsUp={setTimeIsUp}
          />
        )}

        {state === RESULT_STATE.AutopaymentSuggestion && (
          <AutopaymentSuggestion
            contractNumber={orderId ? contractNumber : ''}
            token={token}
            sbpBindingCheckInfo={sbpBindingCheckInfo}
            linkedAccount={linkedAccount}
            cardBindingId={cardBindingId}
            linkedCardValue={linkedCardValue}
            setAmount={setAutoPaymentAmount}
            amount={getAutoPaymentAmount()}
            stateFormError={stateFormError}
            setIsAutoCount={setIsAutoCount}
            bindAutoPayment={bindAutoPayment}
            viewType={viewType}
            minAmountValue={isCard ? cardLimit.minAmount : sbpLimit.minAmount}
            errorMessage={errorMessage}
          />
        )}

        {state === RESULT_STATE.WaitingForBinding && (
          <div className="result__waiting">
            <Loader />
            <div>
              <Timer setTimeIsUp={setTimeIsUpFirstBind} isBig />
              <Text className="result__waiting__text" lineHeight="24px">
                Ожидаем подтверждения привязки счёта
              </Text>
            </div>
          </div>
        )}

        {state === RESULT_STATE.PaymentAndBindingSuccess && (
          <>
            <div className="icon">
              <Icons.OkBigIcon />
            </div>
            {isDesktop940 ? (
              <SuccessHeaderDesktop>
                {amount} ₽ зачислены на&nbsp;лицевой счёт договора{' '}
                {contractNumber}
              </SuccessHeaderDesktop>
            ) : (
              <SuccessHeaderMobile color={defaultTheme.colors.black}>
                {amount} ₽ зачислены на&nbsp;лицевой счёт договора{' '}
                {contractNumber}
              </SuccessHeaderMobile>
            )}
            <SuccessText lineHeight="24px">
              Вы&nbsp;можете управлять платежами на&nbsp;странице{' '}
              <Link href="/payment">Оплаты услуг Планеты</Link>
            </SuccessText>
          </>
        )}

        {state === RESULT_STATE.CardPaymentFailed && (
          <div className="result__failed">
            {isDesktop940 ? (
              <H3 className="result__failed__header">Попробуйте ещё раз</H3>
            ) : (
              <LeadingText
                className="result__failed__header"
                color={defaultTheme.colors.black}
              >
                Попробуйте ещё раз
              </LeadingText>
            )}
            <div className="result__failed__button-block">
              <Button
                onClick={() => payByCreditCard()}
                loading={isLoadingRepeatPayment}
              >
                Отправить повторно
              </Button>
              <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                Вы будете перенаправлены на защищённый сайт банка
              </Text>
            </div>
            {errorMessage && (
              <Text
                className="result__failed__button-block__error"
                lineHeight="24px"
                color={defaultTheme.colors.planeta}
              >
                {errorMessage}
              </Text>
            )}
          </div>
        )}

        {state === RESULT_STATE.SbpBindingFailed && (
          <SbpBindingSuggestion
            contractNumber={contractNumber}
            bind={bind}
            sbpBinding={sbpBindingInfo}
            isError={state === RESULT_STATE.SbpBindingFailed}
            timeIsUp={timeIsUp}
            setTimeIsUp={setTimeIsUp}
          />
        )}

        {[RESULT_STATE.SbpPaymentFailed, RESULT_STATE.SbpBoundFailed].includes(
          state,
        ) && (
          <DeclinedSBPPayment
            condition={
              state as
                | RESULT_STATE.SbpPaymentFailed
                | RESULT_STATE.SbpBoundFailed
            }
          />
        )}
      </div>
      <Portal>
        <PinWizard />
        <ListOfSbpBanks />
      </Portal>
    </StyledPaymentResult>
  );
};

export default observer(PaymentResult);
