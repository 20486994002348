/** libraries */
import { types, flow, Instance } from 'mobx-state-tree';
import { toJS } from 'mobx';
/** api */
import {
  getContacts,
  getContactValueClasses,
  getSubscriptions,
} from '~/api/apiPab2c';
import createApiPathModel from '~/stores/models/createApiPathModel';
/** interfaces */
import { ContactsModel, ContactTypeModel, SubscriptionsModel } from './models';
/** constants */
import {
  CONTACT_TYPES,
  timeLeftToSendCode,
} from '../../../Templates/Pab2c/Settings/constants';
import { PLANETA_EVENT_GROUP } from '../constants';
/** components */
import { DeletingContactStore } from './DeletingContactModel';
import { AddingContactStore } from './AddingContactModel';

const RequestsStateModel = types.model('State', {
  getContactTypes: createApiPathModel(
    'GET /Contacts/Contact/GetContactValueClasses',
  ),
  getAllContacts: createApiPathModel('GET /Contacts/Contact/GetContacts'),
  getAllSubscriptions: createApiPathModel(
    'GET /Contacts/Contact/GetSubscriptions',
  ),
});

const ContactsAndNotificationsModel = types
  .model({
    deletingContactStore: DeletingContactStore,
    addingContactStore: AddingContactStore,
    requestsState: RequestsStateModel,
    isShowContactsAndNotifications: types.boolean,
    /** Справочник типов контактов */
    contactValueClasses: types.array(ContactTypeModel),
    /** Контакты на договоре */
    contactsData: types.array(ContactsModel),
    /** Подписки */
    allSubscriptions: types.array(SubscriptionsModel),
    /** Количество секунд таймера */
    seconds: types.number,
    timerIsOn: types.boolean,
  })
  .views((self) => ({
    get contactTypes() {
      return self.contactValueClasses.reduce((acc, contactValues) => {
        // не используем городской телефон
        if (contactValues.code === CONTACT_TYPES.PHONE_CITY) return [...acc];
        return [
          ...acc,
          {
            ...contactValues,
            label: contactValues.name,
            value: contactValues.id.toString(),
          },
        ];
      }, []);
    },
    get contacts() {
      return toJS(self.contactsData);
    },
    get subscriptions() {
      const subscribed = self.allSubscriptions.filter(
        (sub) => sub.eventGroupId === PLANETA_EVENT_GROUP && sub.isSubscribed,
      );
      return toJS(subscribed);
    },
    get isLoading() {
      return (
        self.requestsState.getContactTypes.isLoading ||
        self.requestsState.getAllContacts.isLoading ||
        self.requestsState.getAllSubscriptions.isLoading
      );
    },
  }))
  .actions((self) => {
    return {
      getContactTypes: flow(function* (onlyPhones?: boolean) {
        self.requestsState.getContactTypes.reset();
        self.requestsState.getContactTypes.setLoading();
        try {
          const res = yield getContactValueClasses();
          if (onlyPhones) {
            self.contactValueClasses = res.filter(
              (value) => value.code === CONTACT_TYPES.PHONE,
            );
          } else {
            self.contactValueClasses = res;
          }
          self.requestsState.getContactTypes.setSuccess();
        } catch (e) {
          console.error(e, 'getContactTypes');
          self.requestsState.getContactTypes.setFail();
        }
      }),
      getAllContacts: flow(function* () {
        self.requestsState.getAllContacts.reset();
        self.requestsState.getAllContacts.setLoading();
        try {
          const res = yield getContacts();
          self.contactsData = res;
          self.requestsState.getAllContacts.setSuccess();
        } catch (e) {
          console.error(e, 'getAllContacts');
          self.requestsState.getAllContacts.setFail();
        }
      }),
      getAllSubscriptions: flow(function* () {
        self.requestsState.getAllSubscriptions.reset();
        self.requestsState.getAllSubscriptions.setLoading();
        try {
          const res = yield getSubscriptions();
          self.allSubscriptions = res;
          self.requestsState.getAllSubscriptions.setSuccess();
        } catch (e) {
          console.error(e, 'getAllSubscriptions');
          self.requestsState.getAllSubscriptions.setFail();
        }
      }),
      setIsShowContactsAndNotifications: (isShow: boolean) => {
        self.isShowContactsAndNotifications = isShow;
      },
      setSeconds: (num: number) => {
        self.seconds = num;
      },
      setTimerIsOn: (isOn: boolean) => {
        self.timerIsOn = isOn;
      },
    };
  })
  .actions((self) => {
    return {
      startTimer: () => {
        self.setSeconds(timeLeftToSendCode);
        if (self.timerIsOn) return;
        const timer = () => {
          if (self.seconds < 1) {
            self.setTimerIsOn(false);
            return;
          }
          setTimeout(() => {
            self.setSeconds(self.seconds - 1);
            timer();
          }, 1000);
        };

        timer();
      },
      /** Проверка на наличие хотя бы одного подтвержденного телефона */
      haveVerifiedPhone: flow(function* () {
        yield Promise.all([self.getContactTypes(true), self.getAllContacts()]);
        const phoneClassId = self.contactValueClasses.find(
          (type) => type.code === CONTACT_TYPES.PHONE,
        )?.id;
        const verifiedPhone = self.contactsData.find(
          (contact) =>
            contact.contactValueClassId === phoneClassId && contact.isVerified,
        );
        return !!verifiedPhone;
      }),
    };
  });

export default ContactsAndNotificationsModel;

export type IContactsAndNotificationsStore = Instance<
  typeof ContactsAndNotificationsModel
>;
