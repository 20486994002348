/* Типы */
import {
  PurchaseTypes,
  CallToActionFeePriceTypes,
  DeviceImages,
  CallToActionDeviceTypes,
} from './CallToAction.types';
/* Константы */
import { ConnectionMethod } from '~/constants/common';

/* Ссылки на изображения устройств */
export const DEVICE_IMAGES: DeviceImages = {
  [CallToActionDeviceTypes.ROUTER]: {
    /* Wi-Fi роутер для оптических продуктов lite */
    [ConnectionMethod.FTTH]: `${process.env.STATIC_SERVER}/static/images/devices/router/SNR-CPE-ME2-SFP lite.png`,
    /* Wi-Fi роутер для медных продуктов */
    [ConnectionMethod.LAN]: `${process.env.STATIC_SERVER}/static/images/device/router.png`,
  },
  /* Телеприставка */
  [CallToActionDeviceTypes.STB]: {
    [ConnectionMethod.FTTH]: `${process.env.STATIC_SERVER}/static/images/device/tv.png`,
    [ConnectionMethod.LAN]: `${process.env.STATIC_SERVER}/static/images/device/tv.png`,
  },
  /* Пульт */
  [CallToActionDeviceTypes.REMOTE_CONTROL]: {
    [ConnectionMethod.FTTH]: `${process.env.STATIC_SERVER}/static/images/device/remotecontrol.png`,
    [ConnectionMethod.LAN]: `${process.env.STATIC_SERVER}/static/images/device/remotecontrol.png`,
  },
};

/* Возможные способы приобретения устройств */
export const DEVICE_PURCHASE_TYPES: string[] = [
  ...Object.values(PurchaseTypes).filter((k) => {
    return typeof k === 'string';
  }),
];

/* Возможное количество оборудования, приобретаемого в собственность */
export const DEVICE_BUY_AMOUNT = ['1 шт.', '2 шт.', '3 шт.'];

/* Варианты табов с отображением стоимости сервиса */
export const TABS_VARIANTS = [
  ...Object.values(CallToActionFeePriceTypes).filter((k) => {
    return typeof k === 'string';
  }),
];

/* Наименование телеприставки */
export const STB_NAME = 'IMAQLIQ G-Box X';

/* Наименование пульта управления */
export const REMOTE_NAME = 'ПДУ';

/** идентификатор элемента блока Call to Action */
export const CALL_TO_ACTION_BLOCK_ID = '#cta';
