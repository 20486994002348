import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** стилизованная карточка продукта для мобильного меню */
export const StyledProductCardMobile = styled.div`
  margin-top: 32px;

  .product-desc {
    margin-bottom: 16px;
  }
`;
