import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, defaultTheme } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop340,
  desktop420,
  desktop940,
} from '~/components/Grid/constants';

export const MainText = styled.span`
  text-align: center;
  white-space: pre-line;
  font-weight: 400;
  z-index: 3;
`;

export const StyledImageWrapper = styled.div`
  position: absolute;
  z-index: 2;
  width: 291px;
  height: 82px;
  top: 0;
  left: -38px;

  @media (min-width: ${desktop340}px) {
    width: 380px;
    height: 98px;
    top: 0px;
    left: -50px;
  }

  @media (min-width: ${desktop420}px) {
    width: 436px;
    height: 121px;
    top: 0;
    left: -45px;
  }

  @media (min-width: ${desktop940}px) {
    width: 982px;
    height: 276px;
    bottom: 207px;
    top: -73px;
    left: -37px;
  }

  @media (min-width: ${desktop1100}px) {
    width: 1156px;
    height: 321px;
    bottom: 173px;
  }

  @media (min-width: ${desktop1280}px) {
    width: 1370px;
    height: 378px;
    bottom: 155px;
    top: -82px;
    left: -48px;
  }
`;

export const StyledFragmentsWrapper = styled.div`
  position: absolute;
  z-index: 1;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  overflow: hidden;

  @media (min-width: ${desktop940}px) {
    top: -62px;
    width: 904px;
    height: 539px;
  }

  @media (min-width: ${desktop1100}px) {
    width: 1084px;
    height: 598px;
    top: -62px;
  }

  @media (min-width: ${desktop1280}px) {
    top: -62px;
    width: 1264px;
  }
`;

export const StyledFrontsWrapper = styled.div`
  position: absolute;
  z-index: 2;
  width: 701px;
  height: 418px;

  @media (min-width: ${desktop940}px) {
    top: -90px;
    left: 39px;
  }

  @media (min-width: ${desktop1100}px) {
    top: -90px;
    left: 113px;
  }

  @media (min-width: ${desktop1280}px) {
    top: -99px;
    left: 265px;
  }
`;

export const StyledButton = styled(Button)`
  z-index: 3;
  margin-bottom: 28px;
`;

export default styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ $isMobile }) => ($isMobile ? '406px' : '100%')};
  align-items: ${({ $isMobile }) => ($isMobile ? 'flex-start' : 'center')};
  justify-content: ${({ $isMobile }) =>
    $isMobile ? 'flex-end' : 'flex-start'};
  width: 100%;
  position: relative;

  ${MainText} {
    ${({ $isMobile }) =>
      $isMobile
        ? css`
            text-align: left;
            margin-bottom: 32px;
          `
        : css`
            margin-bottom: 16px;
            margin-top: 14rem;
          `}
  }
`;
