/** библиотеки */
import { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import * as React from 'react';

import Image from 'next/image';

/** компоненты библиотеки */
import {
  H3,
  defaultTheme,
  Tabs,
  LeadingText,
  TabsStyleTypes,
  Snoska,
  Text,
} from 'cordis-core-ui-planeta';

/** стилевые компоненты */
import { StyledDevice } from './Device.style';

/** типы */
import { DeviceFields, DeviceProps } from './Device.types';

/** утилиты */
import {
  formatNumber,
  pluralizeAll,
  calcMonth,
  isExternal,
} from '~/utils/utils';

/** константы */
import {
  DEVICE_TYPES,
  DEVICE_TITLES,
  CONNECTION_METHODS,
  PRICE_TABS,
  DEVICE_NAMES,
  FORGIVENESS,
  BACKGROUND,
} from './Device.const';
import { getPromoPrice } from '~/components/Blocks/Shared/Shared.utils';
import {
  desktop1280,
  desktop900,
  desktop500,
} from '~/components/Grid/constants';

/**
 * Компонент оборудование
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=398395403
 * @param {DeviceProps} content информация об оборудовании
 */
const Device: React.FC<DeviceProps> = ({ content }: DeviceProps) => {
  const { fields } = content;
  /**
   * Возвращает тип устройства в зависимости от полученного типа и метода подключения
   * Например:
   * "Phone" => "Современные технологии интернет-телефонии"
   * @param {DEVICE_TYPES} deviceType Тип устройства
   * @param {CONNECTION_METHODS} connectionMethod Тип подключения
   * @returns {DEVICE_TYPES} Текст заголовка
   */
  const getDeviceType = (
    deviceType: DEVICE_TYPES,
    connectionMethod: CONNECTION_METHODS,
  ): DEVICE_TYPES => {
    switch (deviceType) {
      case DEVICE_TYPES.Router:
        switch (connectionMethod) {
          case CONNECTION_METHODS.FTTH:
            return DEVICE_TYPES.FTTH;
          default:
            return DEVICE_TYPES.Router;
        }
      default:
        return DEVICE_TYPES[deviceType];
    }
  };

  /** Вычисление ширины экрана */
  const isMinDesktop900 = useMediaQuery({
    query: `(min-width: ${desktop900}px)`,
  });
  const isMinDesktop500 = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });
  const isMinDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  /**
   * Возвращает список вкладок цен
   * Например:
   * "Phone" => ["Покупка"]
   * @param {DEVICE_TYPES} currentDeviceType Тип устройства
   * @param {DeviceFields} deviceData Данные об оборудовании
   * @returns {string[]} Список вкладок
   */
  const getPricesTabs = (
    currentDeviceType: DEVICE_TYPES,
    deviceData: DeviceFields,
  ): string[] => {
    const tabs = {
      [PRICE_TABS.usage]: deviceData.ownershipPriceOn,
      [PRICE_TABS.credit]: deviceData.annuity,
      [PRICE_TABS.purchase]: deviceData.price,
    };
    const filterTabs = Object.keys(tabs).filter((tab) => tabs[tab] !== null);
    switch (currentDeviceType) {
      case DEVICE_TYPES.Phone:
        return filterTabs.filter((item) => item === PRICE_TABS.purchase);
      default:
        return filterTabs;
    }
  };

  /**
   * Возвращает список стоимостей для вкладок цен
   * @param {DEVICE_TYPES} currentDeviceType Тип оборудования
   * @param {DeviceFields} deviceData Данные об оборудовании
   * @returns {number[]} Список стоимостей
   */
  const getPricesForTabs = (
    currentDeviceType: DEVICE_TYPES,
    deviceData: DeviceFields,
  ): number[] => {
    switch (currentDeviceType) {
      case DEVICE_TYPES.Phone:
        return [
          getPromoPrice(deviceData.promoPriceInfos, {
            currentPrice: deviceData.price,
          }).promoPrice,
        ];
      default:
        return [
          deviceData.ownershipPriceOn,
          getPromoPrice(deviceData.promoPriceInfos, {
            currentAnnuity: deviceData.annuity,
          }).promoAnnuity,
          getPromoPrice(deviceData.promoPriceInfos, {
            currentPrice: deviceData.price,
          }).promoPrice,
        ];
    }
  };

  /**
   * Проверяет нужно ли подставить период в текст стоимости оборудования
   * Например:
   * (0, ["Рассрочка", "Покупка"]) => true
   * @param {number} selectIndex Индекс выбранной вкладки
   * @param {string[]} tabNames Список имён вкладок
   * @returns {boolean}
   */
  const isPeriod = (selectIndex: number, tabNames: string[]): boolean =>
    tabNames[selectIndex] === PRICE_TABS.credit ||
    tabNames[selectIndex] === PRICE_TABS.usage;

  const deviceType = getDeviceType(fields.type, fields.connectionMethod);
  const title = DEVICE_TITLES[deviceType];
  const background = BACKGROUND[deviceType] ?? BACKGROUND[DEVICE_TYPES.Router];
  const { imgLink } = fields.defaultImgs.find(
    (item) => item.type === deviceType.toLowerCase(),
  );
  const [priceIndex, setPriceIndex] = useState(0);
  const pricesCategoryNames = getPricesTabs(deviceType, fields);
  const pricesCategoryValues = getPricesForTabs(deviceType, fields).filter(
    (item) => item !== null,
  );
  const deviceName = DEVICE_NAMES[deviceType];
  const creditDuration = calcMonth(fields.leasePeriod);
  const isSmall = content.size === 1;
  const formattedPrice = formatNumber(pricesCategoryValues[priceIndex]);
  const periodVote = FORGIVENESS[fields.ownershipPriceOnPaymentPeriodCode];
  const creditDurationVote = pluralizeAll(creditDuration, [
    'месяц',
    'месяца',
    'месяцев',
  ]);

  const getImageSrc = (src: string): string =>
    isExternal(src) ? src : `${process.env.STATIC_SERVER}${src}`;

  /** ссылка на компонент вкладок для анимации */
  const ref = useRef(null);

  /** анимация вкладок */
  const tabsAnimation = (index: number): void => {
    const tabs: HTMLDivElement = ref.current.children[0];
    const selectedTab = tabs.children[index] as HTMLDivElement;
    if (selectedTab) {
      const selectedOffset = selectedTab.offsetLeft;
      /** значение середины элемента с небольшим отступом */
      const parentOffset = ref.current.offsetWidth / 3 + 20;
      tabs.style.left = `-${
        selectedOffset ? selectedOffset - parentOffset : 0
      }px`;
    }
  };

  /** изменяет индекс выбранной вкладки и запускает анимацию */
  const changeTabIndex = (index: number): void => {
    setPriceIndex(index);
    if (isMinDesktop900 || !isMinDesktop500) tabsAnimation(index);
  };
  /** Проверит нужно ли показать символ '*' */
  const isShowStar =
    fields.promoPriceInfos?.length > 0 &&
    pricesCategoryNames[priceIndex] === PRICE_TABS.purchase;

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return (
    <StyledDevice
      small={isSmall}
      background={getImageSrc(background.desktop)}
      backgroundMobile={getImageSrc(background.mobile)}
    >
      <div className="header">
        {(isSmall && isMinDesktop1280) || (!isSmall && isMinDesktop900) ? (
          <H3 className="title" color={defaultTheme.colors.white}>
            {title}
          </H3>
        ) : (
          <LeadingText className="title" color={defaultTheme.colors.white}>
            {title}
          </LeadingText>
        )}
      </div>
      <div className="device">
        <div className="device__preview">
          <div className="device__preview__imageWrapper">
            <Image
              loader={imageLoader}
              src={getImageSrc(imgLink)}
              alt={fields.type}
              layout="fill"
              objectFit="contain"
              quality={100}
            />
          </div>
        </div>
        <div className="device__text">
          {(isSmall && isMinDesktop1280) || (!isSmall && isMinDesktop900) ? (
            <LeadingText color={defaultTheme.colors.white}>
              {deviceName} {fields.name}
              <sup>&nbsp;4</sup>
            </LeadingText>
          ) : (
            <Text color={defaultTheme.colors.white}>
              {deviceName} {fields.name}
              <sup>&nbsp;4</sup>
            </Text>
          )}
        </div>
      </div>
      <div className="rate">
        <div className="rate__tabs" ref={ref}>
          <Tabs
            value={pricesCategoryNames}
            onChange={changeTabIndex}
            activeTabIndex={priceIndex}
            styleType={TabsStyleTypes.SECONDARY}
          />
        </div>
        {isShowStar ? (
          <div className="rate__star">
            <H3 className="value" color={defaultTheme.colors.black}>
              {formattedPrice} ₽
            </H3>
            <span className="star">&nbsp;*</span>
          </div>
        ) : (
          <H3 className="value" color={defaultTheme.colors.black}>
            {formattedPrice} ₽{' '}
            {isPeriod(priceIndex, pricesCategoryNames) && periodVote}
          </H3>
        )}
        <div className="rate__wrapper">
          {pricesCategoryNames[priceIndex] === PRICE_TABS.credit && (
            <Snoska className="rate__description">
              на {creditDurationVote},{' '}
              {
                getPromoPrice(fields.promoPriceInfos, {
                  currentDownPayment: fields.downPayment,
                }).promoDownPayment
              }
              &nbsp;₽ первоначальный взнос
            </Snoska>
          )}
        </div>
      </div>
    </StyledDevice>
  );
};

export default React.memo(Device);
