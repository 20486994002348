/** библиотеки */
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  defaultTheme,
  H3,
  Icon,
  Icons,
  Tabs,
  TabsStyleTypes,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import Image from 'next/image';

/** компоненты */
import { StyledSummaryInfo, StyledTabs } from '../Summary.style';

/** константы и типы */
import { IMAGES } from '../Images';
import {
  CHANNELS_CATEGORY,
  DeviceCode,
  DeviceExtend,
  DEVICES_NAME,
  DevicesInfo,
} from '../Summary.types';
import { pluralizeAll } from '~/utils/utils';
import { ConnectionMethod, SERIES_CODE } from '~/constants/common';
import { getPromoPrice } from '~/components/Blocks/Shared/Shared.utils';
import { SummaryInfoProps } from './SummaryInfo.types';

/**
 * компонент Информация о тарифе
 * @param {ConnectionMethod} connectionMethod
 * @param {string} speedBaseText
 * @param {DevicesInfo} devicesInfo
 * @param {ChannelsInfo} channelsInfo
 * @param {boolean} isTransformer
 * @param {number} freeVoiceMinutes
 * @param {number} phonesCount
 * @param {boolean} isBusiness
 * @param {ActionFields} action
 */
const SummaryInfo: React.FC<SummaryInfoProps> = ({
  connectionMethod,
  speedBaseText,
  devicesInfo,
  channelsInfo,
  isTransformer,
  freeVoiceMinutes,
  phonesCount,
  isBusiness,
  action,
  seriesCode,
}: SummaryInfoProps) => {
  /** Скидка на устройство
   * @param {device}
   * @returns Скидка в числовом выражении
   */
  const getDiscount = (device: DeviceExtend) =>
    Number(
      (
        getPromoPrice(device.promoPriceInfos, { currentPrice: device.price })
          .promoPrice / device.price
      ).toFixed(2),
    );

  const internetType: string =
    connectionMethod === ConnectionMethod.FTTH
      ? 'Оптический Гигабит'
      : 'Интернет';

  /**
   * Вернёт актуальный список устройств
   * @param devicesInfoList список устройств
   */
  const getDevices = (devicesInfoList: DevicesInfo) => {
    let devicesItems = devicesInfoList.devices;
    if (!channelsInfo?.channelCount) {
      devicesItems = devicesInfo.devices.filter(
        (device) => device.code !== DeviceCode.STB,
      );
    }
    return devicesItems.map((device) => {
      if (
        device.code === DeviceCode.ROUTER &&
        connectionMethod === ConnectionMethod.FTTH
      ) {
        return {
          ...device,
          code: 'opticalRouter',
          name: 'Оптический Wi-Fi-роутер',
        };
      }
      return {
        ...device,
        name: DEVICES_NAME[device.code],
      };
    });
  };

  /**
   * фильтрует список устройств, если нет телеканалов, заменяет тексты для устройств
   * и вычисляет оптический ли роутер
   */
  const devices = React.useMemo(() => {
    if (!devicesInfo) return [];
    return getDevices(devicesInfo);
  }, [devicesInfo]);
  // Вкладка табов телеканалов
  const [channelIndex, setChannelIndex] = useState<number>(0);

  // Сбрасываем вкладку табов телеканалов при изменении вкладки рубрик
  useEffect(() => {
    setChannelIndex(0);
  }, [channelsInfo]);

  const summaryChannelInfo = { ...channelsInfo };

  // Скроем раздел 4K, если в продукте их нет
  if (channelsInfo?.uhdChannelCount === 0) {
    delete summaryChannelInfo.uhdChannelCount;
  }
  // Если на продукте отсутствует телевидение, уберём приставку из списка оборудования
  if (!channelsInfo?.channelCount) {
    delete summaryChannelInfo.hdChannelCount;
    summaryChannelInfo.channelCount = 0;
  }

  const channelsCategoryValue: number[] = summaryChannelInfo
    ? Object.values(summaryChannelInfo)
    : [];
  const channelsCategoryNames: string[] = summaryChannelInfo
    ? Object.keys(summaryChannelInfo).map((name) => CHANNELS_CATEGORY[name])
    : [];

  /** Закрытый договор ЧК */
  const isClosedCHK = seriesCode === SERIES_CODE.CLOSE_CHK;

  /** вычисляем склонение для количества каналов */
  const channelCount = React.useMemo(() => {
    if (isClosedCHK) return '0 телеканалов';
    return pluralizeAll(channelsCategoryValue[channelIndex], [
      'телеканал',
      'телеканала',
      'телеканалов',
    ]);
  }, [channelsCategoryValue, channelIndex]);

  /** возвращает true если все устройства бесплатные или имеют одинаковую скидку */
  const isCommonTag =
    devices.reduce(
      (acc, device) =>
        acc &&
        (device.ownershipPriceOn === 0 ||
          getPromoPrice(device.promoPriceInfos, { currentPrice: device.price })
            .promoPrice === 0),
      true,
    ) ||
    (devices.every(
      (device) => getDiscount(device) === getDiscount(devices[0]),
    ) &&
      getDiscount(devices[0]) > 0 &&
      getDiscount(devices[0]) < 1);

  /** Возвращает текст скидки.
   * @param {device}
   * @returns Текст скидки.
   */
  const getDiscountTagText = (device: DeviceExtend): string | null => {
    if (device.ownershipPriceOn === 0 || getDiscount(device) === 0)
      return 'БЕСПЛАТНО';
    if (getDiscount(device) > 0 && getDiscount(device) < 1)
      return `-${100 - getDiscount(device) * 100}%`;
    return null;
  };

  /** табы для трансформера */
  const transformerTabs: React.ReactNode = (
    <StyledTabs className="internet">
      <span className="internet__name-wrapper">
        <Icon className="icon" icon={<Icons.ToggleIcon />} disabled />
        <Text className="internet__name" color={defaultTheme.colors.shadow}>
          Твой набор каналов
        </Text>
      </span>
      <Text
        className="value value__margin"
        fontWeightBold
        style={{ marginTop: '18px' }}
      >
        Любые из&nbsp;{channelsCategoryValue[channelIndex]}&nbsp;каналов
      </Text>
    </StyledTabs>
  );
  /** обычные табы каналов табы */
  const channelTabs: React.ReactNode = (
    <StyledTabs>
      <Tabs
        value={!isClosedCHK ? channelsCategoryNames : ['Всего']}
        onChange={setChannelIndex}
        activeTabIndex={channelIndex}
        styleType={TabsStyleTypes.SECONDARY}
      />
      <H3 className="value value__margin" color={defaultTheme.colors.black}>
        {channelCount}
      </H3>
    </StyledTabs>
  );

  const secondActionImage = (link): JSX.Element => {
    return (
      <div className="action-secondIcon">
        <Image
          loader={imageLoader}
          src={`${process.env.STATIC_SERVER}/${link}`}
          width={126}
          height={70}
          quality={100}
          layout="fixed"
          objectFit="cover"
        />
      </div>
    );
  };

  /** Загрузчик изображения */
  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  const showActionBlock = action?.icon || action?.header || action?.text;

  return (
    <StyledSummaryInfo>
      <div className="info-statistics">
        <StyledTabs className="internet">
          <Text className="internet__name" color={defaultTheme.colors.shadow}>
            {internetType}
          </Text>
          <H3 className="value value__margin">
            {!isClosedCHK ? speedBaseText : '0 Мбит/c'}
          </H3>
        </StyledTabs>
        {/** использует нужные табы в зависимости от типа тарифа */}
        {isTransformer && transformerTabs}
        {!isTransformer &&
          (summaryChannelInfo.channelCount > 0 || isClosedCHK) &&
          channelTabs}
      </div>
      {showActionBlock ? (
        <div className="action-block">
          {action.icon && (
            <Image
              loader={imageLoader}
              src={`${process.env.STATIC_SERVER}/${action.icon}`}
              alt={action.header ?? 'Акция'}
              width={action?.iconSize?.width ?? 40}
              height={action?.iconSize?.height ?? 44}
              quality={100}
            />
          )}
          <div className="action-info">
            {action.header && (
              <Text
                className="action-info__header"
                lineHeight="24px"
                fontWeightBold
              >
                {action.header}
              </Text>
            )}
            {action.text && <Text>{action.text}</Text>}
          </div>
          {action?.secondIcon && secondActionImage(action.secondIcon)}
        </div>
      ) : (
        <>
          {phonesCount && freeVoiceMinutes ? (
            <div className="info-devices">
              <StyledTabs className="phone">
                <Text
                  className="internet__name"
                  color={defaultTheme.colors.shadow}
                >
                  Телефон
                </Text>
                <H3 className="value">{freeVoiceMinutes} минут</H3>
              </StyledTabs>
              <div className="info-devices__icon">
                {/* todo: в иконки ui */}
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="20" cy="20" r="20" fill="#C1C1C1" />
                  <path
                    d="M23.8409 22.2289C23.3075 21.7023 22.6416 21.7023 22.1116 22.2289C21.7073 22.6298 21.3031 23.0307 20.9056 23.4384C20.7968 23.5505 20.7051 23.5742 20.5726 23.4995C20.311 23.3568 20.0324 23.2413 19.781 23.085C18.6089 22.3478 17.6271 21.3999 16.7573 20.3331C16.3259 19.8031 15.942 19.2358 15.6736 18.5971C15.6192 18.468 15.6294 18.383 15.7347 18.2777C16.139 17.887 16.5331 17.4861 16.9306 17.0852C17.4844 16.5281 17.4844 15.8758 16.9272 15.3152C16.6113 14.9958 16.2953 14.6833 15.9793 14.3639C15.6532 14.0378 15.3304 13.7082 15.0009 13.3855C14.4675 12.8657 13.8016 12.8657 13.2716 13.3889C12.8639 13.7898 12.4732 14.2008 12.0587 14.5949C11.6748 14.9585 11.4812 15.4035 11.4404 15.9233C11.3759 16.7693 11.5831 17.5677 11.8753 18.3457C12.4732 19.956 13.3837 21.3863 14.4879 22.6977C15.9793 24.4712 17.7596 25.8743 19.8422 26.8867C20.7799 27.342 21.7515 27.6919 22.8081 27.7496C23.5351 27.7904 24.1671 27.607 24.6733 27.0396C25.0198 26.6523 25.4105 26.299 25.7774 25.9286C26.321 25.3783 26.3244 24.7124 25.7842 24.1688C25.1387 23.5199 24.4898 22.8744 23.8409 22.2289Z"
                    fill="white"
                  />
                  <path
                    d="M23.1921 19.5212L24.4457 19.3071C24.2486 18.1554 23.7051 17.1124 22.8795 16.2834C22.0064 15.4103 20.9022 14.8599 19.6859 14.6901L19.5093 15.9505C20.4504 16.083 21.3065 16.5077 21.9826 17.1838C22.6213 17.8225 23.0392 18.631 23.1921 19.5212Z"
                    fill="white"
                  />
                  <path
                    d="M25.1523 14.0717C23.705 12.6244 21.8738 11.7105 19.8523 11.4286L19.6757 12.689C21.4219 12.9336 23.0051 13.7252 24.2553 14.972C25.441 16.1577 26.219 17.656 26.501 19.3037L27.7547 19.0897C27.4251 17.1803 26.5248 15.4477 25.1523 14.0717Z"
                    fill="white"
                  />
                </svg>
              </div>
              <Text>
                {phonesCount > 1 ? (
                  <span>
                    {phonesCount} городских
                    <br />
                    номера
                  </span>
                ) : (
                  <span>
                    Городской
                    <br />
                    номер
                  </span>
                )}
              </Text>
              {action?.secondIcon && secondActionImage(action.secondIcon)}
            </div>
          ) : (
            !isBusiness && (
              <div className="info-devices">
                {devices.map((device) => (
                  <div className="image" key={device.name}>
                    <Image
                      loader={imageLoader}
                      src={IMAGES[device.code]}
                      alt={device.name}
                      key={device.code}
                      layout="fixed"
                      objectFit="contain"
                      width={75}
                      height={50}
                      quality={100}
                    />
                  </div>
                ))}
                {devices?.length > 0 && (
                  <div className="info-devices__text">
                    {isCommonTag && (
                      <span className="row">
                        <Tag
                          color={defaultTheme.colors.planeta}
                          colorTag={defaultTheme.colors.pink}
                        >
                          {getDiscountTagText(devices[0])}
                        </Tag>
                      </span>
                    )}
                    {devices.map((device) => (
                      <span className="row" key={device.code}>
                        <Text className="info-devices__name">
                          {device.name}
                        </Text>
                        {!isCommonTag && getDiscountTagText(device) && (
                          <Tag
                            color={defaultTheme.colors.planeta}
                            colorTag={defaultTheme.colors.pink}
                          >
                            {getDiscountTagText(device)}
                          </Tag>
                        )}
                      </span>
                    ))}
                  </div>
                )}
                {action?.secondIcon && secondActionImage(action.secondIcon)}
              </div>
            )
          )}
        </>
      )}
    </StyledSummaryInfo>
  );
};

export default React.memo(SummaryInfo);
