/** библиотеки */
import React, { FC, useState, useEffect, useMemo } from 'react';
import {
  H2,
  H3,
  TagButton,
  Button,
  Icon,
  Icons,
  SidePage,
  Text,
  Snoska,
} from 'cordis-core-ui-planeta';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';

/** типы */
import {
  DevicesContentProps,
  DevicesFields,
  FieldsProps,
  TagProps,
} from './interfaces';
import { ResultProps } from '../Pab2c/Help/interfaces';

/** стилевые компоненты */
import {
  StyledDevices,
  StyledDevicesHeader,
  StyledDevicesTags,
  StyledDevicesList,
  StyledResult,
  StyledLegalBlockDevice,
  StyledSidePageFooter,
  StyledSP,
} from './style';

/** компоненты */
import DevicesCard from '~/components/Blocks/Shared/DevicesCard/DevicesCard';
import DeviceDetailedCard from '~/components/Blocks/Templates/Devices/DeviceDetailedCard';
import Portal from '~/components/Portal/Portal';

/* Утилиты */
import useWindowSize from '~/utils/useWindowSize';
import { isExternal } from '~/utils/utils';
import { parseHtml } from '../Legal/parser';

/** константы */
import { desktop940 } from '~/components/Grid/constants';
import {
  DEFAULT_TAG,
  DEVICES_TABS,
  ARCHIVE_TAG,
  DEVICE_PLURAL,
  RESULT_TEXT,
} from './constants';
import { DEFAULT_RESULT } from '../Pab2c/Help/constants';
/** api */
import { getDevices } from '~/api/api';
/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Блок "Агрегатор оборудования"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=525730789
 * @param content
 */
const Devices: FC<DevicesContentProps> = ({ content }: DevicesContentProps) => {
  const { fields } = content;
  const { features, deviceFilters } = fields;
  const {
    authStore: { isAuth },
  } = useRootStore();
  // Ширина сайта
  const [width] = useWindowSize();
  // Флаг отображения детального сайд Пейджа
  const [isDetailedDeviceShow, setDetailedDeviceShow] = useState<boolean>(
    false,
  );
  // Информация о конкретном устройстве
  const [
    detailedDeviceData,
    setDetailedDeviceData,
  ] = useState<DevicesFields | null>(null);
  // Теги
  const [tags, setTags] = useState<TagProps[] | []>([]);
  // Вид блока с тегами в мобильной версии
  const [isThemesOpen, setIsThemesOpen] = useState<boolean>(false);
  // Активный тег
  const [activeTag, setActiveTag] = useState<TagProps>(DEFAULT_TAG);
  // Выбираемый, но не подтверждённый тег в мобильной версии
  const [candidateForActiveTag, setCandidateForActiveTag] = useState<TagProps>(
    DEFAULT_TAG,
  );
  // Отфильтрованное оборудование
  const [filteredDevices, setFilteredDevices] = useState<DevicesFields[]>([]);
  // Объект router
  const router = useRouter();
  // Урл страницы
  const url = new URL(document.location.href);
  /** Устройства под авторизацией */
  const [authDevices, setAuthDevices] = useState<DevicesFields[]>([]);

  const devices = useMemo<DevicesFields[]>(() => {
    let newDevices = [];

    // Заполнение массива оборудования
    Object.keys(fields as FieldsProps).forEach((key) => {
      if (!(+key >= 0)) return;
      newDevices.push(fields[key]);

      // Добавление в объект оборудования его дополнительных свойств
      Object.keys(features).forEach((feature) => {
        const id = Number(feature);
        if (id === fields[key].id) {
          newDevices[key] = { ...newDevices[key], ...features[feature] };
        }
      });
    });

    /** Устройства под авторизацией */
    if (authDevices.length) {
      newDevices = newDevices.map((item) => {
        const authDevice = authDevices.find((device) => device.id === item.id);
        return authDevice
          ? {
              ...item,
              ...authDevice,
            }
          : { ...item, isNotAvailable: true };
      });
    }

    /** Если открыт сп, сменить инфу на новую */
    if (detailedDeviceData) {
      const device = newDevices.find(
        (item) => item.id === detailedDeviceData.id,
      );
      if (device) {
        setDetailedDeviceData(device);
      } else {
        setDetailedDeviceShow(false);
      }
    }

    return [
      ...newDevices.filter((item) => item.sort).sort((a, b) => a.sort - b.sort),
      ...newDevices.filter((item) => !item.sort),
    ];
  }, [fields, authDevices]);

  const getImageInfo = (deviceImageUrl: string) => {
    const imgUrl = isExternal(deviceImageUrl)
      ? deviceImageUrl
      : `${process.env.STATIC_SERVER}${deviceImageUrl}`;

    return fields.imageSizes.find((item) => item.url === imgUrl);
  };

  /** Получение устройств под авторизацией */
  const getAllDevices = async () => {
    try {
      const res = await getDevices();
      setAuthDevices(res);
    } catch (e) {
      console.error('getAllDevices', e);
    }
  };
  useEffect(() => {
    if (isAuth) {
      getAllDevices();
    } else {
      setAuthDevices([]);
    }
  }, [isAuth]);

  // Генерирует массив тегов
  const generateTags = () => {
    const newTags = [DEFAULT_TAG];
    Object.keys(deviceFilters).forEach((key) => {
      newTags.push({
        value: key.replace(/"/g, ''),
        tag: deviceFilters[key].tag,
        types: [...deviceFilters[key].types],
      });
    });
    setTags(newTags);
  };

  // Возвращает блок с заголовком
  const renderTitleBox = () => {
    return (
      <>
        {width >= desktop940 ? (
          <H2>Оборудование</H2>
        ) : (
          <>
            <div className="devices__title-wrap">
              <H3>Оборудование</H3>
              <Icon
                className="devices__title-icon"
                icon={isThemesOpen ? <Icons.CloseIcon /> : <Icons.ListIcon />}
                onClick={() => setIsThemesOpen(!isThemesOpen)}
                highlight
              />
            </div>
          </>
        )}
      </>
    );
  };

  // Возвращает блок с тегами
  const renderTag = (item: TagProps) => {
    const { value, tag } = item;

    return (
      <div className="devices__tag">
        <TagButton
          className="devices__tag-button"
          onChange={() => handleTagClick(item)}
          checked={
            width < desktop940
              ? tag === candidateForActiveTag.tag
              : tag === activeTag.tag
          }
        >
          {value}
        </TagButton>
      </div>
    );
  };

  // Возвращает блок с заголовком и тегами
  const renderHeader = () => {
    return (
      <StyledDevicesHeader isVisible={isThemesOpen}>
        <div className="devices__header">
          {renderTitleBox()}
          {tags.length > 0 && (width >= desktop940 || isThemesOpen) && (
            <>
              <StyledDevicesTags>
                {tags.map((item: TagProps) => (
                  <React.Fragment key={item.tag}>
                    {renderTag(item)}
                  </React.Fragment>
                ))}
              </StyledDevicesTags>
              {width < desktop940 && (
                <div className="button-wrapper">
                  <Button onClick={handleTagButtonClick}>Показать</Button>
                </div>
              )}
            </>
          )}
        </div>
      </StyledDevicesHeader>
    );
  };

  /**
   * Меняет Url
   * @param item текущий тег
   */
  const changeURL = (item: TagProps): void => {
    const valueHash = item.tag === DEFAULT_TAG.tag ? '' : `#${item.tag}`;
    router.push(`/devices${valueHash}`, `/devices${valueHash}`, {
      shallow: true,
    });
  };

  /**
   * Меняет значение активного тега
   * @param item текущий тег
   */
  const changeActiveTag = (item: TagProps): void => {
    if (item.tag === activeTag.tag) return;
    setActiveTag(item);
    changeURL(item);
  };

  /**
   * Обрабатывает клик по тегу в блоке тегов
   * @param item текущий тег
   */
  const handleTagClick = (item: TagProps) => {
    if (width < desktop940) {
      setCandidateForActiveTag(item);
      return;
    }
    changeActiveTag(item);
  };

  // Обрабатывает клик по кнопке "Показать"
  const handleTagButtonClick = () => {
    setIsThemesOpen(false);
    changeActiveTag(candidateForActiveTag);
  };

  const handleShowDetailedInfoClick = (e, item) => {
    const isClickTab = DEVICES_TABS.some((tab) => {
      return tab === e.target.innerHTML;
    });

    if (!isClickTab) {
      setDetailedDeviceData(item);
      setDetailedDeviceShow(!isDetailedDeviceShow);
    }
  };

  useEffect(() => {
    generateTags();
  }, [fields]);

  useEffect(() => {
    if (width < desktop940 && isThemesOpen) setIsThemesOpen(false);
  }, [width]);

  // Изменение выбираемого, но не подтверждённого тега в мобильной версии
  useEffect(() => {
    setCandidateForActiveTag(activeTag);
  }, [activeTag]);

  // Фильтрация оборудования
  useEffect(() => {
    if (activeTag.tag === DEFAULT_TAG.tag) {
      const newFilteredDevices = devices.filter(
        (item) => !(item as DevicesFields).archive,
      );
      setFilteredDevices(newFilteredDevices);
      return;
    }

    if (activeTag.tag === ARCHIVE_TAG.tag) {
      const newFilteredDevices = devices.filter(
        (item) => (item as DevicesFields).archive,
      );
      setFilteredDevices(newFilteredDevices);
      return;
    }

    const newFilteredDevices = devices.filter(
      (item: DevicesFields) =>
        activeTag.types.includes(item.type) && !item.archive,
    );
    setFilteredDevices(newFilteredDevices);
  }, [activeTag, devices]);

  // Изменение активного тега при переходе на страницу тега
  useEffect(() => {
    if (!tags.length) return;
    const urlTag = tags.find((item: TagProps) => `#${item.tag}` === url.hash);
    if (!urlTag) return;
    setActiveTag(urlTag);
  }, [tags, url.hash]);

  /** Результат заказа оборудования */
  const [result, setResult] = useState<ResultProps>(DEFAULT_RESULT);
  /** Телефон для связи */
  const [phone, setPhone] = useState<string>('');

  const deviceCards = useMemo(
    () =>
      filteredDevices.map((item) => (
        <DevicesCard
          key={item.id}
          customTag={item.tag}
          item={item}
          onDeviceCardMouseUp={(e) => handleShowDetailedInfoClick(e, item)}
          imageInfo={getImageInfo(item.images)}
        />
      )),
    [filteredDevices],
  );

  return (
    <StyledDevices>
      {renderHeader()}
      {filteredDevices.length > 0 && (
        <StyledDevicesList>{deviceCards}</StyledDevicesList>
      )}

      <Portal>
        <StyledSP>
          <SidePage
            show={isDetailedDeviceShow}
            headerText={
              detailedDeviceData && !result.isResult
                ? `${detailedDeviceData.typeName} ${detailedDeviceData.vendorName} ${detailedDeviceData.name}`
                : ''
            }
            onCloseClick={() => {
              setResult(DEFAULT_RESULT);
              setDetailedDeviceShow(false);
            }}
            footerContainer={
              !result.isResult && (
                <StyledSidePageFooter>
                  <StyledLegalBlockDevice>
                    <Snoska>{parseHtml(detailedDeviceData?.footer)}</Snoska>
                  </StyledLegalBlockDevice>
                </StyledSidePageFooter>
              )
            }
            removeScrollBar
          >
            {result.isResult ? (
              <StyledResult>
                <Icons.OkBigIcon />
                <H2>{RESULT_TEXT.SUCCESS}</H2>
                <Text lineHeight="24px">
                  Ваша заявка на заказ{' '}
                  <Text lineHeight="24px" fontWeightBold>
                    {DEVICE_PLURAL[detailedDeviceData.type.toLowerCase()] ?? ''}{' '}
                    {detailedDeviceData.vendorName} {detailedDeviceData.name}
                  </Text>{' '}
                  принята.
                </Text>
                <br />
                <br />
                <Text lineHeight="24px">
                  Мы свяжемся с вами, чтобы уточнить детали заказа по телефону{' '}
                  <Text lineHeight="24px" fontWeightBold>
                    {phone}
                  </Text>
                  .
                </Text>
              </StyledResult>
            ) : (
              <DeviceDetailedCard
                item={detailedDeviceData}
                setResult={setResult}
                setPhone={setPhone}
              />
            )}
          </SidePage>
        </StyledSP>
      </Portal>
    </StyledDevices>
  );
};

export default observer(Devices);
