/** libraries */
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  defaultTheme,
  LeadingText,
  Loader,
  Text,
} from 'cordis-core-ui-planeta';
import { nanoid } from 'nanoid';
import { differenceInDays, isLastDayOfMonth, parseISO } from 'date-fns';
/** styles */
import { StyledTransform } from './styles';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { DAYS, TOTAL } from '../constants';
/** utils */
import { formatNumber, pluralizeAll } from '~/utils/utils';
import { dateString } from '../utils';
/** components */
import MobileTable from '../Components/MobileTable/MobileTable';
import Table from '../Components/Table/Table';
import Td from '../Components/Table/Td';
import Tr from '../Components/Table/Tr';
import Th from '../Components/Table/Th';
/** api */
import { getTransformerEstimateDetails } from '~/api/apiPab2c';
/** interfaces */
import { DetailsData } from './interaces';
import { TransformerHistory } from '../FinancialCalculations/interfaces';

interface TransformLimitExceededProps {
  TRLimitData: TransformerHistory;
  startOfFinancialSettlementPeriod: string;
  endOfFinancialSettlementPeriod: string;
}

const TransformLimitExceeded: FC<TransformLimitExceededProps> = ({
  TRLimitData,
  startOfFinancialSettlementPeriod,
  endOfFinancialSettlementPeriod,
}: TransformLimitExceededProps): JSX.Element => {
  /** Загрузка */
  const [isLoading, setIsLoading] = useState<boolean>(true);
  /** Детализация превышения трансформера по датам */
  const [dataDetails, setDataDetails] = useState<DetailsData[]>([]);
  /** Итого за период */
  const [totalAmount, setTotalAmount] = useState<number>(0);

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const getDetails = async () => {
    try {
      const res = await getTransformerEstimateDetails(
        TRLimitData.tariffId,
        startOfFinancialSettlementPeriod,
        endOfFinancialSettlementPeriod,
      );
      const details = res.reduce((acc, day, index) => {
        if (!acc.length)
          acc.push({
            startDate: day.date,
            endDate: null,
            items: day.items,
          });

        const itemsCurPeriod = acc[acc.length - 1].items
          .map((item) => item.name)
          .sort();
        const curItems = day.items
          .map((item) => {
            return item.name;
          })
          .sort();
        const hasSameNames = itemsCurPeriod.every(
          (element, i) => element === curItems[i],
        );
        const isLast = index === res.length - 1;

        if (itemsCurPeriod.length !== curItems.length || !hasSameNames) {
          acc[acc.length - 1].endDate = day.date;
          acc.push({
            startDate: day.date,
            endDate: null,
            items: day.items,
          });
        }

        if (isLast) {
          acc[acc.length - 1].endDate = day.date;
          return acc;
        }

        return acc;
      }, []);

      const total = details.reduce((acc, data, index) => {
        const periodTotal = data.items.find((i) => i.code === TOTAL);
        if (periodTotal.amountPrice !== 0) {
          let days =
            differenceInDays(parseISO(data.endDate), parseISO(data.startDate)) +
            1;
          const isLastInMonth = isLastDayOfMonth(parseISO(data.endDate));
          const isLastInArray = index === details.length - 1;
          if (days === 2 || (!isLastInMonth && !isLastInArray)) days--;
          const sum = periodTotal.amountPrice * days;
          return acc + sum;
        }
        return acc;
      }, 0);
      setTotalAmount(total);
      setDataDetails(details);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getDetails();
  }, []);

  /** Генерация ключей для списка */
  const ids = useMemo(() => dataDetails.map(() => nanoid(5)), [dataDetails]);

  if (isLoading) {
    return (
      <StyledTransform>
        <div className="loader">
          <Loader />
        </div>
      </StyledTransform>
    );
  }

  return (
    <StyledTransform>
      {isDesktop940 ? (
        <Table>
          <thead>
            <Tr>
              <Th>Услуга</Th>
              <Th width={150} textAlign="right">
                Количество дней
              </Th>
              <Th width={140} textAlign="right">
                Цена, ₽
              </Th>
              <Th width={140} textAlign="right">
                Сумма, ₽
              </Th>
            </Tr>
          </thead>
          <tbody>
            {dataDetails.map((data, index) => {
              const total = data.items.find((item) => item.code === TOTAL);
              let days =
                differenceInDays(
                  parseISO(data.endDate),
                  parseISO(data.startDate),
                ) + 1;
              const isLastInMonth = isLastDayOfMonth(parseISO(data.endDate));
              const isLastInArray = index === dataDetails.length - 1;
              if (days === 2 || (!isLastInMonth && !isLastInArray)) days--;
              const amount = total.amountPrice * days;
              return (
                <Fragment key={ids[index]}>
                  <Tr>
                    <Td>
                      <Text
                        className="transform__header"
                        lineHeight="24px"
                        fontWeightBold
                      >
                        {dateString(data.startDate, 'dd MMM yyyy')} -{' '}
                        {dateString(data.endDate, 'dd MMM yyyy')}
                      </Text>
                    </Td>
                    <Td>&nbsp;</Td>
                    <Td>&nbsp;</Td>
                    <Td>&nbsp;</Td>
                  </Tr>
                  {data.items
                    .filter((item) => item.code !== TOTAL)
                    .map((item) => {
                      return (
                        <Tr key={item.code}>
                          <Td>
                            <Text
                              lineHeight="24px"
                              color={defaultTheme.colors.shadow}
                            >
                              {item.name}
                            </Text>
                          </Td>
                          <Td>&nbsp;</Td>
                          <Td textAlign="right">
                            <Text
                              lineHeight="24px"
                              color={defaultTheme.colors.shadow}
                            >
                              {formatNumber(item.amountPrice, false, ',', true)}
                            </Text>
                          </Td>
                          <Td textAlign="right">&nbsp;</Td>
                        </Tr>
                      );
                    })}
                  <Tr>
                    <Td>
                      <Text lineHeight="24px">
                        Превышение лимита{' '}
                        <Text lineHeight="24px" fontWeightBold>
                          ({TRLimitData.transformationLimit} ₽)
                        </Text>{' '}
                        трансформации базового пакета
                      </Text>
                    </Td>
                    <Td textAlign="right" verticalAlign="top">
                      {days}
                    </Td>
                    <Td textAlign="right" verticalAlign="top">
                      {formatNumber(total.amountPrice, false, ',', true)}
                    </Td>
                    <Td textAlign="right" verticalAlign="top">
                      <Text lineHeight="24px" fontWeightBold>
                        {total.amountPrice > 0
                          ? formatNumber(amount, false, ',', true)
                          : '0,00'}
                      </Text>
                    </Td>
                  </Tr>
                </Fragment>
              );
            })}
            <Tr backgroundColor={defaultTheme.colors.light}>
              <Td verticalAlign="top">
                <Text lineHeight="24px" fontWeightBold>
                  Итого за период
                </Text>
              </Td>
              <Td>&nbsp;</Td>
              <Td>&nbsp;</Td>
              <Td textAlign="right">
                <Text lineHeight="24px" fontWeightBold>
                  {formatNumber(totalAmount, false, ',', true)}
                </Text>
              </Td>
            </Tr>
          </tbody>
        </Table>
      ) : (
        <>
          {dataDetails.map((data, index) => {
            const amount = data.items.reduce(
              (acc, item) => acc + item.amountPrice,
              0,
            );
            let days =
              differenceInDays(
                parseISO(data.endDate),
                parseISO(data.startDate),
              ) + 1;
            const isLastInMonth = isLastDayOfMonth(parseISO(data.endDate));
            const isLastInArray = index === dataDetails.length - 1;
            if (days === 2 || (!isLastInMonth && !isLastInArray)) days--;
            return (
              <Fragment key={ids[index]}>
                <MobileTable
                  content={{
                    header: `${dateString(
                      data.startDate,
                      'dd MMM yyyy',
                    )} - ${dateString(data.endDate, 'dd MMM yyyy')}`,
                    lines: data.items.map((line) => {
                      return {
                        amount:
                          line.code === TOTAL && line.amountPrice > 0
                            ? amount
                            : line.amountPrice,
                        info:
                          line.code === TOTAL ? (
                            <Text lineHeight="24px">
                              Превышение лимита{' '}
                              <Text lineHeight="24px" fontWeightBold>
                                ({TRLimitData.transformationLimit} ₽)
                              </Text>{' '}
                              трансформации базового пакета
                            </Text>
                          ) : (
                            line.name
                          ),
                        infoColor:
                          line.code === TOTAL
                            ? defaultTheme.colors.black
                            : defaultTheme.colors.shadow,
                        comment:
                          line.code === TOTAL
                            ? `${line.amountPrice} ₽ x ${pluralizeAll(
                                days,
                                DAYS,
                              )}`
                            : undefined,
                        commentColor: defaultTheme.colors.gray,
                        amountColor:
                          line.code === TOTAL
                            ? defaultTheme.colors.black
                            : defaultTheme.colors.shadow,
                      };
                    }),
                  }}
                />
              </Fragment>
            );
          })}
          <div className="transform__total">
            <Text className="transform__total__header" lineHeight="24px">
              ВСЕГО ЗА МЕСЯЦ
            </Text>
            <br />
            <LeadingText color={defaultTheme.colors.black}>
              {formatNumber(totalAmount, false, ',', true)} ₽
            </LeadingText>
          </div>
        </>
      )}
    </StyledTransform>
  );
};

export default TransformLimitExceeded;
