/** типы */
import { ChatleWizardContextProps } from './ChatleWizard.types';
import { PostResponse } from '~/interfaces/ApiInterface';
/** Библиотеки */
import { useState } from 'react';
/** api */
import { setReservedChatles } from '~/api/apiPab2c';
/** константы */
import { ERROR_DEFAULT } from '~/components/Blocks/Templates/Pab2c/Contract/ChatleWizard/constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';

export const useReserveChatles = (): ChatleWizardContextProps => {
  const {
    pab2cBalanceStore: { getBalance },
  } = useRootStore();
  const [serverError, setServerError] = useState<string>('');
  const [chatleAmount, setChatleAmount] = useState<string>('');
  const [maxChatleAmount, setMaxChatleAmount] = useState<number>(null);
  const [errorChatleAmount, setErrorChatleAmount] = useState<boolean>(false);
  const [isChatleLoading, setIsChatleLoading] = useState<boolean>(false);
  const [isChatleCompleted, setIsChatleCompleted] = useState<boolean>(false);
  const [
    reserveChatleData,
    setReserveChatleData,
  ] = useState<PostResponse | null>(null);
  const [isChatleVisible, setIsChatleVisible] = useState<boolean>(false);

  /** Открывает/закрывает сайд пейдж */
  const toggleChatleVisible = (): void => {
    setIsChatleVisible(!isChatleVisible);
  };

  /** Обработка закрытия виджета в SidePage */
  const onCloseClickSidePageChatle = (): void => {
    setIsChatleVisible(false);
  };

  const hasAmountError = +chatleAmount <= 0 || +chatleAmount > maxChatleAmount;

  const validateChatleAmount = (): void => {
    setErrorChatleAmount(hasAmountError);
  };

  const reserveChatles = async (): Promise<void> => {
    if (errorChatleAmount) setErrorChatleAmount(null);
    if (serverError) setServerError(null);
    setIsChatleLoading(true);
    try {
      const res = await setReservedChatles(+chatleAmount);

      if (res.status && res.status === 200) {
        setReserveChatleData(res);
        setIsChatleCompleted(true);
        getBalance();
      }
    } catch (errorData) {
      console.error(errorData);
      const err = errorData.errorMessage
        ? JSON.parse(errorData.errorMessage)
        : ERROR_DEFAULT;
      setServerError(err);
    }

    setIsChatleLoading(false);
  };

  return {
    errorChatleAmount,
    toggleChatleVisible,
    isChatleVisible,
    onCloseClickSidePageChatle,
    chatleAmount,
    maxChatleAmount,
    serverError,
    setServerError,
    setChatleAmount,
    setErrorChatleAmount,
    isChatleLoading,
    isChatleCompleted,
    reserveChatles,
    reserveChatleData,
    setReserveChatleData,
    validateChatleAmount,
    setMaxChatleAmount,
    setIsChatleVisible,
    setIsChatleCompleted,
  };
};

export default useReserveChatles;
