import styled from '@emotion/styled';
import { desktop450 } from '~/components/Grid/constants';

export const StyledCardAndSbpPaymentSelect = styled.div`
  > div {
    width: 100%;

    .select-wrapper {
      width: 100%;
    }
  }

  .option-content {
    .icon:nth-of-type(3) {
      margin-left: 11px;
    }
  }
  .select-overlay {
    width: 100% !important;
  }

  .option-text {
    max-width: 178px;
    white-space: break-spaces;
  }

  .error {
    margin-top: 16px;
  }

  @media (min-width: ${desktop450}px) {
    > div {
      .select-wrapper {
        width: 330px;
      }
    }
  }
`;
