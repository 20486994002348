/** libraries */
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  TagButton,
  TagButtonIconPosition,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { nanoid } from 'nanoid';
/** styles */
import { StyledIcon, StyledProductSelectorTags } from './ProductTemplate.style';
/* Утилиты */
import { formatTagsByObject } from './ProductTemplate.utils';
/** interfaces */
import {
  MarketingFieldsProps,
  MarketingTagsValues,
} from './ProductTemplate.interfaces';

interface ProductTagsInterface {
  marketingFields: MarketingFieldsProps[];
  isMiniProductSwitcher?: boolean;
  activeProductTag: MarketingFieldsProps;
  activeProductTags: MarketingTagsValues;
  setActiveProductTags: Dispatch<SetStateAction<MarketingTagsValues>>;
  codes: string[];
}

const ProductTags: FC<ProductTagsInterface> = ({
  marketingFields,
  isMiniProductSwitcher,
  activeProductTag,
  activeProductTags,
  setActiveProductTags,
  codes,
}: ProductTagsInterface) => {
  // Выключенное состояние всех маркетинг тегов в виде объекта {'код маркетинг категории' : boolean}
  const allDisabledProductTags: MarketingTagsValues = formatTagsByObject(
    false,
    codes,
  );

  useEffect(() => {
    if (activeProductTag)
      setActiveProductTags({
        ...allDisabledProductTags,
        [activeProductTag?.code]: true,
      });
  }, [activeProductTag, codes]);

  // Обработка события нажатия на checkbox с тегом
  const onCheckboxClick = (
    event: React.FormEvent<HTMLInputElement>,
    code: string,
  ): void => {
    if (!event || checkLastTag(code)) return;
    // Обновляет состояние тега по коду маркетинг категории
    setActiveProductTags({
      ...allDisabledProductTags,
      [code]: true,
    });
    if (isMiniProductSwitcher) changeURL({ tag: code });
  };
  // Объект router
  const router = useRouter();
  // Урл страницы
  const url = new URL(document.location.href);
  /** Меняет Url
   * @param item текущий тег */
  const changeURL = (item): void => {
    const valueHash = `#${item.tag}`;
    router.push(`${url.pathname}${valueHash}`, `${url.pathname}${valueHash}`, {
      shallow: true,
    });
  };

  // Сортирует маркетинг теги по индексу сортировки
  const sortMarketingFields = (
    prev: MarketingFieldsProps,
    next: MarketingFieldsProps,
  ): number => {
    if (prev.sort > next.sort) return -1;
    if (prev.sort < next.sort) return 1;
    return 0;
  };

  // Вычисляет что тег, на который произошёл клик - последний из активных
  const checkLastTag = (code: string): boolean => {
    // Массив тегов
    const tagsArray = Object.entries(activeProductTags);
    // Выключенные теги
    const disabledTags = tagsArray.filter((item) => !item[1]);
    // Остался последний включённый тег и среди них нет нашего
    return (
      tagsArray.length === disabledTags.length + 1 &&
      !disabledTags.find((item) => item[0] === code)
    );
  };
  /* TODO: Checkbox работают как Radio. Продумать решение на других компонентах
    или переименовать Checkbox и добавить возможность указывать тип. */
  return (
    <StyledProductSelectorTags>
      <Text className="product" color={defaultTheme.colors.shadow}>
        Продукты
      </Text>
      {marketingFields
        .sort(sortMarketingFields)
        .map((item: MarketingFieldsProps) => (
          <TagButton
            key={item.code}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              onCheckboxClick(e, item.code)
            }
            checked={activeProductTags[item.code]}
            mobileDisabled
            iconPosition={TagButtonIconPosition.LEFT}
          >
            {item.name}
          </TagButton>
        ))}
    </StyledProductSelectorTags>
  );
};

export default ProductTags;
