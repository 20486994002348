import { createContext, useContext } from 'react';
import vacationModel, { IVacationStore } from './VacationModel';
import { defaultModelState } from '~/stores/models/createApiPathModel';

const store = vacationModel.create({
  requestsState: {
    getVacationInfo: defaultModelState,
    postVacationStart: defaultModelState,
    postVacationStop: defaultModelState,
    postVacationCancel: defaultModelState,
    getVacationAgreement: defaultModelState,
  },
  isChecked: false,
  /** SidePage подключения услуги */
  isVacationConnectionOpen: false,
  /** SidePage отключения услуги */
  isVacationShutdownOpen: false,
  agreement: '',
  vacationInfo: null,
});

const VacationStoreContext = createContext<IVacationStore>(store);

const useVacationStore = () => {
  return useContext(VacationStoreContext);
};

export default useVacationStore;
