/** библиотеки */
import React, { FC, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

/** компоненты библиотеки */
import { Button, ButtonStyleTypes, defaultTheme } from 'cordis-core-ui-planeta';

import { desktop940 } from '~/components/Grid/constants';
import {
  ACTION_MAP,
  OWNERSHIP_CODES,
} from '~/components/Blocks/Templates/Pab2c/Devices/constants';

/** типы */
import { DeviceCardWizardFooterProps } from './types';

/** стили */
import { StyledFooterButtonsContainer } from './style';

/**
 * Блок pab2cDevices. SidePageFooter получения оборудования в собственность
 */
const DeviceCardWizardFooter: FC<DeviceCardWizardFooterProps> = ({
  device,
  openSidePage,
  deviceLeasePurchaseCheck,
  deviceOwnership,
  devicesInLease,
  canLeasePay,
  warrantyState,
}: DeviceCardWizardFooterProps) => {
  /** Вычисление ширины экрана */
  const isDesktop = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const {
    setIsWarrantyPlusWizardShow,
    isWarrantyPlusWizardLoading,
  } = warrantyState;

  /** Вывод текста для кнопки */
  const getAction = (): string => {
    if (deviceOwnership) {
      const sizeKey = isDesktop ? 'desktop' : 'mobile';
      return ACTION_MAP[deviceOwnership][sizeKey];
    }
    return '';
  };

  /** Вывод текста для кнопки покупки в рассрочку */
  const getLeaseAction = (): string => {
    const sizeKey = isDesktop ? 'desktop' : 'mobile';

    return ACTION_MAP.BuyInLeasing[sizeKey];
  };

  const canDeviceBuyLease = useMemo(() => {
    return (
      deviceLeasePurchaseCheck?.notAvailableFundsError ||
      deviceLeasePurchaseCheck?.leaseAnnuity >= 0
    );
  }, [deviceLeasePurchaseCheck]);

  if (
    device?.ownership === OWNERSHIP_CODES.BOUGHT &&
    device?.warrantyPlusInfo?.isOrdered
  )
    return null;

  const isLeasingGroup = [
    OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP,
    OWNERSHIP_CODES.LEASING,
  ].includes(device?.ownership);

  // Доступно подключение гарантии
  const isWarrantyProvided =
    !device?.warrantyPlusInfo?.isOrdered &&
    device?.warrantyPlusInfo?.price !== null;

  // Доступна передача в собственность
  const canBeingOwner = isLeasingGroup && device?.ownershipEndDays <= 0;

  // Рассрочка без подключённой гарантии
  const isBoughtLeasingWithoutWarranty =
    device?.ownership === OWNERSHIP_CODES.BOUGHT_LEASING && isWarrantyProvided;

  // Есть цена для выкупа у OWNERSHIP === LeasingWithOwnership
  const isPrice =
    device?.ownership === OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP
      ? device.purchasePrice > 0
      : true;

  return (
    <StyledFooterButtonsContainer>
      {isBoughtLeasingWithoutWarranty && (
        <Button
          background={defaultTheme.colors.green}
          border={defaultTheme.colors.green}
          type="button"
          onClick={() => setIsWarrantyPlusWizardShow(true)}
          loading={isWarrantyPlusWizardLoading}
        >
          {!isDesktop ? 'Гарантия+' : 'Подключить «Гарантия+»'}
        </Button>
      )}

      {device?.ownership !== OWNERSHIP_CODES.BOUGHT &&
        !canBeingOwner &&
        isPrice && (
          <Button
            type="button"
            onClick={() => openSidePage(deviceOwnership, false)}
            disabled={!canLeasePay}
            styleType={
              isBoughtLeasingWithoutWarranty
                ? ButtonStyleTypes.SECONDARY
                : ButtonStyleTypes.MAIN
            }
          >
            {getAction()}
          </Button>
        )}

      {device?.ownership !== OWNERSHIP_CODES.BOUGHT_LEASING &&
        (!isLeasingGroup || device?.ownershipEndDays <= 0) &&
        isWarrantyProvided && (
          <Button
            background={defaultTheme.colors.green}
            border={defaultTheme.colors.green}
            type="button"
            onClick={() => setIsWarrantyPlusWizardShow(true)}
            loading={isWarrantyPlusWizardLoading}
          >
            {!isDesktop ? 'Гарантия+' : 'Подключить «Гарантия+»'}
          </Button>
        )}

      {device?.isLeasingAvailable &&
        isLeasingGroup &&
        device?.ownershipEndDays > 0 && (
          <Button
            styleType={ButtonStyleTypes.SECONDARY}
            type="button"
            onClick={() => openSidePage(deviceOwnership, true)}
            disabled={!canDeviceBuyLease}
          >
            {getLeaseAction()}
          </Button>
        )}
    </StyledFooterButtonsContainer>
  );
};

export default DeviceCardWizardFooter;
