/** libraries */
import { FC } from 'react';
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledFooter, RecoveryText } from './styles';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** stores */
import useSettingsStore from '../../store/useSettingsStore';
import { useRootStore } from '~/stores/RootStore';
import useCreatePasswordStore from '~/components/Blocks/Shared/CreatePassword/store/useCreatePasswordStore';

const ChangePasswordFooter: FC = () => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();

  const { setIsShowCreatePasswordWizard } = useCreatePasswordStore();

  const {
    changePasswordStore: { changePassword, isDisableButton },
  } = useSettingsStore();

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  return (
    <StyledFooter>
      <Button
        onClick={changePassword}
        disabled={isDisableButton || isTemporaryTokenAuth}
      >
        Изменить
      </Button>
      {!isDesktop940 && (
        <RecoveryText
          onClick={() => {
            if (!isTemporaryTokenAuth) {
              setIsShowCreatePasswordWizard(true);
            }
          }}
          type="button"
          lineHeight="24px"
          color={
            isTemporaryTokenAuth
              ? defaultTheme.colors.disable
              : defaultTheme.colors.planeta
          }
        >
          Восстановить пароль
        </RecoveryText>
      )}
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(ChangePasswordFooter);
