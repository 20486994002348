/** libraries */
import { FC, useState } from 'react';
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledFooter } from '../../styles';
/** api */
import { deleteContact } from '~/api/apiPab2c';
/** stores */
import useContactsAndNotificationsStore from '../../store/useContactsAndNotificationsStore';
import { useRootStore } from '~/stores/RootStore';

const DeletingContactFooter: FC = () => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();

  const {
    getAllContacts,
    deletingContactStore: {
      deleteContactInfo,
      setIsShowDeletingContact,
      setResult,
    },
  } = useContactsAndNotificationsStore();

  /** Loader удаления контакта */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** Удаление контакта */
  const deletingContact = async () => {
    setIsLoading(true);
    try {
      await deleteContact(deleteContactInfo.id);
      getAllContacts();
      setIsShowDeletingContact(false);
    } catch {
      setResult({ isResult: true, isCorrect: false });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledFooter>
      <Button
        onClick={deletingContact}
        loading={isLoading}
        disabled={isTemporaryTokenAuth}
      >
        Удалить
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(DeletingContactFooter);
