/** библиотеки */
import { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** стили */
import { StyledDevicePurchasesWizard } from './style';

/** компоненты библиотеки */
import {
  Text,
  defaultTheme,
  LeadingText,
  PriceTag,
  Consent,
  Button,
  PriceTagBackgroundColor,
  ButtonStyleTypes,
  Icon,
  Icons,
  H2,
  H3,
} from 'cordis-core-ui-planeta';

/** интерфейсы */
import { DevicePurchasesWizardProps } from './types';
import { ContractDevice } from '../interfaces';

/** компоненты */
import LinkWrapper from '~/components/LinkWrapper';

/** константы */
import { desktop1100 } from '~/components/Grid/constants';
import {
  DEFAULT_ERROR,
  DEVICE_TYPES_GENITIVE,
  OWNERSHIP_CODES,
} from '../constants';
import { DEVICE_PURCHASE_EXCEPTION } from '../../../Payment/constants';
import { DAYS } from '~/components/Blocks/Templates/Pab2c/Devices/DeviceCardWizard/constants';

/** утилиты */
import { formatNumber, pluralizeAll, removeLeadingZero } from '~/utils/utils';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';

/** api */
import { warrantyAgreementGet } from '~/api/apiPab2c';
/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Блок pab2cDevices. SidePage покупки оборудования/погашения рассрочки
 */
const DevicePurchasesWizard: FC<DevicePurchasesWizardProps> = ({
  device,
  setIsWarrantyPlusAgreement,
  setIsBoughtAgreement,
  isPurchaseDevice,
  devicePurchaseCheck,
  deviceLeasePurchaseCheckResult,
  error,
  errorCode,
  result,
  deviceOwnership,
}: DevicePurchasesWizardProps) => {
  const {
    authStore: { auth },
  } = useRootStore();
  /** Вычисление ширины экрана */
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  /** Разница между балансом и ценой оборудования */
  const differenceBetweenBalanceAndLeasePrice =
    auth.balance - deviceLeasePurchaseCheckResult?.leaseCredit ?? 0;

  /** Разница между балансом и ценой оборудования */
  const differenceBetweenBalanceAndPrice = auth.balance - device?.purchasePrice;

  /** Баланс больше чем цена оборудования */
  const isBalanceMoreThanPrice = isPurchaseDevice.isLease
    ? differenceBetweenBalanceAndLeasePrice >= 0
    : differenceBetweenBalanceAndPrice >= 0;

  /** Доступна услуга "Гарантия+" */
  const isAvailable = device?.warrantyPlusInfo?.isAvailable;

  /** Подключена услуга "Гарантия+" */
  const isOrdered = device?.warrantyPlusInfo?.isOrdered;

  /** Текстовое сообщение с предупреждением  */
  const warningText = () => {
    if (isAvailable && !isOrdered)
      return `Внимание! После перехода оборудования в${'\u00A0'}собственность расходы на${'\u00A0'}поддержание работоспособности оборудования клиент несёт самостоятельно. Если необходимо гарантийное обслуживание${'\u00A0'}${'\u2010'} подключите услугу Гарантия+ или продолжите пользование оборудованием на${'\u00A0'}текущих условиях.`;
    if (isOrdered)
      return `Внимание! На${'\u00A0'}оборудование подключена услуга Гарантия+ и${'\u00A0'}действует расширенное гарантийное обслуживание. В${'\u00A0'}случае отключения услуги, расходы на${'\u00A0'}поддержание работоспособности оборудования клиент несёт самостоятельно.`;
    if (!isAvailable && !isOrdered)
      return `Внимание! После перехода оборудования в${'\u00A0'}собственность расходы на${'\u00A0'}поддержание работоспособности оборудования клиент несёт самостоятельно.`;
    return '';
  };

  /** Текст "Гарантия+" */
  const [warrantyText, setWarrantyText] = useState<string | null>(null);

  /** Устанавливает текст "Гарантия+" */
  useEffect(() => {
    (async () => {
      try {
        const warrantyTextResult = await warrantyAgreementGet(device.sim);
        setWarrantyText(warrantyTextResult);
      } catch (e) {
        console.error('warrantyAgreementGet', e);
      }
    })();
  }, []);

  /** Текст соглашения */
  const consentAgreementText = `Я, ${
    auth.clientName
  }, Договор &#8470;&nbsp;${removeLeadingZero(auth.contractName)}, ${
    isPurchaseDevice.isLease
      ? 'настоящим извещаю, что намерен купить в рассрочку предоставленное в&nbsp;пользование с&nbsp;передачей в&nbsp;собственность вышеуказанное оборудование на&nbsp;условиях, предусмотренных <a target="_blank" href="/documents">Договором</a>, и даю согласие на&nbsp;списание с&nbsp;моего Лицевого Счёта стоимости оборудования в порядке, предусмотренном Договором. Я&nbsp;ознакомлен с&nbsp;характеристиками оборудования и ограничениями по его применению.'
      : 'настоящим извещаю, что намерен купить предоставленное в&nbsp;пользование с&nbsp;передачей в&nbsp;собственность вышеуказанное оборудование на&nbsp;условиях, предусмотренных <a target="_blank" href="/documents">Договором</a>, и&nbsp;даю согласие на&nbsp;списание с&nbsp;моего Лицевого Счёта стоимости оборудования. Я&nbsp;ознакомлен с&nbsp;характеристиками оборудования и&nbsp;ограничениями по&nbsp;его применению.'
  }`;

  /** Текст ошибки */
  const [errorMessage, setErrorMessage] = useState<string | null>(error);

  // Обработка ошибок при оплате
  useEffect(() => {
    setErrorMessage(error);
    if (errorCode === DEVICE_PURCHASE_EXCEPTION) {
      setErrorMessage(DEFAULT_ERROR);
    }
  }, [errorCode]);

  /** Текст на экране результата */
  const resultText = `Ура! Вы ${
    deviceOwnership === OWNERSHIP_CODES.LEASING && !isPurchaseDevice.isLease
      ? 'приобрели в собственность '
      : ''
  }
  ${
    deviceOwnership === OWNERSHIP_CODES.BOUGHT_LEASING &&
    !isPurchaseDevice.isLease
      ? 'погасили рассрочку на '
      : ''
  }

  ${isPurchaseDevice.isLease ? 'приобрели в рассрочку ' : ''}
  ${DEVICE_TYPES_GENITIVE[device?.deviceTypeCode]?.toLowerCase()}${'\u00A0'}${
    device?.modelName
  }`;

  return (
    <StyledDevicePurchasesWizard>
      {result.isResult && result.isCorrect && (
        <>
          <div className="device-purchases__result-icon">
            <Icon icon={<Icons.SuccessIconAnimated />} />
          </div>
          {isDesktop1100 ? <H2>{resultText}</H2> : <H3>{resultText}</H3>}
        </>
      )}

      {result.isResult && !result.isCorrect && (
        <>
          <div className="device-purchases__result-icon">
            <Icon icon={<Icons.NotOkBigIcon />} />
          </div>
          {isDesktop1100 ? <H2>{errorMessage}</H2> : <H3>{errorMessage}</H3>}
        </>
      )}

      {!result.isResult && (
        <>
          <div className="device-purchases__block-wrapper">
            <div className="device-purchases__block-wrapper__price-tag">
              <PriceTag
                header={
                  isPurchaseDevice.isLease
                    ? 'Ежедневный платёж'
                    : 'Сумма списания'
                }
                value={`${formatNumber(
                  isPurchaseDevice.isLease
                    ? deviceLeasePurchaseCheckResult?.leaseAnnuity
                    : device?.purchasePrice,
                )} ₽`}
                backgroundColor={
                  isBalanceMoreThanPrice
                    ? PriceTagBackgroundColor.OK
                    : PriceTagBackgroundColor.WARNING
                }
                subscription={`На вашем${'\u000A'}счету ${formatNumber(
                  auth.balance,
                )} ₽`}
                fontColor={
                  isBalanceMoreThanPrice
                    ? defaultTheme.colors.black
                    : defaultTheme.colors.warning
                }
                headerType="H2"
              />
              {!isBalanceMoreThanPrice && (
                <LinkWrapper href="/payment">
                  <Button styleType={ButtonStyleTypes.SECONDARY}>
                    {!isDesktop1100 ? 'Пополнить' : 'Пополнить баланс'}
                  </Button>
                </LinkWrapper>
              )}
            </div>
          </div>
          {isPurchaseDevice.isLease && devicePurchaseCheck && (
            <div className="device-purchases__block-list">
              <div className="device-purchases__block-wrapper">
                <Text
                  className="device-purchases__block-wrapper__header"
                  lineHeight="24px"
                  color={defaultTheme.colors.shadow}
                >
                  Первоначальный взнос
                </Text>
                <LeadingText color={defaultTheme.colors.black}>
                  {(device as ContractDevice).downPayment} ₽
                </LeadingText>
              </div>

              <div className="device-purchases__block-wrapper">
                <Text
                  className="device-purchases__block-wrapper__header"
                  lineHeight="24px"
                  color={defaultTheme.colors.shadow}
                >
                  Срок рассрочки
                </Text>
                <LeadingText color={defaultTheme.colors.black}>
                  {pluralizeAll(devicePurchaseCheck.leasePeriod, DAYS)}
                </LeadingText>
              </div>
            </div>
          )}
          <div className="device-purchases__block-list">
            <div className="device-purchases__block-wrapper">
              <Text
                className="device-purchases__block-wrapper__header"
                lineHeight="24px"
                color={defaultTheme.colors.shadow}
              >
                Серийный номер
              </Text>
              <LeadingText color={defaultTheme.colors.black}>
                {device?.serial}
              </LeadingText>
            </div>
            <div className="device-purchases__block-wrapper">
              <Text
                className="device-purchases__block-wrapper__header"
                lineHeight="24px"
                color={defaultTheme.colors.shadow}
              >
                Полная стоимость
              </Text>
              <LeadingText color={defaultTheme.colors.black}>
                {device?.purchasePrice} ₽
              </LeadingText>
            </div>
          </div>

          {isBalanceMoreThanPrice && (
            <>
              <div className="device-purchases__warning-text">
                <Text lineHeight="24px">{warningText()}</Text>
              </div>
              {isAvailable && !isOrdered && warrantyText && (
                <div className="device-purchases__guarantee">
                  <Consent
                    header="Подключить Гарантия+"
                    text={warrantyText}
                    onChangeCheckbox={(e) =>
                      setIsWarrantyPlusAgreement(e.target.checked)
                    }
                  />
                </div>
              )}
              <div className="device-purchases__agreement">
                <Consent
                  header={`Я${'\u00A0'}намерен(а) купить ${
                    isPurchaseDevice.isLease ? `в${'\u00A0'}рассрочку` : ''
                  } предоставленное в${'\u00A0'}пользование
                  с${'\u00A0'}передачей в${'\u00A0'}собственность оборудование`}
                  text={parseHtml(consentAgreementText)}
                  onChangeCheckbox={(e) =>
                    setIsBoughtAgreement(e.target.checked)
                  }
                />
              </div>
            </>
          )}
        </>
      )}
    </StyledDevicePurchasesWizard>
  );
};

export default observer(DevicePurchasesWizard);
