/** libraries */
import { FC, useEffect, useState } from 'react';
import {
  Button,
  ButtonStyleTypes,
  H2,
  H3,
  Icon,
  Icons,
  LeadingText,
  SidePage,
  Snoska,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledDeviceNotification, StyledErrorSP } from './styles';
import { StyledDevicesItemImage } from '~/components/Blocks/Shared/DevicesCard/style';
/** utils */
import { getImageInfo } from '../../../Devices/utils';
import { HooksTyping } from '~/utils/typeScriptHelpers';
import Portal from '~/components/Portal/Portal';
import { formatNumber } from '~/utils/utils';
/** constants */
import { DEFAULT_IMAGE_SIZE } from '~/components/Blocks/Shared/DevicesCard/constants';
import {
  DEFAULT_IMAGE,
  DEFAULT_RESULT,
  DEVICE_TYPES,
  DEVICE_TYPES_GENITIVE,
  OWNERSHIP_CODES,
} from '../../../Devices/constants';
import {
  DEVICE_PURCHASE_BLOCKED_BY_VACATION_EXCEPTION,
  DEVICE_PURCHASE_CONTRACT_NOT_AVAILABLE_FUNDS_EXCEPTION,
  DEVICE_PURCHASE_DEVICE_NOT_PRICES_EXCEPTION,
  ERROR_MESSAGES,
} from '~/components/Blocks/Templates/Payment/constants';
/** interfaces */
import {
  DevicePurchaseCheckResult,
  DevicePurchaseResult,
  Pab2cDevice,
  ResultProps,
} from '../../../Devices/interfaces';
/** components */
import DevicePurchasesWizard from '../../../Devices/DevicePurchasesWizard/DevicePurchasesWizard';
import DevicePurchasesWizardFooter from '../../../Devices/DevicePurchasesWizard/DevicePurchasesWizardFooter';
/** api */
import { devicePurchase, devicePurchaseCheck } from '~/api/apiPab2c';
/** constants */
import { DEFAULT_ERROR } from '~/constants/common';
import { desktop1100 } from '~/components/Grid/constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface DeviceNotificationProps {
  devices: Pab2cDevice[];
}

const DeviceNotification: FC<DeviceNotificationProps> = ({
  devices,
}: DeviceNotificationProps) => {
  // Устройства клиента
  const {
    pab2cDevicesStore: { updatePab2cDevices, pab2cDevicesList },
  } = useRootStore();
  // Флаг отображения результатов и успешности операции
  const [result, setResult] = useState<ResultProps>(DEFAULT_RESULT);

  const imageInfo = (item) => getImageInfo(item.imageSize);

  // Ссылка на изображение
  const imageSrc = (item) => {
    return imageInfo(item)
      ? imageInfo(item)?.url
      : `${process.env.STATIC_SERVER}${DEFAULT_IMAGE}`;
  };

  /** Размеры изображения */
  const imgSizes = (item) => {
    return {
      newHeight: imageInfo(item)
        ? imageInfo(item)?.height
        : DEFAULT_IMAGE_SIZE.height,
      newWidth: imageInfo(item)
        ? imageInfo(item)?.width
        : DEFAULT_IMAGE_SIZE.width,
    };
  };

  /** Флаг сайдпейджа с ошибкой */
  const [isErrorShow, setIsErrorShow] = useState<boolean>(false);

  // Флаг сайдпейджа покупки оборудования
  const [
    isDevicePurchasesWizardShow,
    setIsDevicePurchasesWizardShow,
  ] = useState(false);

  /** Выбранное оборудование */
  const [detailedDeviceData, setDetailedDeviceData] = useState<Pab2cDevice>(
    null,
  );

  /** Кнопка "оформить" */
  const checkout = (device) => {
    setDetailedDeviceData(device);
  };

  useEffect(() => {
    if (detailedDeviceData) {
      const updatedItem = pab2cDevicesList.find(
        (item) =>
          item.serial === detailedDeviceData.serial &&
          item.sim === detailedDeviceData.sim,
      );

      setDetailedDeviceData(updatedItem);
    }
  }, [pab2cDevicesList]);

  // Событие при закрытии сайдпейджа
  const onCloseSidePage = async () => {
    setResult(DEFAULT_RESULT);
    setIsWarrantyPlusAgreement(false);
    setIsMainAgreement(false);
    setDetailedDeviceData(null);
    setIsDevicePurchasesWizardShow(false);
    await updatePab2cDevices();
  };

  /** Загрузка покупки оборудования */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Флаг чекбокса "Гарантия+"
  const [
    isWarrantyPlusAgreement,
    setIsWarrantyPlusAgreement,
  ] = useState<boolean>(false);

  // Статус покупки оборудования
  const [
    devicePurchaseResult,
    setDevicePurchaseResult,
  ] = useState<DevicePurchaseResult | null>(null);

  // Флаг чекбокса рассрочки или покупки
  const [isMainAgreement, setIsMainAgreement] = useState<boolean>(false);

  /** Метод для выкупа или аренды оборудования */
  const handleDevicePurchase = async (isLease = false) => {
    setIsLoading(true);
    try {
      await devicePurchase(
        detailedDeviceData.sim,
        isLease,
        isWarrantyPlusAgreement,
      );
      setResult({
        isResult: true,
        isCorrect: true,
        text: `Ура! Вы приобрели в собственность ${DEVICE_TYPES_GENITIVE[
          detailedDeviceData?.deviceTypeCode
        ]?.toLowerCase()}${'\u00A0'}${detailedDeviceData?.modelName}`,
      });
    } catch (error) {
      console.error('devicePurchase', error);
      const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
      let description = '';
      switch (err.Type) {
        case DEVICE_PURCHASE_BLOCKED_BY_VACATION_EXCEPTION:
          description = ERROR_MESSAGES.BLOCKED_BY_VACATION;
          break;
        case DEVICE_PURCHASE_DEVICE_NOT_PRICES_EXCEPTION:
          description = ERROR_MESSAGES.DEVICE_NO_PRICES;
          break;
        case DEVICE_PURCHASE_CONTRACT_NOT_AVAILABLE_FUNDS_EXCEPTION:
          description = ERROR_MESSAGES.CONTRACT_NOT_AVAILABLE_FUNDS;
          break;
        default:
          description = ERROR_MESSAGES.ERROR;
      }
      const devicePurchaseResultData = {
        status: {
          description,
          code: err.Type,
        },
      };
      setDevicePurchaseResult(devicePurchaseResultData);
      setResult({
        isResult: true,
        isCorrect: false,
      });
    }
    setIsLoading(false);
  };

  // Данные проверке возможности выкупа оборудования
  const [
    devicePurchaseCheckResult,
    setDevicePurchaseCheckResult,
  ] = useState<DevicePurchaseCheckResult>(null);

  /** Код владения продуктом */
  const [
    deviceOwnership,
    setDeviceOwnership,
  ]: HooksTyping<OWNERSHIP_CODES> = useState<OWNERSHIP_CODES>(null);

  /** Загрузка проверки выкупа */
  const [isLoadingPurchaseCheck, setIsLoadingPurchaseCheck] = useState<number>(
    null,
  );

  /** Проверка выкупа оборудования */
  const checkDevicePurchase = async (inLease: boolean) => {
    try {
      const res = await devicePurchaseCheck(detailedDeviceData.sim, inLease);
      return res;
    } catch (e) {
      const error = JSON.parse(e.errorMessage);
      if (error.Type === 'DevicePurchaseCheckNotAvailableFundsException') {
        setIsLoadingPurchaseCheck(null);
        setIsDevicePurchasesWizardShow(true);
        return null;
      }
      setIsLoadingPurchaseCheck(null);
      setIsErrorShow(true);
      return null;
    }
  };

  useEffect(() => {
    /** Вывод статуса владения оборудованием */
    if (detailedDeviceData) {
      setIsLoadingPurchaseCheck(detailedDeviceData.sim);
      (async () => {
        /** Проверка возможности выкупить оборудование */
        const devicePurchaseCheckResultData = await checkDevicePurchase(
          detailedDeviceData.inLease,
        );

        setDevicePurchaseCheckResult(devicePurchaseCheckResultData);
      })();

      const ownershipKey = () => {
        if (
          detailedDeviceData?.ownership ===
          OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP
        ) {
          /** Если срок передачи в собственность прошёл */
          if (detailedDeviceData.ownershipEndDays === 0)
            return OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP;
          return OWNERSHIP_CODES.LEASING;
        }
        return detailedDeviceData?.ownership;
      };

      setDeviceOwnership(ownershipKey());
    }
  }, [detailedDeviceData]);

  useEffect(() => {
    if (devicePurchaseCheckResult) {
      setIsLoadingPurchaseCheck(null);
      setIsDevicePurchasesWizardShow(true);
    }
  }, [devicePurchaseCheckResult]);

  // Вычисление ширины экрана
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  return (
    <StyledDeviceNotification>
      <div className="devices">
        {devices.map((item) => {
          return (
            <div key={item.sim} className="device">
              <div className="device__item-image-wrapper">
                <StyledDevicesItemImage
                  imgSrc={imageSrc(item)}
                  imgSizes={imgSizes(item)}
                />
              </div>
              <LeadingText
                className="device__name"
                color={defaultTheme.colors.black}
              >
                {DEVICE_TYPES[item.deviceTypeCode]}
              </LeadingText>
              <LeadingText
                className="device__name"
                color={defaultTheme.colors.black}
              >
                {item.modelName}
              </LeadingText>
              {item?.purchasePrice >= 0 && (
                <H3 className="device__price">{`${formatNumber(
                  item.purchasePrice,
                )} ₽`}</H3>
              )}
              <Button
                className="device__button"
                styleType={ButtonStyleTypes.SECONDARY}
                onClick={() => checkout(item)}
                loading={isLoadingPurchaseCheck === item.sim}
              >
                Оформить
              </Button>
            </div>
          );
        })}
      </div>
      <Snoska className="devices__info" color={defaultTheme.colors.black}>
        Вы&nbsp;пользуетесь оборудованием оператора, за&nbsp;которое может
        взиматься плата за&nbsp;использование. Приобретите в&nbsp;собственность
        оборудование по&nbsp;сниженной стоимости и&nbsp;экономьте
        на&nbsp;платежах.
      </Snoska>
      {detailedDeviceData && (
        <Portal>
          <SidePage
            show={isDevicePurchasesWizardShow}
            headerText={
              !result.isResult
                ? `Купить ${DEVICE_TYPES_GENITIVE[
                    detailedDeviceData?.deviceTypeCode
                  ]?.toLowerCase()}  ${detailedDeviceData?.modelName}`
                : undefined
            }
            onCloseClick={() => onCloseSidePage()}
            footerContainer={
              !result.isResult && (
                <DevicePurchasesWizardFooter
                  device={detailedDeviceData}
                  isPurchaseDevice={{
                    isShow: false,
                    isLease: false,
                  }}
                  devicePurchase={handleDevicePurchase}
                  deviceOwnership={deviceOwnership}
                  isLoading={isLoading}
                  isBoughtAgreement={isMainAgreement}
                />
              )
            }
            isOnlyMobileFooter
          >
            <DevicePurchasesWizard
              device={detailedDeviceData}
              isPurchaseDevice={{
                isShow: false,
                isLease: false,
              }}
              devicePurchaseCheck={devicePurchaseCheckResult}
              deviceLeasePurchaseCheckResult={null}
              setIsWarrantyPlusAgreement={setIsWarrantyPlusAgreement}
              error={
                devicePurchaseResult && devicePurchaseResult.status?.description
              }
              errorCode={
                devicePurchaseResult && devicePurchaseResult.status?.code
              }
              result={result}
              deviceOwnership={deviceOwnership}
              setIsBoughtAgreement={setIsMainAgreement}
            />
          </SidePage>
          <SidePage
            show={isErrorShow}
            onCloseClick={() => {
              setDevicePurchaseCheckResult(null);
              setIsErrorShow(false);
              setDetailedDeviceData(null);
            }}
          >
            <StyledErrorSP>
              <div className="error">
                <Icon icon={<Icons.NotOkBigIcon />} />
              </div>
              {isDesktop1100 ? (
                <H2>{DEFAULT_ERROR}</H2>
              ) : (
                <H3>{DEFAULT_ERROR}</H3>
              )}
            </StyledErrorSP>
          </SidePage>
        </Portal>
      )}
    </StyledDeviceNotification>
  );
};

export default observer(DeviceNotification);
