/** Библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
/** Стили */
import { StyledFooter } from './styles';
/** Компоненты */
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
/** interfaces */
import { ProlongationProps } from '../../Templates/Pab2c/Contract/types';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface AnnualProductRenewalWizardFooterProps {
  isAgreement: boolean;
  prolongateProduct: () => Promise<void>;
  isLoadingProlongate: boolean;
  prolongationInfo: ProlongationProps;
}
const AnnualProductRenewalWizardFooter: FC<AnnualProductRenewalWizardFooterProps> = ({
  isAgreement,
  prolongateProduct,
  isLoadingProlongate,
  prolongationInfo,
}: AnnualProductRenewalWizardFooterProps) => {
  const {
    pab2cBalanceStore: { chatleBudgetSaldo },
    authStore: { auth, isTemporaryTokenAuth },
  } = useRootStore();

  /** На балансе достаточно денег для продления годового продукта */
  const isAmountToRenew =
    auth.balance + chatleBudgetSaldo - prolongationInfo?.prolongation?.price >=
    0;
  return (
    <StyledFooter>
      <Button
        type="button"
        onClick={prolongateProduct}
        disabled={!isAgreement || !isAmountToRenew || isTemporaryTokenAuth}
        loading={isLoadingProlongate}
      >
        Продлить продукт
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(AnnualProductRenewalWizardFooter);
