/** библиотеки */
import React, { FC, useMemo, useState } from 'react';

/** компоненты библиотеки */
import { H2, Icons, SidePage, Snoska, Text } from 'cordis-core-ui-planeta';
import Portal from '~/components/Portal/Portal';
import DeviceDetailedCard from '~/components/Blocks/Templates/Devices/DeviceDetailedCard';
import DevicesCard from '~/components/Blocks/Shared/DevicesCard/DevicesCard';

/** стилевые компоненты */
import { StyledList } from './DeviceList.style';

/** типы */
import {
  DeviceListContentProps,
  DeviceItemFilterType,
} from './DeviceList.types';
import {
  DevicesFields,
  ImageSizeData,
} from '~/components/Blocks/Templates/Devices/interfaces';
import { ResultProps } from '../Pab2c/Help/interfaces';

/** утилиты */
import { isExternal } from '~/utils/utils';

/** константы */
import {
  DEVICES_TABS,
  DEVICE_PLURAL,
  RESULT_TEXT,
} from '~/components/Blocks/Templates/Devices/constants';
import {
  StyledLegalBlockDevice,
  StyledResult,
  StyledSP,
  StyledSidePageFooter,
} from '../Devices/style';
import { DEFAULT_RESULT } from '../Pab2c/Help/constants';
import { parseHtml } from '../Legal/parser';

/**
 * Блок "Минивитрина оборудования"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=525730789
 * @param content
 */
const DeviceList: FC<DeviceListContentProps> = ({ content }) => {
  const { allDevices, content: list } = content.fields;
  // Информация о конкретном устройстве
  const [detailedDeviceData, setDetailedDeviceData] = useState<DevicesFields>(
    null,
  );

  // Флаг отображения детального сайдпейджа
  const [isDetailedDeviceShow, setDetailedDeviceShow] = useState<boolean>(
    false,
  );

  const filterDeviceId = list.map((item) => item.id);

  const filteredDevices = useMemo<DevicesFields[]>(() => {
    const newDevices = filterDeviceId.reduce((acc, id) => {
      const newDevice = allDevices.filter(
        (device: DevicesFields) => device.id === id,
      );
      if (newDevice) return [...acc, ...newDevice];
      return acc;
    }, []);
    return newDevices;
  }, []);

  const handleShowDetailedInfoClick = (e, item) => {
    const isClickTab = DEVICES_TABS.some((tab) => {
      return tab === e.target.innerHTML;
    });

    if (!isClickTab) {
      setDetailedDeviceData(item);
      setDetailedDeviceShow(!isDetailedDeviceShow);
    }
  };

  const getCustomTag = (
    deviceItem: DevicesFields,
    contentList: DeviceItemFilterType[],
  ) => {
    return contentList.find(
      (deviceFeature) => deviceFeature.id === deviceItem.id,
    )?.tag;
  };

  const getImageInfo = (deviceImageData: ImageSizeData) => {
    const newImageData = { ...deviceImageData };
    newImageData.url = isExternal(deviceImageData.url)
      ? deviceImageData.url
      : `${process.env.STATIC_SERVER}${deviceImageData.url}`;
    return newImageData;
  };

  /** Результат заказа оборудования */
  const [result, setResult] = useState<ResultProps>(DEFAULT_RESULT);
  /** Телефон для связи */
  const [phone, setPhone] = useState<string>('');

  const deviceCards = useMemo(() => {
    return filteredDevices.map((item) => (
      <React.Fragment key={item.id}>
        <DevicesCard
          customTag={getCustomTag(item, list)}
          item={item}
          onDeviceCardMouseUp={(e) => handleShowDetailedInfoClick(e, item)}
          imageInfo={getImageInfo(item.imageSize)}
        />
      </React.Fragment>
    ));
  }, [filteredDevices]);

  return (
    <>
      <StyledList>{deviceCards}</StyledList>
      <Portal>
        <SidePage
          show={isDetailedDeviceShow}
          headerText={
            detailedDeviceData
              ? `${detailedDeviceData.typeName} ${detailedDeviceData.vendorName} ${detailedDeviceData.name}`
              : ''
          }
          onCloseClick={() => setDetailedDeviceShow(false)}
          removeScrollBar
        >
          <Portal>
            <StyledSP>
              <SidePage
                show={isDetailedDeviceShow}
                headerText={
                  detailedDeviceData && !result.isResult
                    ? `${detailedDeviceData.typeName} ${detailedDeviceData.vendorName} ${detailedDeviceData.name}`
                    : ''
                }
                onCloseClick={() => {
                  setResult(DEFAULT_RESULT);
                  setDetailedDeviceShow(false);
                }}
                footerContainer={
                  !result.isResult && (
                    <StyledSidePageFooter>
                      <StyledLegalBlockDevice>
                        <Snoska>{parseHtml(detailedDeviceData?.footer)}</Snoska>
                      </StyledLegalBlockDevice>
                    </StyledSidePageFooter>
                  )
                }
                removeScrollBar
              >
                {result.isResult ? (
                  <StyledResult>
                    <Icons.OkBigIcon />
                    <H2>{RESULT_TEXT.SUCCESS}</H2>
                    <Text lineHeight="24px">
                      Ваша заявка на заказ{' '}
                      <Text lineHeight="24px" fontWeightBold>
                        {DEVICE_PLURAL[detailedDeviceData.type.toLowerCase()] ??
                          ''}{' '}
                        {detailedDeviceData.vendorName}{' '}
                        {detailedDeviceData.name}
                      </Text>{' '}
                      принята.
                    </Text>
                    <br />
                    <br />
                    <Text lineHeight="24px">
                      Мы свяжемся с вами, чтобы уточнить детали заказа по
                      телефону{' '}
                      <Text lineHeight="24px" fontWeightBold>
                        {phone}
                      </Text>
                      .
                    </Text>
                  </StyledResult>
                ) : (
                  <DeviceDetailedCard
                    item={detailedDeviceData}
                    setResult={setResult}
                    setPhone={setPhone}
                  />
                )}
              </SidePage>
            </StyledSP>
          </Portal>
        </SidePage>
      </Portal>
    </>
  );
};

export default DeviceList;
