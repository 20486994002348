/** библиотеки */
import { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** компоненты библиотеки */
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';

/** константы */
import { desktop940 } from '~/components/Grid/constants';
import { ACTION_MAP, OWNERSHIP_CODES } from '../constants';

/** интерфейсы */
import { DeviceLeasePayWizardFooterProps } from './types';

/** стили */
import { StyledFooter } from './style';

/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Блок pab2cDevices. SidePageFooter покупки оборудования/погашения рассрочки
 */
const DeviceLeasePayWizardFooter: FC<DeviceLeasePayWizardFooterProps> = ({
  device,
  deviceLeasePay,
  deviceOwnership,
  leasePayAmount,
  isLeaseAgreement,
  errorAmount,
}: DeviceLeasePayWizardFooterProps) => {
  /** флаг выполнения запроса */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    authStore: { auth, isTemporaryTokenAuth },
  } = useRootStore();

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Баланс больше 0 */
  const isPositiveBalance = auth.balance > 0;

  const handleClick = () => {
    setIsLoading(true);
    deviceLeasePay(device, leasePayAmount.forSend);
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  /** Текст для кнопки */
  const buttonText = () => {
    if (deviceOwnership === OWNERSHIP_CODES.LEASING)
      return isDesktop940
        ? ACTION_MAP.Leasing.desktop
        : ACTION_MAP.Leasing.mobile;
    if (deviceOwnership === OWNERSHIP_CODES.BOUGHT_LEASING)
      return isDesktop940
        ? ACTION_MAP.BoughtLeasing.desktop
        : ACTION_MAP.BoughtLeasing.mobile;
    return '';
  };

  return (
    <StyledFooter>
      <Button
        className="device-purchases__buy-button"
        disabled={
          !isLeaseAgreement ||
          !isPositiveBalance ||
          +leasePayAmount.forSend > device.leaseSaldo ||
          +leasePayAmount.forSend <= 0 ||
          !leasePayAmount.value.length ||
          errorAmount ||
          isLoading ||
          isTemporaryTokenAuth
        }
        onClick={handleClick}
      >
        {buttonText()}
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(DeviceLeasePayWizardFooter);
