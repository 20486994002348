/** libraries */
import { FC, useEffect } from 'react';
import { add, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** styles */
import { StyledVacationConnection } from './styles';

/** library components */
import {
  defaultTheme,
  Icon,
  Icons,
  LeadingText,
  PriceTag,
  PriceTagBackgroundColor,
  Text,
  H2,
  H3,
  Loader,
} from 'cordis-core-ui-planeta';

/** interfaces */
import { VacationConnectionProps } from './interfaces';

/** constants */
import { desktop1280, desktop940 } from '~/components/Grid/constants';

/** utils */
import { formatNumber } from '~/utils/utils';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';

/** store */
import useVacationStore from '../store/useVacationStore';
import { useRootStore } from '~/stores/RootStore';

const VacationConnection: FC<VacationConnectionProps> = ({
  price,
}: VacationConnectionProps) => {
  const {
    authStore: { auth },
  } = useRootStore();
  const {
    isResult,
    isCorrect,
    agreement,
    getVacationAgreement,
  } = useVacationStore();

  /** Дата начала действия услуги */
  const connectionDate = format(add(new Date(), { days: 1 }), 'd MMMM', {
    locale: ru,
  });

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  useEffect(() => {
    getVacationAgreement();
  }, []);

  return (
    <StyledVacationConnection isSuccessConnection={isResult && isCorrect}>
      {isResult && isCorrect && (
        <>
          <Icon icon={<Icons.OkBigIcon />} />
          {isDesktop1280 && (
            <H2 className="connection__success__header">
              Услуга Каникулы
              <br />
              подключена
            </H2>
          )}
          {isDesktop940 && !isDesktop1280 && (
            <H3 className="connection__success__header">
              Услуга Каникулы
              <br />
              подключена
            </H3>
          )}
          {!isDesktop940 && (
            <LeadingText
              className="connection__success__header"
              color={defaultTheme.colors.black}
            >
              Услуга Каникулы
              <br />
              подключена
            </LeadingText>
          )}
          <div className="connection__block">
            <Text color={defaultTheme.colors.shadow} lineHeight="24px">
              Услуга будет предоставлена
            </Text>
            <LeadingText color={defaultTheme.colors.black}>
              {connectionDate}
            </LeadingText>
          </div>
        </>
      )}
      {isResult && !isCorrect && (
        <>
          <Icon icon={<Icons.NotOkBigIcon />} />
          {isDesktop1280 && (
            <H2 className="connection__success__header">
              Что-то пошло не так...
            </H2>
          )}
          {isDesktop940 && !isDesktop1280 && (
            <H3 className="connection__success__header">
              Что-то пошло не так...
            </H3>
          )}
          {!isDesktop940 && (
            <LeadingText
              className="connection__success__header"
              color={defaultTheme.colors.black}
            >
              Что-то пошло не так...
            </LeadingText>
          )}
        </>
      )}
      {!isResult && (
        <>
          <div className="connection__block">
            <Text color={defaultTheme.colors.shadow} lineHeight="24px">
              Услуга будет предоставлена
            </Text>
            <LeadingText color={defaultTheme.colors.black}>
              {connectionDate}
            </LeadingText>
          </div>
          <div className="connection__block">
            <PriceTag
              header="Стоимость подключения"
              value={`${price} ₽`}
              subscription={`На вашем${'\u000A'}счету ${formatNumber(
                auth.balance,
              )} ₽`}
              backgroundColor={PriceTagBackgroundColor.OK}
            />
          </div>
          <div className="connection__agreement">
            <Text lineHeight="24px" fontWeightBold>
              Я&nbsp;ознакомлен(а) и&nbsp;согласен(на) с&nbsp;условиями
              предоставления и&nbsp;оплаты услуги Каникулы
            </Text>
            {agreement ? (
              <Text lineHeight="24px">{parseHtml(agreement)}</Text>
            ) : (
              <div>
                <Loader small />
              </div>
            )}
          </div>
        </>
      )}
    </StyledVacationConnection>
  );
};

export default observer(VacationConnection);
