/** Библиотеки */
import React, {
  FC,
  useEffect,
  useState,
  useRef,
  useMemo,
  RefObject,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** Стилизованные компоненты */
import {
  LoaderWrapper,
  StyledDevices,
  StyledDevicesHeader,
  StyledSidePage,
} from './Pab2cStyle';

/** Компоненты  */
import {
  H3,
  SidePage,
  Loader,
  Button,
  ButtonStyleTypes,
} from 'cordis-core-ui-planeta';
import DeviceCardWizard from '~/components/Blocks/Templates/Pab2c/Devices/DeviceCardWizard/DeviceCardWizard';
import DevicePurchasesWizard from './DevicePurchasesWizard/DevicePurchasesWizard';
import DeviceCardWizardFooter from '~/components/Blocks/Templates/Pab2c/Devices/DeviceCardWizard/DeviceCardWizardFooter';
import DeviceCardWizardPurchase from '~/components/Blocks/Templates/Pab2c/Devices/DeviceCardWizard/DeviceCardWizardPurchase';
import DeviceCardWizardPurchaseFooter from '~/components/Blocks/Templates/Pab2c/Devices/DeviceCardWizard/DeviceCardWizardPurchaseFooter';
import DevicePurchasesWizardFooter from './DevicePurchasesWizard/DevicePurchasesWizardFooter';
import LinkWrapper from '~/components/LinkWrapper';

/** Интерфейсы */
import {
  ContractDevice,
  DeviceLeasePayResult,
  DevicePurchaseCheckResult,
  DevicePurchaseResult,
  DevicesContentProps,
  LeasePayInputParams,
  PurchaseDeviceShow,
  ResultProps,
} from '~/components/Blocks/Templates/Pab2c/Devices/interfaces';

/** Константы */
import { desktop940 } from '~/components/Grid/constants';
import {
  ACTION_MAP,
  DEFAULT_RESULT,
  DEVICE_TYPES,
  DEVICE_TYPES_GENITIVE,
  OWNERSHIP_CODES,
  TITLE_DEVICES_BLOCK,
} from '~/components/Blocks/Templates/Pab2c/Devices/constants';
import {
  DEVICE_PURCHASE_BLOCKED_BY_VACATION_EXCEPTION,
  DEVICE_PURCHASE_CONTRACT_NOT_AVAILABLE_FUNDS_EXCEPTION,
  DEVICE_PURCHASE_DEVICE_NOT_PRICES_EXCEPTION,
  ERROR_MESSAGES,
  EXCEPTIONS,
} from '../../Payment/constants';
import { DEVICES } from '~/constants/common';

/** api */
import {
  checkLeasePay,
  devicePurchase,
  devicePurchaseCheck,
  leasePay,
} from '~/api/apiPab2c';

/** Утилиты */
import { HooksTyping } from '~/utils/typeScriptHelpers';
import DeviceLeasePayWizardFooter from '~/components/Blocks/Templates/Pab2c/Devices/DeviceLeasePayWizard/DeviceLeasePayWizardFooter';
import DeviceLeasePayWizard from '~/components/Blocks/Templates/Pab2c/Devices/DeviceLeasePayWizard/DeviceLeasePayWizard';
import DeviceSliderList from '~/components/Blocks/Templates/Pab2c/Devices/DeviceSliderList/DeviceSliderList';
import { SidePageHandle } from 'cordis-core-ui-planeta/dist/components/SidePage/SidePage.types';

/** hooks */
import useWarranty from './useWarranty';
import { formatNumber } from '~/utils/utils';
import Portal from '~/components/Portal/Portal';

/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Блок "Моё оборудование"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=597419200
 */
const Devices: FC<DevicesContentProps> = ({ content }) => {
  const {
    size,
    fields: { banner, allDevices, text },
  } = content;

  const {
    authStore: { balanceUpdate, isLoadingAuth },
    pab2cDevicesStore: {
      updatePab2cDevices,
      pab2cDevicesList,
      isLoading: isLoadingDevicesData,
    },
  } = useRootStore();
  const contractDevices: ContractDevice[] = useMemo(() => {
    if (!pab2cDevicesList?.length) return [];
    return pab2cDevicesList.map((item) => {
      const addFields = allDevices?.find(
        (device) => device.id === item.deviceModelId,
      );
      return {
        ...item,
        ...addFields,
        annuity:
          item.ownership === OWNERSHIP_CODES.BOUGHT_LEASING
            ? item?.annuity
            : addFields?.annuity,
      };
    });
  }, [pab2cDevicesList]);

  /** Тип оборудования есть в рассрочке */
  const devicesInLease = useMemo(() => {
    return contractDevices
      .filter((item) => item.ownership === OWNERSHIP_CODES.BOUGHT_LEASING)
      .map((item) => {
        return {
          [item.deviceTypeCode]:
            item.ownership === OWNERSHIP_CODES.BOUGHT_LEASING,
        };
      });
  }, [contractDevices]);

  // Флаг отображения детального сайдпейджа
  const [isDetailedDeviceShow, setDetailedDeviceShow] = useState<boolean>(
    false,
  );

  // Флаг отображения детального сайдпейджа приобретения оборудования
  const [isPurchaseDevice, setIsPurchaseDevice] = useState<PurchaseDeviceShow>({
    isShow: false,
    isLease: false,
  });

  // Флаг сайдпейджа покупки оборудования
  const [
    isDevicePurchasesWizardShow,
    setIsDevicePurchasesWizardShow,
  ] = useState(false);

  // Флаг сайдпейджа погашения рассрочки
  const [isDeviceLeasePayWizardShow, setIsDeviceLeasePayWizardShow] = useState(
    false,
  );

  // Данные проверке возможности выкупа оборудования
  const [
    devicePurchaseCheckResult,
    setDevicePurchaseCheckResult,
  ] = useState<DevicePurchaseCheckResult>(null);

  // Данные проверке возможности выкупа оборудования в рассрочку
  const [
    deviceLeasePurchaseCheckResult,
    setDeviceLeasePurchaseCheckResult,
  ] = useState<DevicePurchaseCheckResult>(null);

  /** Флаг возможности погашения рассрочки */
  const [canLeasePay, setCanLeasePay] = useState<boolean>(null);

  /** Ошибка ввода суммы при погашении рассрочки */
  const [errorAmountLeasePay, setErrorAmountLeasePay] = useState<boolean>(
    false,
  );

  // Флаг чекбокса "Гарантия+"
  const [
    isWarrantyPlusAgreement,
    setIsWarrantyPlusAgreement,
  ] = useState<boolean>(false);

  // Флаг чекбокса рассрочки или покупки
  const [isMainAgreement, setIsMainAgreement] = useState<boolean>(false);

  // Информация о конкретном устройстве
  const [detailedDeviceData, setDetailedDeviceData] = useState<ContractDevice>(
    null,
  );

  /** Код владения продуктом */
  const [
    deviceOwnership,
    setDeviceOwnership,
  ]: HooksTyping<OWNERSHIP_CODES> = useState<OWNERSHIP_CODES>(null);

  const initialLeasingPayment = {
    value: '0 ₽',
    forSend: 0,
  };

  /** Сумма пополнения в счёт выкупа оборудования */
  const [leasePayAmount, setLeasePayAmount] = useState<LeasePayInputParams>(
    initialLeasingPayment,
  );

  // Статус покупки оборудования
  const [
    devicePurchaseResult,
    setDevicePurchaseResult,
  ] = useState<DevicePurchaseResult | null>(null);

  // Статус аренды оборудования
  const [
    deviceLeasePayResult,
    setDeviceLeasePayResult,
  ] = useState<DeviceLeasePayResult | null>(null);

  // Флаг загрузки данных
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Флаг отображения результатов и успешности операции
  const [result, setResult] = useState<ResultProps>(DEFAULT_RESULT);

  // Флаг отображения результатов и успешности операции погашения рассрочки
  const [resultLeasePay, setResultLeasePay] = useState<ResultProps>(
    DEFAULT_RESULT,
  );

  // Родительский сайдпейдж
  const parentSidePage: RefObject<SidePageHandle> = useRef(null);

  const warrantyState = useWarranty(
    detailedDeviceData,
    updatePab2cDevices,
    parentSidePage,
  );

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Проверка выкупа оборудования */
  const checkDevicePurchase = async (inLease: boolean) => {
    try {
      const res = await devicePurchaseCheck(detailedDeviceData.sim, inLease);
      return res;
    } catch (e) {
      const error = JSON.parse(e.errorMessage);
      if (error.Type === 'DevicePurchaseCheckNotAvailableFundsException') {
        return {
          notAvailableFundsError: true,
        };
      }
      return null;
    }
  };

  useEffect(() => {
    /** Вывод статуса владения оборудованием */
    if (detailedDeviceData) {
      (async () => {
        /** Проверка возможности выкупить оборудование */
        const devicePurchaseCheckResultData = await checkDevicePurchase(
          detailedDeviceData.inLease,
        );

        setDevicePurchaseCheckResult(devicePurchaseCheckResultData);

        /** Проверка возможности выкупить оборудование в рассрочку */
        const deviceLeasePurchaseCheckResultData = detailedDeviceData.inLease
          ? devicePurchaseCheckResultData
          : await checkDevicePurchase(true);

        setDeviceLeasePurchaseCheckResult(deviceLeasePurchaseCheckResultData);
      })();

      (async () => {
        /** Проверка возможности погасить рассрочку */
        const res = await checkLeasePay();
        setCanLeasePay(res);
      })();

      const ownershipKey = () => {
        if (
          detailedDeviceData?.ownership ===
          OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP
        ) {
          /** Если срок передачи в собственность прошёл */
          if (detailedDeviceData.ownershipEndDays < 1)
            return OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP;
          return OWNERSHIP_CODES.LEASING;
        }
        return detailedDeviceData?.ownership;
      };

      setDeviceOwnership(ownershipKey());
    }
  }, [detailedDeviceData]);

  useEffect(() => {
    if (detailedDeviceData) {
      const updatedItem = contractDevices.find(
        (item) => item.sim === detailedDeviceData.sim,
      );

      setDetailedDeviceData(updatedItem);
    }
  }, [contractDevices]);

  useEffect(() => {
    if (parentSidePage.current) parentSidePage.current.scrollToTop();
  }, [isDetailedDeviceShow]);

  useEffect(() => {
    setIsLoading(false);
  }, [result]);

  // Изменение состояния сайдпейджа
  const openSidePage = (state, isLease) => {
    switch (state) {
      case OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP:
        setIsPurchaseDevice({ isShow: true, isLease });
        break;
      case OWNERSHIP_CODES.LEASING:
        setIsDevicePurchasesWizardShow(true);
        setIsPurchaseDevice({ isShow: false, isLease });
        break;
      case OWNERSHIP_CODES.BOUGHT_LEASING:
        setIsDeviceLeasePayWizardShow(true);
        break;
      default:
        break;
    }
  };

  /** Метод для выкупа или аренды оборудования */
  const handleDevicePurchase = async (isLease = false) => {
    try {
      setIsLoading(true);
      await devicePurchase(
        detailedDeviceData.sim,
        isLease,
        isWarrantyPlusAgreement,
      );
      setResult({
        isResult: true,
        isCorrect: true,
        text: `Ура! Вы приобрели в собственность ${DEVICE_TYPES_GENITIVE[
          detailedDeviceData?.deviceTypeCode
        ]?.toLowerCase()}${'\u00A0'}${detailedDeviceData.modelName}`,
      });
    } catch (error) {
      console.error('devicePurchase', error);
      const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
      let description = '';
      switch (err.Type) {
        case DEVICE_PURCHASE_BLOCKED_BY_VACATION_EXCEPTION:
          description = ERROR_MESSAGES.BLOCKED_BY_VACATION;
          break;
        case DEVICE_PURCHASE_DEVICE_NOT_PRICES_EXCEPTION:
          description = ERROR_MESSAGES.DEVICE_NO_PRICES;
          break;
        case DEVICE_PURCHASE_CONTRACT_NOT_AVAILABLE_FUNDS_EXCEPTION:
          description = ERROR_MESSAGES.CONTRACT_NOT_AVAILABLE_FUNDS;
          break;
        default:
          description = ERROR_MESSAGES.ERROR;
      }
      const devicePurchaseResultData = {
        status: {
          description,
          code: err.Type,
        },
      };
      setDevicePurchaseResult(devicePurchaseResultData);
      setResult({
        isResult: true,
        isCorrect: false,
      });
    }
  };

  const handleDeviceLeasePay = async (device, moneyAmount: number) => {
    try {
      await leasePay(detailedDeviceData.sim, moneyAmount);
      setResultLeasePay({
        isResult: true,
        isCorrect: true,
        text:
          leasePayAmount.forSend === detailedDeviceData.leaseSaldo
            ? `Ура! Вы погасили рассрочку на ${DEVICE_TYPES_GENITIVE[
                device?.deviceTypeCode
              ]?.toLowerCase()}${'\u00A0'}${device.modelName}`
            : `Ура! Вы погасили часть рассрочки на ${formatNumber(
                leasePayAmount.forSend,
              )} ₽`,
      });
      await updatePab2cDevices();
      await balanceUpdate();
    } catch (error) {
      console.error('leasePay', error);
      const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
      const deviceLeasePayResultData = {
        status: {
          description: EXCEPTIONS[err.Type] ?? ERROR_MESSAGES.ERROR,
          code: err.Type,
        },
      };
      setDeviceLeasePayResult(deviceLeasePayResultData);
      setResultLeasePay({
        isResult: true,
        isCorrect: false,
      });
    }
  };

  // Флаг отображения баннера
  const isShowBanner = contractDevices.length <= 1 && isDesktop940;

  /** Заголовок для сайдпейджа покупки оборудования/погашения рассрочки */
  const headerText = () => {
    const postFix = isPurchaseDevice.isLease ? 'в рассрочку' : '';

    if (deviceOwnership === OWNERSHIP_CODES.LEASING)
      return `Купить ${DEVICE_TYPES_GENITIVE[
        detailedDeviceData?.deviceTypeCode
      ]?.toLowerCase()}  ${detailedDeviceData.modelName} ${postFix}`;
    if (deviceOwnership === OWNERSHIP_CODES.BOUGHT_LEASING)
      return `Погашение рассрочки на ${DEVICE_TYPES_GENITIVE[
        detailedDeviceData?.deviceTypeCode
      ]?.toLowerCase()}  ${detailedDeviceData.modelName}`;
    return '';
  };

  // Событие при закрытии сайдпейджа
  const onCloseSidePage = async () => {
    setResult(DEFAULT_RESULT);
    setResultLeasePay(DEFAULT_RESULT);
    setIsWarrantyPlusAgreement(false);
    setIsMainAgreement(false);
    setDetailedDeviceShow(false);
    setDetailedDeviceData(null);
    await updatePab2cDevices();
  };

  const isDataLoading = isLoadingDevicesData || isLoadingAuth;

  return (
    <StyledDevices>
      <StyledDevicesHeader>
        <H3>{TITLE_DEVICES_BLOCK}</H3>
        <LinkWrapper href={DEVICES} target="_blank">
          {isDesktop940 ? (
            'Заказать ещё'
          ) : (
            <Button styleType={ButtonStyleTypes.SECONDARY}>Заказать ещё</Button>
          )}
        </LinkWrapper>
      </StyledDevicesHeader>

      {isDataLoading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}

      {!isDataLoading && (
        <DeviceSliderList
          devices={contractDevices}
          setDetailedDeviceData={setDetailedDeviceData}
          setDetailedDeviceShow={setDetailedDeviceShow}
          banner={{
            isShow: isShowBanner,
            banner,
          }}
          size={size}
          text={text}
        />
      )}

      {detailedDeviceData && (
        <>
          <Portal>
            {/* Информация об оборудовании */}
            <SidePage
              show={isDetailedDeviceShow}
              headerText={`
              ${
                detailedDeviceData?.deviceTypeCode
                  ? DEVICE_TYPES[detailedDeviceData?.deviceTypeCode]
                  : ''
              }  ${detailedDeviceData.modelName}`}
              onCloseClick={() => setDetailedDeviceShow(false)}
              footerContainer={
                <DeviceCardWizardFooter
                  device={detailedDeviceData}
                  openSidePage={openSidePage}
                  deviceLeasePurchaseCheck={deviceLeasePurchaseCheckResult}
                  deviceOwnership={deviceOwnership}
                  devicesInLease={devicesInLease}
                  canLeasePay={canLeasePay}
                  warrantyState={warrantyState}
                />
              }
              isOnlyMobileFooter
              removeScrollBar
              ref={parentSidePage}
            >
              <DeviceCardWizard
                openSidePage={openSidePage}
                deviceOwnership={deviceOwnership}
                device={detailedDeviceData}
                warrantyState={warrantyState}
              />
            </SidePage>
          </Portal>
          <Portal wrapperId="portalPrimary">
            {/* Сайдпейдж передачи в собственность */}
            <StyledSidePage
              $isHideHeader={result.isResult}
              show={isPurchaseDevice.isShow}
              headerText={`${
                ACTION_MAP[detailedDeviceData.ownership].desktop
              } ${
                detailedDeviceData?.deviceTypeCode
                  ? DEVICE_TYPES_GENITIVE[
                      detailedDeviceData?.deviceTypeCode
                    ]?.toLowerCase()
                  : ''
              }  ${detailedDeviceData.modelName}`}
              onCloseClick={() => {
                onCloseSidePage();
                setIsPurchaseDevice({ isShow: false, isLease: false });
              }}
              footerContainer={
                !result.isResult ? (
                  <DeviceCardWizardPurchaseFooter
                    device={detailedDeviceData}
                    devicePurchase={handleDevicePurchase}
                  />
                ) : (
                  <></>
                )
              }
              isOnlyMobileFooter
              removeScrollBar
            >
              <DeviceCardWizardPurchase
                device={detailedDeviceData}
                error={devicePurchaseResult?.status?.description}
                errorCode={devicePurchaseResult?.status?.code}
                setIsWarrantyPlusAgreement={setIsWarrantyPlusAgreement}
                result={result}
              />
            </StyledSidePage>

            {/* Сайдпейдж покупки оборудования */}
            <StyledSidePage
              $isHideHeader={result.isResult}
              show={isDevicePurchasesWizardShow}
              headerText={headerText()}
              onCloseClick={() => {
                onCloseSidePage();
                setIsDevicePurchasesWizardShow(false);
              }}
              footerContainer={
                !result.isResult ? (
                  <DevicePurchasesWizardFooter
                    device={detailedDeviceData}
                    isPurchaseDevice={isPurchaseDevice}
                    deviceLeasePurchaseCheckResult={
                      deviceLeasePurchaseCheckResult
                    }
                    devicePurchase={handleDevicePurchase}
                    deviceOwnership={deviceOwnership}
                    isLoading={isLoading}
                    isBoughtAgreement={isMainAgreement}
                  />
                ) : (
                  <></>
                )
              }
              isOnlyMobileFooter
            >
              <DevicePurchasesWizard
                device={detailedDeviceData}
                isPurchaseDevice={isPurchaseDevice}
                devicePurchaseCheck={devicePurchaseCheckResult}
                deviceLeasePurchaseCheckResult={deviceLeasePurchaseCheckResult}
                setIsWarrantyPlusAgreement={setIsWarrantyPlusAgreement}
                setIsBoughtAgreement={setIsMainAgreement}
                error={devicePurchaseResult?.status?.description}
                errorCode={devicePurchaseResult?.status?.code}
                result={result}
                deviceOwnership={deviceOwnership}
              />
            </StyledSidePage>

            {/* Сайдпейдж погашения рассрочки */}
            <StyledSidePage
              $isHideHeader={resultLeasePay.isResult}
              show={isDeviceLeasePayWizardShow}
              headerText={headerText()}
              onCloseClick={() => {
                onCloseSidePage();
                setIsDeviceLeasePayWizardShow(false);
                setLeasePayAmount(initialLeasingPayment);
              }}
              footerContainer={
                !resultLeasePay.isResult ? (
                  <DeviceLeasePayWizardFooter
                    device={detailedDeviceData}
                    deviceLeasePay={handleDeviceLeasePay}
                    leasePayAmount={leasePayAmount}
                    deviceOwnership={deviceOwnership}
                    isLeaseAgreement={isMainAgreement}
                    errorAmount={errorAmountLeasePay}
                  />
                ) : (
                  <></>
                )
              }
              isOnlyMobileFooter
            >
              <DeviceLeasePayWizard
                device={detailedDeviceData}
                handleUpdateLeasePay={setLeasePayAmount}
                setIsWarrantyPlusAgreement={setIsWarrantyPlusAgreement}
                error={deviceLeasePayResult?.status?.description}
                errorCode={deviceLeasePayResult?.status?.code}
                result={resultLeasePay}
                deviceOwnership={deviceOwnership}
                deviceLeasePay={handleDeviceLeasePay}
                leasePayAmount={leasePayAmount}
                isLeaseAgreement={isMainAgreement}
                setIsLeaseAgreement={setIsMainAgreement}
                errorAmount={errorAmountLeasePay}
                setErrorAmount={setErrorAmountLeasePay}
              />
            </StyledSidePage>
          </Portal>
        </>
      )}
    </StyledDevices>
  );
};

export default observer(Devices);
