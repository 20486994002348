import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  FC,
} from 'react';
import { H3, Text } from 'cordis-core-ui-planeta';
/** styles */
import { StyledTimer } from './styles';

interface TimerProps {
  setTimeIsUp: Dispatch<SetStateAction<boolean>>;
  isBig?: boolean;
}
const Timer: FC<TimerProps> = ({
  setTimeIsUp,
  isBig,
}: TimerProps): JSX.Element => {
  const [totalSeconds, setTotalSeconds] = useState(120);

  useEffect(() => {
    const interval = setInterval(() => {
      if (totalSeconds === 0) {
        clearInterval(interval);
        handleTimeUp();
      } else {
        setTotalSeconds((prevTotalSeconds) => prevTotalSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [totalSeconds]);

  const handleTimeUp = () => {
    setTimeIsUp(true);
  };

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return (
    <StyledTimer>
      {isBig ? (
        <H3>
          {minutes}:{seconds.toString().padStart(2, '0')}
        </H3>
      ) : (
        <Text lineHeight="24px">
          {minutes}:{seconds.toString().padStart(2, '0')}
        </Text>
      )}
    </StyledTimer>
  );
};

export default Timer;
