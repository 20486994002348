/** libraries */
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Button,
  defaultTheme,
  H3,
  Icon,
  Icons,
  Loader,
  TagButton,
  TagButtonIconPosition,
  Text,
} from 'cordis-core-ui-planeta';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';

/** api */
import { getMarketingGroupsByCity } from '~/api/api';
import { getSummariesByTariffIds } from '~/api/apiPab2c';

/** stores */
import { useRootStore } from '~/stores/RootStore';

/** components */
import Summary from '../Summary/Summary';
import ProductTemplate from '../../Shared/ProductTemplate/ProductTemplate';
import ChangeConnectionTypeSP from './ChangeConnectionTypeSP';
import Portal from '~/components/Portal/Portal';

/** utils */
import { HooksTyping } from '~/utils/typeScriptHelpers';
import useWindowSize from '~/utils/useWindowSize';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';

/** interfaces */
import {
  AllowedTariffProps,
  MarketingGroups,
  ProductSwitcherContentProps,
  ProductSwitcherTemplate,
  Tag,
  TariffProps,
} from './interfaces';
import {
  ActionType,
  BUSINESS_GROUP_CODE,
  SummaryFields,
} from '../Summary/Summary.types';
import {
  MarketingFieldsProps,
  ProductSelectorMiniSummaryProps,
} from '../../Shared/ProductTemplate/ProductTemplate.interfaces';

/** styles */
import { StyledMiniProductSwitcher, StyledProductSwitcher } from './style';

/** constants */
import { ALL_TAG, DEFAULT_TAG } from './constants';
import { desktop940 } from '~/components/Grid/constants';

/**
 * Блок "Блок «ЛК. Смена продукта»"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=711869968
 */
const ProductSwitcher: FC<ProductSwitcherContentProps> = ({
  content,
}: ProductSwitcherContentProps) => {
  const {
    products,
    productsNotAvailableText,
    unionTariffNotAuth,
    sample,
  } = content?.fields;
  const {
    authStore: { isAuth },
    cityStore: { city },
    summaryDataStore: { summaryData, seriesCode },
    allowedTariffStore: { allowedTariff },
  } = useRootStore();

  const extendedSummaryData = useMemo(() => {
    if (!seriesCode) return null;
    const summaryFromCompare = products.find(
      (summary) => summary.seriesCode === seriesCode,
    );
    return {
      ...summaryData,
      ...summaryFromCompare,
    };
  }, [seriesCode]);

  /** Ширина сайта */
  const [width] = useWindowSize();

  // Объект router
  const router = useRouter();
  // Урл страницы
  const url = new URL(document.location.href);

  /** Доступные тарифы */
  const [tariff, setTariff] = useState<TariffProps[]>([]);

  /** Загрузка продуктов */
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /** Маркетинг группы */
  const [marketingGroups, setMarketingGroups] = useState<MarketingGroups[]>([]);

  const getMarketingGroups = async () => {
    try {
      const res = await getMarketingGroupsByCity(city.id);
      const group =
        isAuth || sample === ProductSwitcherTemplate.MINI
          ? res
          : res.filter((item) => {
              return item.code !== BUSINESS_GROUP_CODE;
            });
      setMarketingGroups(group);
    } catch (e) {
      console.error('GetMarketingGroupsByCity', e);
    }
  };

  /** Доступные в городе продукты */
  const marketingGroupsProducts = useMemo(() => {
    if (!marketingGroups.length) return [];
    return marketingGroups.reduce((acc, item) => {
      acc.push(...item.marketingSeries.map((series) => series.seriesCode));
      return acc;
    }, []);
  }, [marketingGroups]);

  /** Объединение данных о тарифах */
  const defineTariffInfo = async () => {
    if (
      !marketingGroupsProducts.length ||
      (isAuth && !allowedTariff?.length) ||
      (!isAuth && allowedTariff?.length)
    )
      return;
    setIsLoading(true);

    const unionTariffAuth = [];
    if (allowedTariff?.length && isAuth) {
      const allowedTariffIds = ((allowedTariff as unknown) as AllowedTariffProps[]).map(
        (item) => item.tariffTo.tariffId,
      );
      const summariesData = await getSummariesByTariffIds(allowedTariffIds);
      const dataForTariff = ((allowedTariff as unknown) as AllowedTariffProps[]).map(
        (item) => {
          const summaryFromCompare = products.find(
            (summary) => summary.seriesCode === item.tariffTo.seriesCode,
          );
          const extendedSummary = summaryFromCompare
            ? summariesData.find(
                (summary) =>
                  summary.seriesCode === summaryFromCompare.seriesCode,
              )
            : null;
          return {
            ...item,
            ...summaryFromCompare,
            ...(extendedSummary ?? null),
          };
        },
      );
      unionTariffAuth.push(...dataForTariff);
    }

    const unionTariff = !isAuth ? unionTariffNotAuth : unionTariffAuth;

    if (isAuth) {
      setTariff(
        (unionTariff as TariffProps[]).filter(
          (item) =>
            item.seriesCode &&
            item.seriesCode !== extendedSummaryData?.seriesCode,
        ),
      );
    } else {
      setTariff(
        (unionTariff as TariffProps[]).filter(
          (item) =>
            item.seriesCode &&
            item.seriesCode !== extendedSummaryData?.seriesCode &&
            marketingGroupsProducts.includes(item.seriesCode),
        ),
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    defineTariffInfo();
  }, [allowedTariff.length, isAuth, marketingGroupsProducts, seriesCode]);

  useEffect(() => {
    if (city) getMarketingGroups();
  }, [city.id, isAuth]);

  /** tags */
  const tags: Tag[] = useMemo(() => {
    if (!tariff.length || !marketingGroups.length) {
      return [];
    }
    const marketingTags =
      extendedSummaryData?.marketingGroupCode === BUSINESS_GROUP_CODE
        ? []
        : marketingGroups.reduce((acc, group) => {
            const productsList = tariff.reduce((filteredProducts, item) => {
              if (
                +item.tariffId &&
                group.marketingSeries.some(
                  (product) => product.seriesCode === item.seriesCode,
                )
              ) {
                filteredProducts.push(item);
              }
              return filteredProducts;
            }, []);

            if (productsList.length) {
              acc.push({
                code: group.code,
                tag: group.code,
                name: group.name,
                sort: group.sort,
                productsList,
              });
            }

            return acc;
          }, []);

    /** Тег "Все" */
    const all = ALL_TAG;
    all.productsList = tariff.filter((item) => +item.tariffId);
    marketingTags.push(all);

    const sortedMarketingTags = marketingTags.sort((a, b) => {
      if (!a.sort || a.sort < b.sort) return 1;
      return -1;
    });
    return sortedMarketingTags;
  }, [tariff, marketingGroups]);

  const defaultTag = useMemo(() => {
    if (!tags.length) return null;
    return tags.find((item) => item.code === DEFAULT_TAG);
  }, [tags]);

  // Состояние маркетинг тегов
  const [activeProductTag, setActiveProductTag] = useState<Tag>(null);

  /** Выбираемый, но не подтверждённый тег в мобильной версии */
  const [
    candidateForActiveTag,
    setCandidateForActiveTag,
  ]: HooksTyping<Tag> = useState<Tag>(null);

  useEffect(() => {
    if (isLoading || !tags?.length) return;
    const initial = tariff.length && defaultTag ? defaultTag : ALL_TAG;
    const urlTag = tags.find((item) => `#${item.tag}` === url.hash);
    setActiveProductTag(urlTag ?? initial);
    setCandidateForActiveTag(initial);
  }, [defaultTag, tariff, isLoading, tags, isAuth, url.hash]);

  /** Вид блока с тегами в мобильной версии */
  const [
    isThemesOpen,
    setIsThemesOpen,
  ]: HooksTyping<boolean> = useState<boolean>(false);

  // Обработка события нажатия на checkbox с тегом
  const onCheckboxClick = (
    event: React.FormEvent<HTMLInputElement>,
    item: Tag,
  ): void => {
    if (!event) return;

    if (width < desktop940) {
      setCandidateForActiveTag(item);
      return;
    }
    // Обновляет состояние тега по коду маркетинг категории
    changeActiveTag(item);
  };

  /** Обрабатывает клик по кнопке "Показать" */
  const handleTagButtonClick = () => {
    setIsThemesOpen(false);
    // Обновляет состояние тега по коду маркетинг категории
    changeActiveTag(candidateForActiveTag);
  };

  /**
   * Меняет значение активного тега
   * @param item текущий тег
   */
  const changeActiveTag = (item): void => {
    if (item?.tag === activeProductTag?.tag) return;
    if (!item.tag) return;
    setActiveProductTag(item);
    changeURL(item);
  };

  /**
   * Меняет Url
   * @param item текущий тег
   */
  const changeURL = (item): void => {
    const valueHash = `#${item.tag}`;
    router.push(`${url.pathname}${valueHash}`, `${url.pathname}${valueHash}`, {
      shallow: true,
    });
  };

  /** Отфильтрованные по тегам продукты */
  const filteredTariff = useMemo(() => {
    if (!tags.length || !activeProductTag) return [];
    const filteredTag =
      tags.find((tag) => tag.code === activeProductTag.code)?.productsList ??
      [];
    return filteredTag.sort((a, b) => a.sort - b.sort);
  }, [activeProductTag, tags]);

  useEffect(() => {
    if (width < desktop940 && isThemesOpen) setIsThemesOpen(false);
  }, [width]);

  /** Изменение выбираемого, но не подтверждённого тега в мобильной версии */
  useEffect(() => {
    setCandidateForActiveTag(activeProductTag);
  }, [activeProductTag]);

  const isPrivateTariff = (seriesCodeProp): boolean => {
    return !marketingGroupsProducts.includes(seriesCodeProp);
  };

  if (sample === ProductSwitcherTemplate.MINI) {
    return (
      <StyledMiniProductSwitcher>
        {tariff.length === 0 && !isLoading ? (
          <Text className="unavailable" lineHeight="24px">
            {parseHtml(productsNotAvailableText)}
          </Text>
        ) : (
          <>
            {isLoading ? (
              <div className="loader">
                <Loader />
              </div>
            ) : (
              <ProductTemplate
                blocks={
                  (products as unknown) as ProductSelectorMiniSummaryProps[]
                }
                marketingFields={(tags as unknown) as MarketingFieldsProps[]}
                summaryFields={tariff}
                activeProductTag={
                  (activeProductTag as unknown) as MarketingFieldsProps
                }
                isMiniProductSwitcher
              />
            )}
          </>
        )}
      </StyledMiniProductSwitcher>
    );
  }

  return (
    <>
      <StyledProductSwitcher isThemesOpen={isThemesOpen}>
        {(filteredTariff?.length === 0 && tariff.length !== 0) || isLoading ? (
          <div className="product-switcher__loader">
            <Loader />
          </div>
        ) : (
          <>
            <div className="product-switcher__single-summary">
              {extendedSummaryData?.seriesCode && isAuth && (
                <Summary
                  content={{
                    fields: (extendedSummaryData as unknown) as SummaryFields &
                      ActionType,
                  }}
                  isPrivate={isPrivateTariff(extendedSummaryData.seriesCode)}
                />
              )}
            </div>
            {tags.length > 0 && (
              <div className="product-switcher__tags-block">
                {width >= desktop940 ? (
                  <Text color={defaultTheme.colors.shadow}>Продукты</Text>
                ) : (
                  <div className="product-switcher__tags-block__burger-menu">
                    <H3>Продукты</H3>
                    <Icon
                      icon={
                        isThemesOpen ? <Icons.CloseIcon /> : <Icons.ListIcon />
                      }
                      onClick={() => setIsThemesOpen(!isThemesOpen)}
                      highlight
                    />
                  </div>
                )}
                {(width >= desktop940 || isThemesOpen) && activeProductTag && (
                  <>
                    <div className="product-switcher__tags-block__tags">
                      {tags.map((item) => (
                        <TagButton
                          key={item.code}
                          onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            onCheckboxClick(e, item)
                          }
                          checked={
                            width < desktop940
                              ? item.code === candidateForActiveTag.code
                              : item.code === activeProductTag.code
                          }
                          iconPosition={TagButtonIconPosition.LEFT}
                        >
                          {item.name}
                        </TagButton>
                      ))}
                    </div>
                    {width < desktop940 && (
                      <div className="product-switcher__tags-block__button-wrapper">
                        <Button onClick={handleTagButtonClick}>Показать</Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            <div className="product-switcher__summary">
              {filteredTariff.length > 0 ? (
                filteredTariff.map((item) => {
                  return (
                    <Summary
                      key={item.seriesCode}
                      content={{ fields: { ...item } }}
                      isPrivate={isPrivateTariff(
                        extendedSummaryData?.seriesCode,
                      )}
                      isLinkInNewTab
                    />
                  );
                })
              ) : (
                <Text lineHeight="24px">
                  {parseHtml(productsNotAvailableText)}
                </Text>
              )}
            </div>
          </>
        )}
      </StyledProductSwitcher>
      <Portal>
        <ChangeConnectionTypeSP />
      </Portal>
    </>
  );
};

export default observer(ProductSwitcher);
