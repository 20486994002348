import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

/** стилизованный блок-раздел для развёрнутого меню */
export const StyledFolder = styled.div`
  margin-bottom: 16px;

  ul {
    margin-top: 18px;

    li + li {
      margin-top: 18px;
    }
  }

  a {
    &:hover {
      text-decoration: none;
      color: ${defaultTheme.colors.planeta};
    }
  }
`;
