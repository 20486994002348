/** libraries */
import { FC, RefObject, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
/** styles */
import { StyledProductSelector } from './ProductTemplate.style';
/** interfaces */
import {
  ProductTemplateProps,
  SummaryFieldsProps,
} from './ProductTemplate.interfaces';
import { BUSINESS_GROUP_CODE } from '../../Templates/Summary/Summary.types';
/** constants */
import { DEFAULT_RESULT } from '../../Templates/ProductSwitcher/ProductSwitcherWizard/constants';
/** components */
import ProductSelectorItem from './ProductSelectorItem/ProductSelectorItem';
import ProductSwitcherWizard from '../../Templates/ProductSwitcher/ProductSwitcherWizard/ProductSwitcherWizard';
import ChangeConnectionTypeSP from '../../Templates/ProductSwitcher/ChangeConnectionTypeSP';
import AddingContactSidePage from '../ContactsAndNotifications/Components/AddingContact/AddingContactSidePage';
import ArrowsContainer from './ArrowsContainer';
import ProductTags from './ProductTags';
import Portal from '~/components/Portal/Portal';
/** stores */
import { useRootStore } from '~/stores/RootStore';
/** hooks */
import { useProductTemplate } from './ProductTemplateHook';

const ProductTemplate: FC<ProductTemplateProps> = ({
  blocks,
  marketingFields,
  summaryFields,
  withoutFilters,
  activeProductTag,
  isMiniProductSwitcher,
}: ProductTemplateProps) => {
  const {
    connectionTariffStore: { connectTariff },
  } = useRootStore();

  const productRef: RefObject<HTMLDivElement> | null = useRef(null);

  const {
    getProductList,
    activeProductTags,
    setActiveProductTags,
    getMarketingNameByCode,
    getMiniSummaryByCode,
    connectResult,
    setConnectResult,
    connectLoading,
    setConnectLoading,
    agreement,
    setAgreement,
    productChangeError,
    setProductChangeError,
    handleSetTariff,
    productListLength,
    xPosition,
    setXPosition,
    codes,
  } = useProductTemplate({
    marketingFields,
    summaryFields,
    blocks,
    isMiniProductSwitcher,
  });

  useEffect(() => {
    if (!connectTariff) {
      setAgreement(null);
      setConnectResult(DEFAULT_RESULT);
      setConnectLoading(false);
    }
  }, [connectTariff]);

  return (
    <StyledProductSelector
      xPosition={xPosition}
      ref={productRef}
      withoutFilters={withoutFilters}
    >
      {!withoutFilters && (
        <ProductTags
          marketingFields={marketingFields}
          isMiniProductSwitcher={isMiniProductSwitcher}
          activeProductTag={activeProductTag}
          activeProductTags={activeProductTags}
          setActiveProductTags={setActiveProductTags}
          codes={codes}
        />
      )}
      <ArrowsContainer
        productListLength={productListLength}
        activeProductTags={activeProductTags}
        productRef={productRef}
        xPosition={xPosition}
        setXPosition={setXPosition}
      >
        {productListLength > 0 &&
          getProductList().map(
            (item: SummaryFieldsProps) =>
              getMiniSummaryByCode(item.seriesCode)[0] && (
                <ProductSelectorItem
                  key={item.seriesCode}
                  isAnnual={item.isAnnual}
                  channelsInfo={item.channelsInfo}
                  marketingName={getMarketingNameByCode(
                    item.marketingGroupCode,
                  )}
                  marketingText={item.marketingText}
                  priceInfo={item.priceInfo}
                  seriesName={item.seriesName}
                  speedBaseText={item.speedBaseText}
                  marketingCategoryFeedLink={
                    getMiniSummaryByCode(item.seriesCode)[0]
                      .marketingCategoryFeedLink
                  }
                  productFeedLink={
                    getMiniSummaryByCode(item.seriesCode)[0].productFeedLink
                  }
                  tags={item.tags}
                  isBusiness={item.marketingGroupCode === BUSINESS_GROUP_CODE}
                  tariffId={item.tariffId}
                  withoutFilters={withoutFilters}
                  action={getMiniSummaryByCode(item.seriesCode)[0].action}
                  setAgreement={setAgreement}
                  setProductChangeError={setProductChangeError}
                  tariff={item}
                />
              ),
          )}
      </ArrowsContainer>
      <Portal wrapperId="portalPrimary">
        <AddingContactSidePage />
        <ChangeConnectionTypeSP />
      </Portal>
      {agreement && (
        <ProductSwitcherWizard
          tariff={connectTariff}
          result={connectResult}
          agreement={agreement}
          productChangeError={productChangeError}
          setProductChangeError={setProductChangeError}
          handleSetTariff={handleSetTariff}
          connectLoading={connectLoading}
        />
      )}
    </StyledProductSelector>
  );
};

export default observer(ProductTemplate);
