/** libraries */
import { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react';

/** interfaces */
import { VacationProps } from './interfaces';

/** styles */
import { StyledFooter, StyledVacation } from './style';

/** library components */
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H3,
  SidePage,
  Snoska,
  Switcher,
  Text,
} from 'cordis-core-ui-planeta';

/** constants */
import {
  desktop400,
  desktop940,
  desktop1100,
  desktop1280,
} from '~/components/Grid/constants';
import { SUSPEND_STATE } from '../Pab2c/Contract/constants';
import { DEFAULT_ERROR, OPERATING_STATE } from '~/constants/common';
import { VIEW_TYPES } from '../Pab2c/ContractState/types';

/** api */
import { resumeService } from '~/api/apiPab2c';
/** components */
import VacationConnection from './VacationConnection/VacationConnection';
import LinkWrapper from '~/components/LinkWrapper';
import Portal from '~/components/Portal/Portal';
import OperatingStateWizard from '../Pab2c/ContractState/Components/OperatingStateWizard/OperatingStateWizard';
import OperatingStateWizardFooter from '../Pab2c/ContractState/Components/OperatingStateWizard/OperatingStateWizardFooter';
/** store */
import useVacationStore from './store/useVacationStore';
import { useRootStore } from '~/stores/RootStore';

/** Блок «ЛК. Каникулы»
 *  https://ckb.itmh.ru/pages/viewpage.action?pageId=669188148
 */
const Vacation: FC<VacationProps> = ({ content }: VacationProps) => {
  const { size } = content;
  const {
    authStore: { isTemporaryTokenAuth },
    contractStateStore: { contractState, updateAuthCondition },
  } = useRootStore();

  const {
    isChecked,
    isVacationConnectionOpen,
    isLoading,
    isResult,
    isCorrect,
    dateTrim,
    activationDate,
    isOrdered,
    price,
    isAvailable,
    isNotEnoughMoney,
    isActivated,
    isEnabledSuspending,
    isOrderSuspending,
    getVacation,
    setVacationStart,
    setIsChecked,
    setIsVacationConnectionOpen,
    setIsVacationShutdownOpen,
    reset,
  } = useVacationStore();

  useEffect(() => {
    setIsChecked(isOrdered || isActivated);
  }, [isOrdered, isActivated]);

  // Вычисление ширины экрана
  const isDesktop400 = useMediaQuery({
    query: `(min-width: ${desktop400}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  /** Описание при отрицательном балансе */
  const negativeBalanceText = (
    <Text
      className="vacation__text"
      color={defaultTheme.colors.white}
      lineHeight="24px"
    >
      <span className="vacation__text__bold">Пополните баланс</span> вашего
      лицевого счета и&nbsp;отправьте его на&nbsp;каникулы!
      <br />
      То&nbsp;есть ВООБЩЕ{' '}
      <span className="vacation__text__bold">никаких списаний!</span>
    </Text>
  );

  /** Дефолтный текст */
  const defaultText = (
    <Text
      className="vacation__text"
      color={defaultTheme.colors.white}
      lineHeight="24px"
    >
      Отправьте баланс вашего лицевого счёта на&nbsp;каникулы! То&nbsp;есть
      ВООБЩЕ
      <br />
      <span className="vacation__text__bold">никаких списаний!</span>
    </Text>
  );

  /** Описание при выключенной услуги "Каникулы" */
  const vacationDisabledText = (
    <Text
      className="vacation__text vacation__text__disabled"
      color={defaultTheme.colors.white}
      lineHeight="24px"
    >
      Отправьте баланс вашего лицевого счёта на&nbsp;каникулы! То&nbsp;есть
      ВООБЩЕ{' '}
      <Text color={defaultTheme.colors.white} lineHeight="24px" fontWeightBold>
        никаких списаний!
      </Text>
    </Text>
  );

  /** Описание при заказанной услуги "Каникулы" */
  const vacationOrderedText = (
    <Text
      className="vacation__text vacation__text__ordered"
      color={defaultTheme.colors.white}
      lineHeight="24px"
    >
      Баланс вашего лицевого счета на&nbsp;каникулах! То&nbsp;есть ВООБЩЕ{' '}
      <Text color={defaultTheme.colors.white} lineHeight="24px" fontWeightBold>
        никаких списаний!
      </Text>
    </Text>
  );

  /** Описание при подключённой услуги "Каникулы" */
  const vacationEnabledText = (
    <Text
      className="vacation__text vacation__text__enabled"
      color={defaultTheme.colors.white}
      lineHeight="24px"
    >
      Абонентская плата не&nbsp;взимается. Услуги не&nbsp;предоставляются.
    </Text>
  );

  /** Описание при новом договоре */
  const vacationNewContractText = (
    <Text
      className="vacation__text vacation__text__new-contract"
      color={defaultTheme.colors.white}
      lineHeight="24px"
    >
      Оформление услуги будет доступно после завершения подключения договора
    </Text>
  );

  /** Описание при зомби */
  const vacationStrayText = (
    <Text
      className="vacation__text vacation__text__new-contract"
      color={defaultTheme.colors.white}
      lineHeight="24px"
    >
      Ваш договор не&nbsp;обслуживается. Обратитесь в&nbsp;офис обслуживание или
      по телефону, чтобы восстановить его.
    </Text>
  );

  const [
    isOperatingStateWizardShow,
    setIsOperatingStateWizardShow,
  ] = useState<boolean>(false);

  /** Описание при добровольной приостановке */
  const vacationClientBlockContractText = (
    <Text
      className="vacation__text vacation__text__new-contract"
      color={defaultTheme.colors.white}
      lineHeight="24px"
    >
      <span
        className="vacation__resume-button"
        onClick={() => setIsOperatingStateWizardShow(true)}
      >
        Возобновите
      </span>{' '}
      обслуживание для подключения услуги «Каникулы»
    </Text>
  );

  const handlerSwitcher = (e) => {
    setIsChecked(e.target.checked);
    if (isOrdered || isActivated) {
      setIsVacationShutdownOpen(true);
      return;
    }
    if (e.target.checked) {
      setIsVacationConnectionOpen(true);
    }
  };

  /** Дата начала действия услуги */
  const connectionDate = activationDate
    ? format(parseISO(activationDate), 'HH:mm d MMMM yyyy', {
        locale: ru,
      })
    : null;

  /** Дата окончания действия услуги */
  const expirationDate = dateTrim
    ? format(parseISO(dateTrim), 'd MMMM yyyy', {
        locale: ru,
      })
    : null;

  /** Новый договор */
  const isNewContract = contractState === OPERATING_STATE.NEW;

  /** Договор обслуживается */
  const isServicedContract = contractState === OPERATING_STATE.ON;

  /** Приостановлен оператором */
  const isProviderBlockDebtContract =
    contractState === OPERATING_STATE.PROVIDER_BLOCK_DEBT;

  /** Зомби или расторгнут */
  const isStrayContract =
    contractState === OPERATING_STATE.STRAY ||
    contractState === OPERATING_STATE.DREGS;

  /** СП Возобновление обслуживания */
  const [viewType, setViewType] = useState<VIEW_TYPES>(VIEW_TYPES.RESUME);
  const [error, setError] = useState<null | string>(null);
  const [
    isOperatingStateLoading,
    setIsOperatingStateLoading,
  ] = useState<boolean>(false);

  const onCloseClick = async () => {
    setIsOperatingStateWizardShow(false);
    setError(null);
    setViewType(VIEW_TYPES.RESUME);
    if (viewType === VIEW_TYPES.RESUME_SUCCESS) await updateAuthCondition();
  };

  const handleOperatingStateSubmit = async () => {
    setIsOperatingStateLoading(true);
    setError(null);

    try {
      await resumeService();
      await getVacation();
      setViewType(VIEW_TYPES.RESUME_SUCCESS);
    } catch (e) {
      setViewType(VIEW_TYPES.RESUME_ERROR);
      setError(DEFAULT_ERROR);
    } finally {
      setIsOperatingStateLoading(false);
    }
  };

  const getOperatingStateTitle = (): string => {
    return viewType === VIEW_TYPES.RESUME_SUCCESS ||
      viewType === VIEW_TYPES.RESUME_ERROR
      ? null
      : 'Возобновить обслуживание';
  };

  return (
    <StyledVacation
      size={size}
      isDesktop400={isDesktop400}
      isDesktop940={isDesktop940}
      isDesktop1100={isDesktop1100}
      isDesktop1280={isDesktop1280}
      isActivated={isActivated}
    >
      <div className="vacation__block">
        <H3 className="vacation__header" color={defaultTheme.colors.white}>
          {isActivated
            ? 'Договор с\u00A0Планетой на\u00A0каникулах!'
            : 'Собрались в\u00A0отпуск?'}
        </H3>
        {(isNewContract ||
          isEnabledSuspending ||
          isOrderSuspending ||
          isStrayContract) &&
          !isOrdered &&
          defaultText}
        {isProviderBlockDebtContract && negativeBalanceText}
        {isServicedContract && (
          <>
            {isNotEnoughMoney && negativeBalanceText}
            {isAvailable && vacationDisabledText}
            {isOrdered && vacationOrderedText}
          </>
        )}
        {isActivated && vacationEnabledText}
      </div>
      <div className="vacation__block">
        {isNewContract && vacationNewContractText}
        {(isEnabledSuspending || isOrderSuspending) &&
          vacationClientBlockContractText}
        {isStrayContract && vacationStrayText}
        {(isServicedContract ||
          isProviderBlockDebtContract ||
          isActivated ||
          isOrdered) && (
          <>
            {(isNotEnoughMoney || isProviderBlockDebtContract) && (
              <LinkWrapper href="payment">
                <Button
                  className="vacation__button"
                  styleType={ButtonStyleTypes.SECONDARY}
                >
                  {((isDesktop940 && !isDesktop1100) || !isDesktop400) &&
                  size === 1
                    ? 'Пополнить'
                    : 'Пополните баланс'}
                </Button>
              </LinkWrapper>
            )}
            {(isAvailable || isOrdered || isActivated) &&
              !isProviderBlockDebtContract && (
                <Text
                  className="vacation__switcher"
                  color={defaultTheme.colors.white}
                  lineHeight="24px"
                >
                  <Switcher
                    disabled={isOrderSuspending}
                    title={
                      isOrderSuspending
                        ? 'Заказана временная приостановка обслуживания'
                        : null
                    }
                    checked={isChecked}
                    onClick={(e) => handlerSwitcher(e)}
                    activeColor={isOrdered ? defaultTheme.colors.shadow : null}
                  >
                    {isAvailable && `Подключить за ${price} ₽`}
                    {isOrdered && 'Услуга заказана'}
                    {isActivated && 'Услуга подключена'}
                  </Switcher>
                </Text>
              )}
            {(isNotEnoughMoney ||
              isAvailable ||
              isProviderBlockDebtContract) && (
              <>
                {size === 3 && isDesktop940 ? (
                  <Text color={defaultTheme.colors.white} lineHeight="24px">
                    Услуга доступна до&nbsp;{expirationDate}&nbsp;года.
                    <br />
                    Число заказов&nbsp;&mdash; не&nbsp;ограничено!
                  </Text>
                ) : (
                  <Snoska
                    className="vacation__snoska__disabled"
                    color={defaultTheme.colors.white}
                  >
                    Услуга доступна до&nbsp;{expirationDate} года. Число
                    заказов&nbsp;&mdash; не&nbsp;ограничено!
                  </Snoska>
                )}
              </>
            )}
            {isOrdered && (
              <>
                {size === 3 && isDesktop940 ? (
                  <Text color={defaultTheme.colors.white} lineHeight="24px">
                    Услуга Каникулы будет активна
                    <br />
                    с&nbsp;{connectionDate} года
                  </Text>
                ) : (
                  <Snoska color={defaultTheme.colors.white}>
                    Услуга Каникулы будет активна
                    <br />
                    с&nbsp;{connectionDate} года
                  </Snoska>
                )}
              </>
            )}
            {isActivated && (
              <>
                {size === 3 && isDesktop940 ? (
                  <Text
                    className="vacation__text__enabled"
                    color={defaultTheme.colors.white}
                    lineHeight="24px"
                  >
                    Для возобновления обслуживания отключите услугу.
                    Это&nbsp;&mdash; бесплатно.
                  </Text>
                ) : (
                  <Snoska
                    className="vacation__snoska"
                    color={defaultTheme.colors.white}
                  >
                    Для возобновления обслуживания отключите услугу.
                    Это&nbsp;&mdash; бесплатно.
                  </Snoska>
                )}
              </>
            )}
          </>
        )}
      </div>
      <Portal>
        <SidePage
          show={isVacationConnectionOpen}
          headerText={!isResult && 'Подключить услугу Каникулы'}
          onCloseClick={async () => {
            if (isResult && isCorrect) await updateAuthCondition();
            setIsVacationConnectionOpen(false);
            setIsChecked(!!(isResult && isCorrect));
            reset();
          }}
          footerContainer={
            !isResult && (
              <StyledFooter>
                <Button
                  className="vacation__connection-button"
                  onClick={() => setVacationStart(getVacation)}
                  loading={isLoading}
                  disabled={isTemporaryTokenAuth}
                >
                  Подключить
                </Button>
                {isTemporaryTokenAuth && (
                  <Snoska className="snoska" color={defaultTheme.colors.gray}>
                    Действие доступно только клиенту
                  </Snoska>
                )}
              </StyledFooter>
            )
          }
          isOnlyMobileFooter
        >
          <VacationConnection price={price} />
        </SidePage>
        <SidePage
          show={isOperatingStateWizardShow}
          width="832px"
          headerText={getOperatingStateTitle()}
          onCloseClick={onCloseClick}
          footerContainer={
            viewType === VIEW_TYPES.RESUME ? (
              <OperatingStateWizardFooter
                handleSubmit={handleOperatingStateSubmit}
                viewType={viewType}
                isActionLoading={isOperatingStateLoading}
                isTemporaryTokenAuth={isTemporaryTokenAuth}
              />
            ) : (
              <></>
            )
          }
          isOnlyMobileFooter
          removeScrollBar
        >
          <OperatingStateWizard viewType={viewType} error={error} />
        </SidePage>
      </Portal>
    </StyledVacation>
  );
};

export default observer(Vacation);
