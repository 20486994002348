/** утилиты */
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

/** интерфейсы */
import { FeedInformationProps } from './FeedInformation.types';

/** стили */
import { StyledFeedInformation } from './FeedInformation.style';

/** компоненты библиотеки */
import { Text, defaultTheme } from 'cordis-core-ui-planeta';

/** компоненты */
import { Row } from '~/components/Grid/Row';
import { Col } from '~/components/Grid/Col';

const FeedInformation: React.FC<FeedInformationProps> = ({
  publishedDt,
  announcementTag,
}: FeedInformationProps) => {
  const date = format(new Date(publishedDt), 'd MMMM yyyy', { locale: ru });

  return (
    <Row backgroundColor={defaultTheme.colors.white}>
      <Col size={3}>
        <StyledFeedInformation>
          <div className="announcements__tag">
            <Text lineHeight="24px" color={defaultTheme.colors.gray}>
              {announcementTag}
            </Text>
          </div>
          <Text
            lineHeight="24px"
            color={defaultTheme.colors.gray}
            fontWeightBold
          >
            {date}
          </Text>
        </StyledFeedInformation>
      </Col>
    </Row>
  );
};

export default FeedInformation;
