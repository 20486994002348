/** Библиотеки */
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
/** Типы */
import {
  Button,
  ButtonStyleTypes,
  ButtonText,
  Icon,
  Icons,
  Link,
  Text,
  defaultTheme,
  LinkButton,
} from 'cordis-core-ui-planeta';
import { FastLinks } from '~/components/Feed/FeedPage.types';
/** Компоненты */
import {
  StyledContentLayout,
  StyledFastLinks,
  StyledGreeting,
  StyledHumbugger,
} from './style';
import { StyledLeftPanel, StyledRightPanel } from '../style';
import LinkWrapper from '~/components/LinkWrapper';
/** Константы */
import { buttonWidgetTypes } from '~/components/Blocks/Templates/Banner/Banner.types';
/** Утилиты */
import { DEFAULT_LK_LINK } from '~/constants/common';
import { formatNumber, getClientName, getGreetingText } from '~/utils/utils';
/** store */
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
import useCallbackStore from '~/components/CallbackWizard/store/useCallbackStore';
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

/** интерфейс панели быстрых ссылок */
interface ContentLayoutProps {
  toggleHandler: () => void;
  hamburgerOpen: boolean;
  fastLinks: FastLinks;
}

/**
 * Блок меню "панель быстрых ссылок"
 * @returns {React.FC}
 */
const ContentLayout: React.FC<ContentLayoutProps> = ({
  toggleHandler,
  hamburgerOpen,
  fastLinks,
}: ContentLayoutProps) => {
  const { toggleConnectionWizardVisible } = useConnectionStore();
  const { toggleCallbackWizardVisible } = useCallbackStore();
  const {
    authStore: { auth, isAuth, removeAuth, isTemporaryTokenAuth },
  } = useRootStore();
  const { toggleVisible } = useMakeAuthStore();
  /** Обработчик нажатия на ссылку в панели быстрых ссылок */
  const clickFastLinkHandler = (): void => {
    if (hamburgerOpen) toggleHandler();
  };

  /** Список ссылок в панели быстрых ссылок в меню */
  const fastLinksList = isAuth
    ? fastLinks?.authFastLinks
    : fastLinks?.defaultFastLinks;

  const links = fastLinksList?.length
    ? fastLinksList.map((item) => {
        switch (item.widget) {
          case buttonWidgetTypes.ConnectWidget:
            return (
              <Link key={item.name} onClick={toggleConnectionWizardVisible}>
                <ButtonText color={defaultTheme.colors.white}>
                  {item.name}
                </ButtonText>
              </Link>
            );
          case buttonWidgetTypes.CallbackWidget:
            return (
              <Link key={item.name} onClick={toggleCallbackWizardVisible}>
                <ButtonText color={defaultTheme.colors.white}>
                  {item.name}
                </ButtonText>
              </Link>
            );
          default:
            return (
              <LinkWrapper href={item.slug} key={item.name}>
                <ButtonText
                  color={defaultTheme.colors.white}
                  onClick={clickFastLinkHandler}
                >
                  {item.name}
                </ButtonText>
              </LinkWrapper>
            );
        }
      })
    : [];

  /** Формулирует текст приветствия */
  const greeting = (): JSX.Element => (
    <StyledGreeting>
      {!isTemporaryTokenAuth && (
        <LinkWrapper href={DEFAULT_LK_LINK} target="_self">
          <Text
            color={defaultTheme.colors.pink}
          >{`${getGreetingText()}, ${getClientName(auth.clientName)}`}</Text>
        </LinkWrapper>
      )}
      {isTemporaryTokenAuth && (
        <LinkWrapper href={DEFAULT_LK_LINK} target="_self">
          <Text
            title={`${auth.contractName}\n${auth.clientName}`}
            color={defaultTheme.colors.white}
          >
            Вход для сотрудников
          </Text>
        </LinkWrapper>
      )}
      <Text color={defaultTheme.colors.white}>
        {formatNumber(auth.balance, false)}&nbsp;&#8381;
      </Text>
    </StyledGreeting>
  );

  return (
    <StyledContentLayout $isTemporaryTokenAuth={isTemporaryTokenAuth}>
      <div className="layout-wrapper">
        <div className="content">
          <StyledLeftPanel>
            <StyledHumbugger
              className="hamburger"
              role="button"
              onClick={toggleHandler}
              onKeyPress={toggleHandler}
              tabIndex={0}
              isOpened={hamburgerOpen}
              data-test-button=""
            >
              <Icon icon={<Icons.HamburgerIcon />} />
            </StyledHumbugger>
            <StyledFastLinks>{links}</StyledFastLinks>
          </StyledLeftPanel>
          <StyledRightPanel>
            {!isAuth && (
              <Button
                styleType={ButtonStyleTypes.SECONDARY}
                border="1px solid #E04E90"
                background="inherit"
                color="white"
                onClick={() => toggleVisible()}
              >
                Моя Планета
              </Button>
            )}
            {isAuth && greeting()}
            {isAuth && (
              <LinkButton
                color={defaultTheme.colors.pink}
                onClick={() => removeAuth(true)}
                className="logout-icon"
              >
                <Icons.LogoutIcon />
              </LinkButton>
            )}
          </StyledRightPanel>
        </div>
      </div>
    </StyledContentLayout>
  );
};

export default observer(ContentLayout);
