/** constants */
import checkDataFetching, {
  SBP_BANKS_BLOCKS_NOT_AUTH,
} from '~/components/Feed/useBlockDataFetching/config';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVacationStore from '~/components/Blocks/Templates/Vacation/store/useVacationStore';
import useVoiceStore from '~/components/Blocks/Templates/Pab2c/Voice/store/useVoiceStore';
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';

type ResetAfterLogoutProps = Array<() => void | Promise<void>>;

/** Хук сброса состояний, после разавторизации  */
export const useStatesResetAfterLogout = (
  blockTemplates: string[],
): ResetAfterLogoutProps => {
  const { resetVacation } = useVacationStore();
  const { resetConnectionStore } = useConnectionStore();
  const { resetVoice } = useVoiceStore();
  const {
    pab2cBalanceStore: { resetPab2cBalance },
    pab2cDevicesStore: { resetPab2cDevices },
    deferredPaymentStore: { resetDeferredPayment },
    contractStateStore: { resetContractState },
    contractInfoStore: { resetContractInfo },
    productHistoryStore: { resetProductHistory },
    summaryDataStore: { resetSummaryData },
    tvStore: { resetTV },
    allowedTariffStore: { resetAllowedTariff },
    pab2cVoiceStore: { resetPab2cVoice },
    paymentInfoStore: { resetPaymentInfoState },
    autoPaymentInfoStore: { resetAutoPaymentData },
    sbpBanksStore: { sbpBanks, getSbpBanks },
    softlineStore: { resetSoftline },
  } = useRootStore();

  return [
    () =>
      checkDataFetching(
        !sbpBanks.length,
        SBP_BANKS_BLOCKS_NOT_AUTH,
        blockTemplates,
        getSbpBanks,
      ),
    resetVacation,
    resetPab2cBalance,
    resetPab2cDevices,
    resetDeferredPayment,
    resetContractState,
    resetContractInfo,
    resetProductHistory,
    resetConnectionStore,
    resetPab2cVoice,
    resetVoice,
    resetPaymentInfoState,
    resetAutoPaymentData,
    resetAllowedTariff,
    resetTV,
    resetSummaryData,
    resetSoftline,
  ];
};
