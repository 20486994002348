/** libraries */
import React, { FC, useMemo, useState } from 'react';
import {
  H1,
  H3,
  Icons,
  LeadingText,
  SidePage,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { addDays } from 'date-fns';
import { observer } from 'mobx-react';
/** styles */
import { StyledNumberDeactivationSidePage, StyledResult } from './style';
/** constants */
import { DEFAULT_RESULT } from '../../constants';
import { DEFAULT_ERROR } from '../../../Devices/constants';
/** components */
import NumberDeactivationFooter from './NumberDeactivationFooter';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { maskHomePhone } from '../../utils';
import { dateString } from '../../../Contract/utils';
/** api */
import { phoneUnbind } from '~/api/apiPab2c';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVoiceStore from '../../store/useVoiceStore';

const NumberDeactivationSidePage: FC = () => {
  const {
    pab2cVoiceStore: { getPhones },
  } = useRootStore();
  const {
    result,
    setResult,
    phonePassword: phone,
    isShowNumberDeactivation,
    setIsShowNumberDeactivation,
    sim,
    agreement,
  } = useVoiceStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const zeroing = () => {
    setIsShowNumberDeactivation(false);
    setResult(DEFAULT_RESULT);
  };

  const unbindDate = useMemo(() => {
    return dateString(addDays(new Date(), 1), 'dd MMMM');
  }, []);

  const resultJSX = () => {
    if (!result.isResult) return <></>;
    if (result.isCorrect) {
      return (
        <StyledResult>
          <Icons.OkBigIcon />
          <H1>Номер {maskHomePhone(phone)} будет отключён</H1>
          <div className="result__time">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Отключение произойдет
            </Text>
            <H3>{unbindDate} в 00:00</H3>
          </div>
        </StyledResult>
      );
    }
    return (
      <StyledResult>
        <Icons.NotOkBigIcon />
        <H1>{result.text ?? DEFAULT_ERROR}</H1>
      </StyledResult>
    );
  };

  const content = () => {
    if (!result.isResult) {
      return (
        <>
          <div className="number__info-block">
            <div>
              <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                Отключаемый номер
              </Text>
              <LeadingText color={defaultTheme.colors.black}>
                {maskHomePhone(phone)}
              </LeadingText>
            </div>
            <div>
              <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                Будет отключён
              </Text>
              <LeadingText color={defaultTheme.colors.black}>
                {unbindDate} в 00:00
              </LeadingText>
            </div>
          </div>
          <div className="number__yellow-block">
            <Text lineHeight="24px">
              До конца текущих суток вы можете отозвать одну заявку на
              отключение номера
            </Text>
          </div>
          <div className="number__agreement">
            <Text lineHeight="24px" fontWeightBold>
              Согласие на отключение номера
            </Text>
            <Text lineHeight="24px">{parseHtml(agreement)}</Text>
          </div>
        </>
      );
    }
    return resultJSX();
  };

  const disable = async () => {
    setIsLoading(true);
    try {
      await phoneUnbind(sim);
      getPhones();
      setResult({
        isResult: true,
        isCorrect: true,
      });
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <StyledNumberDeactivationSidePage>
      <SidePage
        show={isShowNumberDeactivation}
        width="832px"
        headerText={!result.isResult && 'Отключить номер'}
        onCloseClick={zeroing}
        footerContainer={
          !result.isResult && (
            <NumberDeactivationFooter disable={disable} isLoading={isLoading} />
          )
        }
        removeScrollBar
      >
        {content()}
      </SidePage>
    </StyledNumberDeactivationSidePage>
  );
};

export default observer(NumberDeactivationSidePage);
