/** libraries */
import { useEffect, useState, FC } from 'react';
import {
  Checkbox,
  LeadingText,
  Select,
  Switcher,
  Text,
  defaultTheme,
  Snoska,
  Button,
  ButtonStyleTypes,
  Loader,
  Icons,
  Icon,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledCommonError, StyledContactsAndNotifications } from './styles';
/** constants */
import {
  ADD_CONTACT_SP_STATE,
  CONTACT_TYPES,
  HOURS,
} from '../../Templates/Pab2c/Settings/constants';
import { desktop940 } from '~/components/Grid/constants';
/** interfaces */
import { Contacts, StateMutePeriod } from './interfaces';
import { SelectProps } from '../../Templates/Pab2c/Settings/interfaces';
/** utils */
import { outsideClickHelper } from '~/utils/outsideClickHelper';
import { maskPhone } from '~/utils/utils';
/** api */
import {
  getMutePeriodInfo,
  notificationSubscription,
  sendVerificationMessage,
  setMutePeriod,
} from '~/api/apiPab2c';
/** components */
import Portal from '~/components/Portal/Portal';
import DeletingContactSidePage from './Components/DeletingContact/DeletingContactSidePage';
import AddingContactSidePage from './Components/AddingContact/AddingContactSidePage';
/** stores */
import { useRootStore } from '~/stores/RootStore';
/** stores */
import useContactsAndNotificationsStore from './store/useContactsAndNotificationsStore';

const ContactsAndNotifications: FC = () => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();

  const {
    contacts,
    contactTypes,
    subscriptions,
    isLoading,
    deletingContactStore: { deleteContact },
    addingContactStore: {
      setIsShowAddingContact,
      addContactState,
      setAddContactState,
      setContactData,
      setContactType,
      setConfirmContact,
    },
  } = useContactsAndNotificationsStore();

  /** switcher "Тихий час" */
  const [isQuietHour, setIsQuietHour] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const onChangeQuietHour = (e) => {
    changeMutePeriod(
      beginningOfQuietHour,
      endOfQuietHour,
      isNotifyAboutReplenishment,
      e.target.checked,
    );
  };

  const initialStateMutePeriod = {
    isLoading: false,
    isCorrect: false,
    isBad: false,
  };

  const loadingStateMutePeriod = {
    isLoading: true,
    isCorrect: false,
    isBad: false,
  };

  /** Изменение тихого часа */
  const [
    changeStateMutePeriod,
    setChangeStateMutePeriod,
  ] = useState<StateMutePeriod>(initialStateMutePeriod);

  /** Флаг-состояние видимости уведомлений */
  const [
    isNotificationVisibility,
    setIsNotificationVisibility,
  ] = useState<boolean>(false);

  useEffect(() => {
    let timerId = null;
    if (
      changeStateMutePeriod !== initialStateMutePeriod &&
      changeStateMutePeriod !== loadingStateMutePeriod &&
      isNotificationVisibility
    ) {
      timerId = setTimeout(() => {
        setIsNotificationVisibility(false);
        setChangeStateMutePeriod(initialStateMutePeriod);
      }, 3000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [changeStateMutePeriod]);

  /** Изменение настроек тихого часа */
  const changeMutePeriod = async (
    hourFrom: SelectProps,
    hourTo: SelectProps,
    isImportantEnabled: boolean,
    isActive: boolean,
  ) => {
    setChangeStateMutePeriod(loadingStateMutePeriod);
    setIsNotificationVisibility(true);
    try {
      await setMutePeriod(
        Number(hourFrom.value.slice(0, -3)),
        Number(hourTo.value.slice(0, -3)),
        isImportantEnabled,
        isActive,
      );
      setIsQuietHour(isActive);
      setIsNotifyAboutReplenishment(isImportantEnabled);
      setBeginningOfQuietHour(hourFrom);
      setEndOfQuietHour(hourTo);
      setChangeStateMutePeriod({
        isLoading: false,
        isCorrect: true,
        isBad: false,
      });
    } catch (e) {
      setChangeStateMutePeriod({
        isLoading: false,
        isCorrect: false,
        isBad: true,
      });
    }
  };

  /** Select начала тихого часа */
  const [beginningOfQuietHour, setBeginningOfQuietHour] = useState<SelectProps>(
    null,
  );
  const onChangeBeginningOfQuietHour = (option) => {
    if (option.value === endOfQuietHour.value) {
      setIsNotificationVisibility(true);
      setChangeStateMutePeriod({
        isLoading: false,
        isCorrect: false,
        isBad: true,
        error: 'Начало тихого часа не должно совпадать с завершением',
      });
      return;
    }
    changeMutePeriod(
      option,
      endOfQuietHour,
      isNotifyAboutReplenishment,
      isQuietHour,
    );
  };
  /** Select завершения тихого часа */
  const [endOfQuietHour, setEndOfQuietHour] = useState<SelectProps>(null);
  const onChangeEndOfQuietHour = (option) => {
    if (option.value === beginningOfQuietHour.value) {
      setIsNotificationVisibility(true);
      setChangeStateMutePeriod({
        isLoading: false,
        isCorrect: false,
        isBad: true,
        error: 'Начало тихого часа не должно совпадать с завершением',
      });
      return;
    }
    changeMutePeriod(
      beginningOfQuietHour,
      option,
      isNotifyAboutReplenishment,
      isQuietHour,
    );
  };

  /** Checkbox извещать о пополнении лицевого счета в любое время */
  const [
    isNotifyAboutReplenishment,
    setIsNotifyAboutReplenishment,
  ] = useState<boolean>(false);

  const onChangeNotifyAboutReplenishment = (e) => {
    changeMutePeriod(
      beginningOfQuietHour,
      endOfQuietHour,
      e.target.checked,
      isQuietHour,
    );
  };

  const [
    isLoadingContactAndNotification,
    setIsLoadingContactAndNotification,
  ] = useState<boolean>(true);

  /** Получает информацию по тихому часу */
  const getMutePeriod = async () => {
    try {
      const res = await getMutePeriodInfo();
      setIsQuietHour(res.isMutePeriodEnabled);
      setIsNotifyAboutReplenishment(res.isImportantDeliveryEnabled);
      setBeginningOfQuietHour({
        label: res.mutePeriodStartTime.slice(0, -3),
        value: res.mutePeriodStartTime.slice(0, -3),
      });
      setEndOfQuietHour({
        label: res.mutePeriodTrimTime.slice(0, -3),
        value: res.mutePeriodTrimTime.slice(0, -3),
      });
    } catch (e) {
      setHasError(true);
      console.error('getMutePeriodInfo', e);
    }
  };

  useEffect(() => {
    (async () => {
      await getMutePeriod();
      setIsLoadingContactAndNotification(false);
    })();
  }, []);

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Добавить контакт */
  const addingContact = () => {
    setIsShowAddingContact(true);
  };

  /** Выслать код */
  const sendingCode = async (data: Contacts) => {
    try {
      if (addContactState === ADD_CONTACT_SP_STATE.CODE) {
        await sendVerificationMessage(data.id);
      }
      setContactData(data);
      setIsShowAddingContact(true);
      setAddContactState(ADD_CONTACT_SP_STATE.CODE);
    } catch (e) {
      setIsShowAddingContact(true);
      setAddContactState(ADD_CONTACT_SP_STATE.ERROR);
    }
  };

  /** Подтверждение */
  const confirm = (data: Contacts) => {
    const type = contactTypes.find(
      (item) => item.id === data.contactValueClassId,
    );
    setContactType(type);
    if (type.code === CONTACT_TYPES.PHONE) {
      sendingCode(data);
      return;
    }
    setConfirmContact(data);
    setAddContactState(ADD_CONTACT_SP_STATE.RESULT);
    setIsShowAddingContact(true);
  };

  /** Подписка контакта на уведомления */
  const subscribeToNotifications = async (data: Contacts, event) => {
    try {
      await notificationSubscription(1, data.id, event.target.checked);
    } catch (e) {
      console.error('subscribeToNotificationsError', e);
    }
  };
  const isSubscribed = (contact: Contacts) => {
    return subscriptions.some((sub) => sub.contactId === contact.id);
  };

  if (hasError)
    return (
      <StyledCommonError>
        <Icon icon={<Icons.NotOkBigIcon />} />
        <Text>Что-то пошло не так, повторите попытку позже...</Text>
      </StyledCommonError>
    );

  /** Ограничение на удаление последнего подтвержденного контакта одого типа */
  const canDeleteContact = (contact: Contacts) => {
    if (!contact.isVerified) return true;
    return (
      contacts.filter(
        (item) =>
          item.contactValueClassId === contact.contactValueClassId &&
          item.isVerified,
      ).length > 1
    );
  };

  const emailClassId = contactTypes.find(
    (type) => type.code === CONTACT_TYPES.EMAIL,
  )?.id;
  const phoneClassId = contactTypes.find(
    (type) => type.code === CONTACT_TYPES.PHONE,
  )?.id;

  return (
    <StyledContactsAndNotifications>
      {isLoadingContactAndNotification || isLoading ? (
        <div className="contacts-notifications__loader">
          <Loader />
        </div>
      ) : (
        <>
          <div className="contacts-notifications__quiet-hour">
            <div className="contacts-notifications__quiet-hour__header">
              {isDesktop940 ? (
                <LeadingText color={defaultTheme.colors.black}>
                  Настройка тихого часа
                </LeadingText>
              ) : (
                <Text lineHeight="24px">Настройка тихого часа</Text>
              )}
              {changeStateMutePeriod.isLoading && <Loader small />}
              {changeStateMutePeriod.isCorrect && (
                <div className="contacts-notifications__quiet-hour__header__result">
                  <Icons.OkGreenIcon />
                  <Text lineHeight="24px" color={defaultTheme.colors.green}>
                    Изменения применены
                  </Text>
                </div>
              )}
              {changeStateMutePeriod.isBad && (
                <div className="contacts-notifications__quiet-hour__header__result">
                  <Icons.ErrorIcon />
                  <Text lineHeight="24px" color={defaultTheme.colors.down}>
                    {changeStateMutePeriod?.error
                      ? changeStateMutePeriod.error
                      : 'Что-то пошло не так…'}
                  </Text>
                </div>
              )}
            </div>
            <div className="contacts-notifications__quiet-hour__switcher">
              <Switcher
                checked={isQuietHour}
                onClick={(e) => onChangeQuietHour(e)}
                disabled={isTemporaryTokenAuth}
              >
                <Text lineHeight="24px">
                  Включить тихий час для уведомлений
                </Text>
              </Switcher>
            </div>
            <div className="contacts-notifications__quiet-hour__time-interval">
              <div className="contacts-notifications__quiet-hour__time-interval__select">
                <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                  Начало тихого часа
                </Text>
                <Select
                  placeholder="Выберите начало тихого часа"
                  onOptionClick={(option) =>
                    onChangeBeginningOfQuietHour(option)
                  }
                  visibleOptionCount={4}
                  value={beginningOfQuietHour ? beginningOfQuietHour.value : ''}
                  outsideClickHelper={outsideClickHelper}
                  data={HOURS}
                  disabled={!isQuietHour || isTemporaryTokenAuth}
                />
              </div>
              <div className="contacts-notifications__quiet-hour__time-interval__select">
                <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                  Завершение
                </Text>
                <Select
                  placeholder="Выберите завершение"
                  onOptionClick={(option) => onChangeEndOfQuietHour(option)}
                  visibleOptionCount={4}
                  value={endOfQuietHour ? endOfQuietHour.value : ''}
                  outsideClickHelper={outsideClickHelper}
                  data={HOURS}
                  disabled={!isQuietHour || isTemporaryTokenAuth}
                />
              </div>
            </div>
            <Checkbox
              checked={isNotifyAboutReplenishment}
              onChange={(e) => onChangeNotifyAboutReplenishment(e)}
              disabled={!isQuietHour || isTemporaryTokenAuth}
            >
              Извещать о пополнении лицевого счета в любое время
            </Checkbox>
          </div>
          <div className="contacts-notifications__contacts">
            {isDesktop940 ? (
              <LeadingText color={defaultTheme.colors.black}>
                Контакты
              </LeadingText>
            ) : (
              <Text lineHeight="24px">Контакты</Text>
            )}
            {!isDesktop940 && (
              <Button
                className="contacts-notifications__contacts__add-button-mobile"
                styleType={ButtonStyleTypes.SECONDARY}
                onClick={addingContact}
              >
                Добавить контакт
              </Button>
            )}
            {contacts?.length > 0 && (
              <div className="contacts-notifications__contacts__table">
                {contacts.map((item) => {
                  const domain = item.value.split('@').pop();
                  return (
                    <div
                      key={item.id}
                      className="contacts-notifications__contacts__table__line"
                    >
                      <div className="contacts-notifications__contacts__table__line__value">
                        <Text
                          className={`${
                            item.contactValueClassId === emailClassId &&
                            'contacts-notifications__contacts__table__line__contact'
                          }`}
                          lineHeight="24px"
                          color={
                            !item.isVerified
                              ? defaultTheme.colors.disable
                              : defaultTheme.colors.black
                          }
                          title={item.value}
                        >
                          {item.contactValueClassId === emailClassId
                            ? item.value.split('@')[0]
                            : maskPhone(item.value)}
                        </Text>
                        {item.contactValueClassId === emailClassId && (
                          <Text
                            lineHeight="24px"
                            color={
                              !item.isVerified
                                ? defaultTheme.colors.disable
                                : defaultTheme.colors.black
                            }
                          >
                            @{domain}
                          </Text>
                        )}
                      </div>
                      {item.isVerified &&
                        [emailClassId, phoneClassId].includes(
                          item.contactValueClassId,
                        ) && (
                          <div className="contacts-notifications__contacts__table__line__switcher">
                            <Switcher
                              checked={isSubscribed(item)}
                              isSmallWithCheckMark
                              onClick={(e) => subscribeToNotifications(item, e)}
                              disabled={isTemporaryTokenAuth}
                            >
                              <Snoska
                                color={defaultTheme.colors.shadow}
                                lineHeight="16px"
                              >
                                Уведомления
                              </Snoska>
                            </Switcher>
                          </div>
                        )}
                      {!item.isVerified && (
                        <>
                          {item.contactValueClassId === emailClassId && (
                            <Snoska
                              className="contacts-notifications__contacts__table__line__confirm cursor-default"
                              color={defaultTheme.colors.shadow}
                            >
                              Не подтверждена
                            </Snoska>
                          )}
                          {item.contactValueClassId === phoneClassId && (
                            <Snoska
                              className="contacts-notifications__contacts__table__line__confirm"
                              color={defaultTheme.colors.planeta}
                              onClick={() => confirm(item)}
                            >
                              Подтвердить
                            </Snoska>
                          )}
                        </>
                      )}
                      {canDeleteContact(item) && (
                        <Snoska
                          className="contacts-notifications__contacts__table__line__delete"
                          color={defaultTheme.colors.planeta}
                          onClick={() => deleteContact(item)}
                        >
                          Удалить
                        </Snoska>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {isDesktop940 && (
              <Button
                className="contacts-notifications__contacts__add-button-desktop"
                styleType={ButtonStyleTypes.SECONDARY}
                onClick={addingContact}
              >
                Добавить контакт
              </Button>
            )}
          </div>
        </>
      )}
      <Portal>
        <DeletingContactSidePage />
        <AddingContactSidePage />
      </Portal>
    </StyledContactsAndNotifications>
  );
};

export default observer(ContactsAndNotifications);
