/** libraries */
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import Image from 'next/image';
import {
  Icon,
  Icons,
  OptionProp,
  Select,
  Text,
  defaultTheme,
  Loader,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledCardAndSbpPaymentSelect } from './styles';
/**  constants */
import { desktop940, desktop1280 } from '~/components/Grid/constants';
import { ERROR_MESSAGES, NEW_CARD } from '../../Templates/Payment/constants';
import { NEW_ACCOUNT } from '../../Templates/PaymentResult/constants';
/** api */
import { unbindPaymentCard } from '~/api/api';
/** interfaces */
import { BanksProps } from '../../Templates/Payment/types';
import { AccountProps } from './interfaces';
/* utils */
import { chooseIconForSelect } from '~/components/Blocks/Shared/Shared.utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface CardAndSbpPaymentSelectProps {
  className?: string;
  setSelectedAccount: Dispatch<
    SetStateAction<(OptionProp & AccountProps) | null>
  >;
  convertedSbpBanksList: BanksProps[];
  isSbp?: boolean;
  isBankCard?: boolean;
  setError?: Dispatch<SetStateAction<string>>;
}

const CardAndSbpPaymentSelect: FC<CardAndSbpPaymentSelectProps> = ({
  className,
  setSelectedAccount,
  convertedSbpBanksList,
  isSbp,
  isBankCard,
  setError,
}: CardAndSbpPaymentSelectProps) => {
  const {
    paymentInfoStore: {
      paymentCards,
      getPaymentCards,
      sbpBindings,
      getSbpBindings,
      isCardsError,
      isSbpError,
      isLoading,
    },
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();

  // Выбранный счёт в select
  const [accountSelect, setAccountSelect] = useState<
    (OptionProp & AccountProps) | null
  >(null);
  // Привязанные счета
  const [accountsData, setAccountsData] = useState<
    (OptionProp & AccountProps)[]
  >([]);
  /** id отвязываемого счёта */
  const [untieAccountId, setUntieAccountId] = useState<number>(null);
  /** Ошибка */
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showLoader, setShowLoader] = useState<number>(null);

  const selectRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  // Вычисление ширины экрана
  const isMinDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isMinDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  // Отвязывает и удаляет счёт
  const onDeleteAccountClick = async (
    id: number,
    onError: () => void,
    isCard?: boolean,
  ) => {
    setErrorMessage('');
    if (isTemporaryTokenAuth) {
      setError(ERROR_MESSAGES.NOT_AVAILABLE_TO_EMPLOYEE);
      return;
    }
    try {
      setShowLoader(id);
      const unbind = await unbindPaymentCard(id);
      if (unbind === 200) {
        if (isCard) {
          getPaymentCards();
        } else {
          getSbpBindings();
        }
      }
      setUntieAccountId(null);
      setShowLoader(null);
    } catch (error) {
      setUntieAccountId(null);
      setShowLoader(null);
      onError();
      if (typeof setError === 'function') {
        setError(
          isCard
            ? ERROR_MESSAGES.ERROR_UNBIND_CARD
            : ERROR_MESSAGES.ERROR_UNBIND_SBP,
        );
        return;
      }
      setErrorMessage(
        isCard
          ? ERROR_MESSAGES.ERROR_UNBIND_CARD
          : ERROR_MESSAGES.ERROR_UNBIND_SBP,
      );
    }
  };

  /** Обработка ошибки загрузки привязанных карт/счетов СБП */
  useEffect(() => {
    if (isCardsError) setErrorMessage(ERROR_MESSAGES.ERROR_LINKED_CARD);
    if (isSbpError) setErrorMessage(ERROR_MESSAGES.ERROR_LINKED_ACCOUNTS);
  }, [isCardsError, isSbpError]);

  /** Добавление иконок к счетам */
  const sbpAccounts = useMemo(() => {
    if (!convertedSbpBanksList) return [];
    return sbpBindings.map((item) => {
      const bankInfo = convertedSbpBanksList.find(
        (bank) => bank.bankName.toLowerCase() === item.bankName.toLowerCase(),
      );
      return {
        ...item,
        ...bankInfo,
      };
    });
  }, [sbpBindings, convertedSbpBanksList]);

  const forceSelectClosing = () => {
    if (!selectRef?.current) return;
    selectRef.current.click();
    setUntieAccountId(null);
  };

  const getRightIcon = (item, isCard?: boolean) => {
    if (showLoader === item.id && untieAccountId) return <Loader small />;
    if (untieAccountId === item.id)
      return (
        <>
          <Icon
            icon={<Icons.OkIcon />}
            onClick={(e) => {
              onDeleteAccountClick(untieAccountId, forceSelectClosing, isCard);
              e.stopPropagation();
            }}
          />
          <Icon
            icon={<Icons.CancelPlanetaIcon />}
            onClick={(e) => {
              setUntieAccountId(null);
              e.stopPropagation();
            }}
          />
        </>
      );

    return (
      <Icon
        icon={<Icons.DeleteIcon />}
        onClick={(e) => {
          setUntieAccountId(item.id);
          e.stopPropagation();
        }}
      />
    );
  };

  // Заполняет привязанные счета в select
  const cardDataInfo = async () => {
    const data: (OptionProp & AccountProps)[] = [];

    if (!isBankCard) {
      sbpAccounts.forEach((item) => {
        data.push({
          label:
            untieAccountId === item.id
              ? `Отвязать СБП${'\u000A'}${item.bankName}?`
              : item.bankName,
          value: item.id,
          leftIcon: item.logo ? (
            <div>
              <Image
                src={`data:image/png;base64, ${item.logo}`}
                alt="logo"
                width={item.width}
                height={item.height}
                quality={100}
              />
            </div>
          ) : (
            <Icons.SbpIcon />
          ),
          rightIcon: getRightIcon(item),
          isCard: false,
        });
      });
    }

    const expiredText = isMinDesktop940
      ? 'Срок действия карты истёк'
      : 'Срок действия истёк';

    if (!isSbp) {
      paymentCards.forEach((item) => {
        // const expired = isAfter(now, new Date(item.expirationDt));
        // https://ctms.itmh.ru/browse/DEPIT3-2855
        // @todo: вернуть логику когда всё в мире нормализуется
        const expired = false;
        data.push({
          label:
            isMinDesktop1280 && untieAccountId !== item.id
              ? `•••• •••• •••• ${item.maskedPan.substring(12)}`
              : `${
                  untieAccountId === item.id ? 'Отвязать карту ••' : ''
                }•• ${item.maskedPan.substring(12)}${
                  untieAccountId === item.id ? '?' : ''
                }`,
          value: item.id,
          leftIcon: chooseIconForSelect(item.paymentSystem),
          rightIcon: getRightIcon(item, true),
          bottomText: expired ? expiredText : null,
          className: expired ? 'expired' : '',
          isCard: true,
        });
      });
    }

    if (!isBankCard) {
      data.push({
        label: NEW_ACCOUNT,
        value: 1,
        isCard: false,
      });
    }

    if (!isSbp) {
      data.push({
        label: NEW_CARD,
        value: 2,
        isCard: true,
      });
    }

    if (!accountSelect) {
      setAccountSelect(data[0]);
    }
    if (isLoading)
      setAccountSelect(data.find((item) => item.label === NEW_ACCOUNT));
    setAccountsData(data);
  };

  useEffect(() => {
    if (
      ((sbpBindings.length && sbpAccounts.length) || !sbpBindings.length) &&
      !isLoading
    )
      cardDataInfo();
  }, [paymentCards, sbpAccounts, isLoading, untieAccountId]);

  useEffect(() => {
    // todo: опять дублирование состояния. Переделать в рамках рефакторинга
    setSelectedAccount(accountSelect);
    setErrorMessage(null);
    if (typeof setError === 'function') setError(null);
  }, [accountSelect]);

  return (
    <StyledCardAndSbpPaymentSelect ref={selectRef} className={className}>
      <Select
        className={className}
        placeholder={isSbp ? 'Загрузка счетов...' : 'Выберите карту'}
        onOptionClick={(option) => {
          if (option.value !== untieAccountId)
            setAccountSelect(option as OptionProp & AccountProps);
        }}
        visibleOptionCount={4}
        value={accountSelect ? accountSelect.value : ''}
        data={accountsData}
        loading={isLoading}
        disabled={isLoading}
      />
      {errorMessage && (
        <Text
          className="error"
          lineHeight="24px"
          color={defaultTheme.colors.planeta}
        >
          {errorMessage}
        </Text>
      )}
    </StyledCardAndSbpPaymentSelect>
  );
};

export default observer(CardAndSbpPaymentSelect);
