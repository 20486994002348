/** libraries */
import { FC, memo, useState } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
/** components */
import OperatingStateWizardFooter from '~/components/Blocks/Templates/Pab2c/ContractState/Components/OperatingStateWizard/OperatingStateWizardFooter';
import OperatingStateWizard from '~/components/Blocks/Templates/Pab2c/ContractState/Components/OperatingStateWizard/OperatingStateWizard';
import { Loader, SidePage } from 'cordis-core-ui-planeta';
import Portal from '~/components/Portal/Portal';
/** api */
import { getSuspendingAgreement, suspendService } from '~/api/apiPab2c';
/** constants */
import { VIEW_TYPES } from '~/components/Blocks/Templates/Pab2c/ContractState/types';
import { DEFAULT_ERROR } from '~/constants/common';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVacationStore from '../../../Vacation/store/useVacationStore';
/** styles */
import { StyledLink } from '../style';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Serviced: FC = () => {
  const {
    contractStateStore: { updateAuthCondition },
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();

  const { getVacation } = useVacationStore();

  const [
    isOperatingStateWizardShow,
    setIsOperatingStateWizardShow,
  ] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [viewType, setViewType] = useState<VIEW_TYPES>(VIEW_TYPES.PAUSE);
  const [
    isOperatingStateLoading,
    setIsOperatingStateLoading,
  ] = useState<boolean>(false);
  const [isLoadingSupension, setIsLoadingSuspension] = useState<boolean>(false);
  const [supensionAgreement, setSupensionAgreement] = useState<string>('');
  /** Ошибка проверки приостановки обслуживания */
  const [errorSuspendService, setErrorSuspendService] = useState<string>(null);

  const handleOperationStateWizardClick = async () => {
    setIsLoadingSuspension(true);
    try {
      const res = await getSuspendingAgreement();
      if (res) {
        setSupensionAgreement(res);
        setIsOperatingStateWizardShow(true);
      }
      setIsLoadingSuspension(false);
    } catch (e) {
      setErrorSuspendService(DEFAULT_ERROR);
      setIsOperatingStateWizardShow(true);
      setIsLoadingSuspension(false);
    }
  };

  const handleOperatingStateSubmit = async () => {
    setIsOperatingStateLoading(true);
    setError(null);

    try {
      await suspendService();
      await getVacation();
      setViewType(VIEW_TYPES.PAUSE_SUCCESS);
    } catch (e) {
      setViewType(VIEW_TYPES.PAUSE_ERROR);
      setError(DEFAULT_ERROR);
    } finally {
      setIsOperatingStateLoading(false);
    }
  };

  const getOperatingStateTitle = (): string => {
    return viewType === VIEW_TYPES.PAUSE_SUCCESS ||
      errorSuspendService ||
      viewType === VIEW_TYPES.PAUSE_ERROR
      ? null
      : 'Приостановить обслуживание';
  };

  const onCloseClick = async () => {
    setIsOperatingStateWizardShow(false);
    setError(null);
    setViewType(VIEW_TYPES.PAUSE);
    setErrorSuspendService('');
    if (viewType === VIEW_TYPES.PAUSE_SUCCESS) await updateAuthCondition();
  };

  return (
    <>
      <Root>
        {isLoadingSupension ? (
          <Loader small />
        ) : (
          <StyledLink onClick={handleOperationStateWizardClick}>
            Приостановить обслуживание
          </StyledLink>
        )}
      </Root>
      <Portal>
        <SidePage
          show={isOperatingStateWizardShow}
          width="832px"
          headerText={getOperatingStateTitle()}
          onCloseClick={onCloseClick}
          footerContainer={
            viewType === VIEW_TYPES.PAUSE && !errorSuspendService ? (
              <OperatingStateWizardFooter
                handleSubmit={handleOperatingStateSubmit}
                viewType={viewType}
                isActionLoading={isOperatingStateLoading}
                isTemporaryTokenAuth={isTemporaryTokenAuth}
              />
            ) : (
              <></>
            )
          }
          isOnlyMobileFooter
          removeScrollBar
        >
          <OperatingStateWizard
            viewType={viewType}
            error={error}
            agreement={supensionAgreement}
            errorSuspendService={errorSuspendService}
          />
        </SidePage>
      </Portal>
    </>
  );
};

export default memo(observer(Serviced));
