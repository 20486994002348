// state result по умолчанию
export const DEFAULT_RESULT = {
  isResult: false,
  isCorrect: true,
};

export const initialPhoneNumber = {
  forSend: '',
  value: '',
};

export const DEFAULT_ERROR = 'Что-то пошло не так. Повторите попытку позже.';
export const CONTACT_ERROR =
  'Не удалось добавить контакт. Попробуйте повторить позже, если ошибка будет повторяться, обратитесь в поддержку.';

export enum ADD_CONTACT_SP_STATE {
  CONTACT = 'CONTACT',
  CODE = 'CODE',
  RESULT = 'RESULT',
  ERROR = 'ERROR',
}

export enum CONTACT_TYPES {
  EMAIL = 'email',
  PHONE = 'phonecellular',
  PHONE_CITY = 'phonecity',
}

export const HOURS = [];
for (let i = 0; i < 24; i++) {
  const hour = {
    label: `${i.toString().padStart(2, '0')}:00`,
    value: `${i.toString().padStart(2, '0')}:00`,
  };
  HOURS.push(hour);
}

export enum SP_STATE {
  PHONE = 'PHONE',
  CODE = 'CODE',
  NEW_PASSWORD = 'NEW_PASSWORD',
  BLOCK = 'BLOCK',
}

export enum RESPONSE {
  Success = 'Success',
  Fail = 'Fail',
}

export enum EMAIL_CONFIRM_QS {
  CONTACT_ID = 'contact_id',
  CODE = 'code',
}

// Время до повторной отправки SMS-кода
export const timeLeftToSendCode = 30;
