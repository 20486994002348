/** libraries */
import { useState, useMemo } from 'react';
import { Button, SidePage, Text } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledVoluntarySuspension } from './styles';
/** constants */
import { desktop500 } from '~/components/Grid/constants';
import { VIEW_TYPES } from '~/components/Blocks/Templates/Pab2c/ContractState/Components/OperatingStateWizard/constants';
import { DEFAULT_ERROR } from '~/constants/common';
/** utils */
import Portal from '~/components/Portal/Portal';
/** components */
import OperatingStateWizard from '~/components/Blocks/Templates/Pab2c/ContractState/Components/OperatingStateWizard/OperatingStateWizard';
import OperatingStateWizardFooter from '~/components/Blocks/Templates/Pab2c/ContractState/Components/OperatingStateWizard/OperatingStateWizardFooter';
import SuspensionPaymentsList from '../SuspensionPaymentsList/SuspensionPaymentsList';
/** api */
import { suspendService, resumeService } from '~/api/apiPab2c';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVacationStore from '~/components/Blocks/Templates/Vacation/store/useVacationStore';
/** interfaces */
import { AllowedTariffProps } from '~/components/Blocks/Templates/ProductSwitcher/interfaces';

/** Добровольная приостановка */
const VoluntarySuspension = (): JSX.Element => {
  const {
    authStore: { isTemporaryTokenAuth },
    contractStateStore: { getOperatingState, updateAuthCondition },
    summaryDataStore: { seriesCode },
    allowedTariffStore: { allowedTariff },
  } = useRootStore();

  const { getVacation } = useVacationStore();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });
  /** Флаг отображения сайдпейджа о приостановке/возобновлении обслуживания */
  const [
    isOperatingStateWizardShow,
    setIsOperatingStateWizardShow,
  ] = useState<boolean>(false);
  const [viewType, setViewType] = useState<VIEW_TYPES>(VIEW_TYPES.RESUME);
  const [error, setError] = useState<null | string>(null);
  const [
    isOperatingStateLoading,
    setIsOperatingStateLoading,
  ] = useState<boolean>(false);

  const getOperatingStateTitle = (): string => {
    let title =
      viewType === VIEW_TYPES.PAUSE
        ? 'Приостановить обслуживание'
        : 'Возобновить обслуживание';
    if (viewType !== VIEW_TYPES.PAUSE && viewType !== VIEW_TYPES.RESUME)
      title = null;

    return title;
  };

  const handleOperatingStateSubmit = async () => {
    setIsOperatingStateLoading(true);
    setError(null);
    try {
      if (viewType === VIEW_TYPES.PAUSE) {
        // todo: лишний код, это уведомление о возобновлении обслуживания
        await suspendService();
      } else {
        await resumeService();
      }
      await getVacation();
      setViewType(
        viewType === VIEW_TYPES.PAUSE
          ? VIEW_TYPES.PAUSE_SUCCESS
          : VIEW_TYPES.RESUME_SUCCESS,
      );
      getOperatingState();
    } catch (e) {
      setViewType(
        viewType === VIEW_TYPES.PAUSE
          ? VIEW_TYPES.PAUSE_ERROR
          : VIEW_TYPES.RESUME_ERROR,
      );
      setError(DEFAULT_ERROR);
    } finally {
      updateAuthCondition();
      setIsOperatingStateLoading(false);
    }
  };

  const tariff = useMemo(() => {
    return allowedTariff?.length
      ? ((allowedTariff as unknown) as AllowedTariffProps[]).find(
          (item) => item.tariffTo.seriesCode === seriesCode,
        )
      : null;
  }, [allowedTariff, seriesCode]);

  return (
    <StyledVoluntarySuspension>
      <div className="button-block">
        <Button
          onClick={() => setIsOperatingStateWizardShow(true)}
        >{`Восстановить ${isDesktop ? 'обслуживание' : ''}`}</Button>
        <Text lineHeight="24px">Восстановите доступ к услугам</Text>
      </div>
      {tariff && (
        <SuspensionPaymentsList linePriceOff={tariff.tariffTo.linePriceOff} />
      )}
      <Portal>
        <SidePage
          show={isOperatingStateWizardShow}
          width="832px"
          headerText={getOperatingStateTitle()}
          onCloseClick={() => {
            setError(null);
            setViewType(VIEW_TYPES.RESUME);
            setIsOperatingStateWizardShow(false);
          }}
          footerContainer={
            (viewType === VIEW_TYPES.PAUSE ||
              viewType === VIEW_TYPES.RESUME) && (
              <OperatingStateWizardFooter
                handleSubmit={handleOperatingStateSubmit}
                viewType={viewType}
                isActionLoading={isOperatingStateLoading}
                isTemporaryTokenAuth={isTemporaryTokenAuth}
              />
            )
          }
          isOnlyMobileFooter
          removeScrollBar
        >
          <OperatingStateWizard viewType={viewType} error={error} />
        </SidePage>
      </Portal>
    </StyledVoluntarySuspension>
  );
};

export default observer(VoluntarySuspension);
