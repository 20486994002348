/** libraries */
import { FC } from 'react';
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledFooter } from '../../style';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useSettingsStore from '../../store/useSettingsStore';

const AllowAccessWithoutPasswordFooter: FC = () => {
  const {
    authStore: { isTemporaryTokenAuth },
  } = useRootStore();

  const {
    allowAccessWithoutPasswordStore: {
      isLoginWithoutPassword,
      changeIpAuthAllow,
      isLoading,
      isDisableButton,
    },
  } = useSettingsStore();

  return (
    <StyledFooter>
      <Button
        onClick={changeIpAuthAllow}
        disabled={isDisableButton || isTemporaryTokenAuth}
        loading={isLoading}
      >
        {isLoginWithoutPassword ? 'Разрешить' : 'Запретить'}
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(AllowAccessWithoutPasswordFooter);
