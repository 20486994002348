/** libraries */
import { FC, useEffect, useMemo, useState } from 'react';
import {
  H3,
  Tabs,
  Button,
  Icons,
  LeadingText,
  Text,
  defaultTheme,
  Popup,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledMyTVx2, StyledPopup } from './styles';
/** constants */
import { desktop1100, desktop940 } from '~/components/Grid/constants';
import {
  CHANNEL,
  CONNECTION,
  CardStates,
  PACKAGE_NOMINATIVE,
  SERVICE,
  ServiceNames,
} from '../../constants';
/** interfaces */
import {
  Channel,
  ChannelPackage,
  ServiceCode,
  ServiceInfoProps,
  WinkConnectionStatus,
} from '../../interfaces';
/** components */
import ChannelsTab from '../Tabs/ChannelsTab';
import PackagesTab from '../Tabs/PackagesTab';
import ServicesTab from '../Tabs/ServicesTab';
import PackageDescriptionCard from '../Cards/PackageDescriptionCard/PackageDescriptionCard';
import ChannelDescriptionCard from '../Cards/ChannelDescriptionCard/ChannelDescriptionCard';
import ViewControl from '../Services/ViewControl/ViewControl';
import Wink from '../Services/Wink/Wink';
import Portal from '~/components/Portal/Portal';
import FillableScale from '~/components/Blocks/Shared/FillableScale/FillableScale';
/** utils */
import { formatNumber, pluralizeAll } from '~/utils/utils';
import LinkWrapper from '~/components/LinkWrapper';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMyTVX2Store from '../../stores/MyTvX2Store';

const MyTVx2: FC = () => {
  const {
    tvStore: {
      connectedTVPackages,
      connectedChannelsInPackages,
      channelsList,
      transformerInfo,
      channelsOutsidePackages,
      winkInfo,
      viewControlInfo,
      editOrderSum,
    },
    summaryDataStore: { transformationLimit },
  } = useRootStore();

  const {
    activeChannelCard,
    setActiveChannelCard,
    cardState,
    setCardState,
    activePackageCard,
    setActivePackageCard,
    isPopupOpen,
    setIsPopupOpen,
  } = useMyTVX2Store();

  /** Индекс активного таба */
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  /** Табы */
  const tabs = [
    {
      value: isDesktop1100 && 'Пакеты',
      icon: !isDesktop1100 && <Icons.PackagesIcon white />,
      inactiveIcon: !isDesktop1100 && <Icons.PackagesIcon />,
    },
    {
      value: isDesktop1100 && 'Каналы',
      icon: !isDesktop1100 && <Icons.ChannelsIcon white />,
      inactiveIcon: !isDesktop1100 && <Icons.ChannelsIcon />,
    },
    {
      value: isDesktop1100 && 'Сервисы',
      icon: !isDesktop1100 && <Icons.ServicesIcon white />,
      inactiveIcon: !isDesktop1100 && <Icons.ServicesIcon />,
    },
  ];

  /** Обработчик клика на карточку сервиса  */
  const onClickServiceCard = (service): void => {
    const { name } = service;
    switch (name) {
      case ServiceNames.VIEW_CONTROL:
        setCardState(CardStates.VIEW_CONTROL_SERVICE);
        break;
      case ServiceNames.WINK:
        setCardState(CardStates.WINK_SERVICE);
        break;
      default:
    }
    setIsPopupOpen(true);
  };

  /** Каналы в пакетах */
  const connnectedChannels = useMemo(() => {
    if (connectedChannelsInPackages?.length && channelsList?.length) {
      return channelsList.filter((item) =>
        connectedChannelsInPackages.includes(item.weburgId ?? item.id),
      );
    }
    return [];
  }, [channelsList, connectedChannelsInPackages]);

  /** Все подключённые каналы */
  const channels = [...connnectedChannels, ...channelsOutsidePackages];

  /** Подключённые сервисы */
  const services: ServiceInfoProps[] = useMemo(() => {
    const arr = [];
    if (viewControlInfo?.isOrdered)
      arr.push({
        name: 'Управление просмотром',
        code: ServiceCode.viewControl,
      });
    if (winkInfo?.accountStatus === WinkConnectionStatus.activated)
      arr.push({ name: 'Wink', code: ServiceCode.wink });
    return arr;
  }, [viewControlInfo, winkInfo]);

  const notConnected = () => {
    const name = () => {
      switch (activeTabIndex) {
        case 0:
          return 'пакеты';
        case 1:
          return 'каналы';
        default:
          return 'сервисы';
      }
    };
    const upperCaseName = name()[0].toUpperCase() + name().slice(1);
    return (
      <div className="my-tv__not-connected">
        <LeadingText color={defaultTheme.colors.black}>
          {upperCaseName} не&nbsp;подключены, но&nbsp;это легко исправить!
        </LeadingText>
        <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
          Подключите {name()} и&nbsp;наслаждайтесь качественным телевидением
        </Text>
      </div>
    );
  };

  /* Возвращает компонент при переключении табов */
  const getTabContent = (tabIndex: number): JSX.Element => {
    switch (tabIndex) {
      case 0:
        return !connectedTVPackages?.length ? (
          notConnected()
        ) : (
          <PackagesTab
            tvChannelPackages={
              (connectedTVPackages as unknown) as ChannelPackage[]
            }
          />
        );
      case 1:
        return !channels?.length ? (
          notConnected()
        ) : (
          <ChannelsTab
            formatFilteredChannelList={[
              {
                name: '',
                channels,
              },
            ]}
          />
        );
      case 2:
        return !services?.length ? (
          notConnected()
        ) : (
          <ServicesTab
            serviceInfo={services}
            onClickServiceCard={onClickServiceCard}
          />
        );
      default:
        return <></>;
    }
  };

  /** Текст кнопки */
  const connectText = () => {
    return isDesktop940 && !isDesktop1100 ? 'Подключить' : 'Подключить ещё';
  };

  const zeroingPopup = () => {
    setCardState(null);
    setIsPopupOpen(false);
    setActivePackageCard(null);
    setActiveChannelCard(null);
  };

  const popupTitle = () => {
    if (cardState === CardStates.PACKAGE_DESCRIPTION)
      return `Пакет каналов ${activePackageCard?.channelPackName}`;
    return undefined;
  };

  const connectedCountText = () => {
    switch (activeTabIndex) {
      case 0:
        return `${pluralizeAll(
          connectedTVPackages?.length,
          PACKAGE_NOMINATIVE,
          CONNECTION,
        )}`;
      case 1:
        return `${pluralizeAll(channels?.length, CHANNEL, CONNECTION)}`;
      default:
        return `${pluralizeAll(services?.length, SERVICE, CONNECTION)}`;
    }
  };

  /** Изменение активной карточки при подключении/отключении пакета */
  useEffect(() => {
    if (activePackageCard) {
      const activePackage = connectedTVPackages.find((item) => {
        if (activePackageCard?.id) {
          return item.channelPackId === activePackageCard.id;
        }
        return item.channelPackId === activePackageCard.channelPackId;
      });
      if (activePackage) setActivePackageCard(activePackage);
    }
  }, [connectedTVPackages]);

  /** Изменение активной карточки при подключении/отключении канала */
  useEffect(() => {
    if (
      activeChannelCard?.isChannelWrapper ||
      (activeChannelCard?.singleChannelPacks?.length &&
        activeChannelCard?.singleChannelPacks[0])
    ) {
      const activeChannel = channelsList.find((item) => {
        if (activeChannelCard.isChannelWrapper) {
          return item.channelPackId === activeChannelCard.channelPackId;
        }
        return item?.weburgId && activeChannelCard?.weburgId
          ? item.weburgId === activeChannelCard.weburgId
          : item.id === activeChannelCard.id;
      });
      if (activeChannel)
        setActiveChannelCard((activeChannel as unknown) as Channel);
    }
  }, [channelsList]);

  useEffect(() => {
    setCardState(null);
  }, []);

  /** Текст под шкалой */
  const textUnderScale = useMemo(() => {
    if (transformationLimit > editOrderSum) {
      return `доступно ${formatNumber(
        transformationLimit - editOrderSum,
      )} ₽ для трансформации`;
    }
    return `${formatNumber(
      editOrderSum - transformationLimit,
    )} ₽ в день за доп. каналы`;
  }, [editOrderSum, transformationLimit]);

  return (
    <StyledMyTVx2>
      <div>
        <div className="my-tv__header">
          <H3>Моё ТВ</H3>
          <div className="my-tv__header__tabs">
            <Tabs
              valueWithIcons={tabs}
              onChange={(tabIndex) => setActiveTabIndex(tabIndex)}
              activeTabIndex={activeTabIndex}
              width="650px"
            />
          </div>
          <div className="my-tv__header__button">
            <LinkWrapper href="/tv/channels">
              <Button>{connectText()}</Button>
            </LinkWrapper>
          </div>
        </div>
        {(connectedTVPackages?.length > 0 ||
          channels?.length > 0 ||
          services?.length > 0) && (
          <div className="my-tv__scale-block">
            <LeadingText
              className={`${
                transformerInfo &&
                activeTabIndex !== 2 &&
                'my-tv__scale-block__count'
              }`}
              color={defaultTheme.colors.shadow}
            >
              {connectedCountText()}
            </LeadingText>
            {transformerInfo && activeTabIndex !== 2 && (
              <FillableScale
                value={editOrderSum}
                maxValue={transformationLimit}
              >
                <Text className="text" lineHeight="24px">
                  {textUnderScale}
                </Text>
              </FillableScale>
            )}
          </div>
        )}
        <div className="my-tv__tab-block">{getTabContent(activeTabIndex)}</div>
      </div>
      <Portal>
        <StyledPopup>
          <Popup
            show={isPopupOpen}
            title={popupTitle()}
            onCloseClick={zeroingPopup}
            onOutsideClick={zeroingPopup}
            width={(isDesktop940 && '832px') || undefined}
          >
            {cardState === CardStates.PACKAGE_DESCRIPTION && (
              <PackageDescriptionCard onClickServiceCard={onClickServiceCard} />
            )}
            {cardState === CardStates.CHANNEL_DESCRIPTION && (
              <ChannelDescriptionCard />
            )}
            {cardState === CardStates.VIEW_CONTROL_SERVICE && <ViewControl />}
            {cardState === CardStates.WINK_SERVICE && <Wink />}
          </Popup>
        </StyledPopup>
      </Portal>
    </StyledMyTVx2>
  );
};

export default observer(MyTVx2);
