/** libraries */
import { FC, useState } from 'react';
import Image from 'next/image';
import { Button, H2, SidePage, Snoska, Text } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** interfaces */
import { ProlongationProps } from '../../interfaces';
import { ProlongationProps as ProlongationInfoProps } from '../../../Contract/types';
import { ResultProps } from '../../../Devices/interfaces';
/** styles */
import { StyledProlongationNotification } from './styles';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { formatNumber } from '~/utils/utils';
import Portal from '~/components/Portal/Portal';
/** api */
import { getProlongationAgreement, prolongate } from '~/api/apiPab2c';
/** components */
import AnnualProductRenewalWizard from '~/components/Blocks/Shared/AnnualProductRenewalWizard/AnnualProductRenewalWizard';
import AnnualProductRenewalWizardFooter from '~/components/Blocks/Shared/AnnualProductRenewalWizard/AnnualProductRenewalWizardFooter';
/** constants */
import { DEFAULT_RESULT } from '../../../Devices/constants';
import { desktop370, desktop940 } from '~/components/Grid/constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface ProlongationNotificationProps {
  prolongation: ProlongationProps;
  prolongationInfo: ProlongationInfoProps;
}

/** Баннер пролонгации годового продукта */
const ProlongationNotification: FC<ProlongationNotificationProps> = ({
  prolongation,
  prolongationInfo,
}: ProlongationNotificationProps) => {
  const {
    summaryDataStore: { seriesName },
  } = useRootStore();

  const { text, image, legal } = prolongation;

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  /** Флаг продления годового продукта */
  const [isAnnualProductRenewal, setIsAnnualProductRenewal] = useState<boolean>(
    false,
  );

  /** Соглашение */
  const [agreement, setAgreement] = useState<string>('');

  /** Загрузка соглашения */
  const [isAgreementLoading, setIsAgreementLoading] = useState<boolean>(false);

  // Флаг отображения результатов и успешности операции
  const [result, setResult] = useState<ResultProps>(DEFAULT_RESULT);

  /** Получает соглашение */
  const getAgreement = async () => {
    setIsAgreementLoading(true);
    try {
      const res = await getProlongationAgreement();
      setAgreement(res);
      setIsAnnualProductRenewal(true);
      setIsAgreementLoading(false);
    } catch (e) {
      setIsAgreementLoading(false);
    }
  };

  /** Загрузка пролонгации */
  const [isLoadingProlongate, setIsLoadingProlongate] = useState<boolean>(
    false,
  );

  const prolongateProduct = async () => {
    setIsLoadingProlongate(true);
    try {
      const res = await prolongate();
      if (res.statusCode === 'Success') {
        setResult({
          isResult: true,
          isCorrect: true,
          text: `Ура! Вы продлили продукт ${seriesName}`,
        });
      } else {
        setResult({
          isResult: true,
          isCorrect: false,
        });
      }
      setIsLoadingProlongate(false);
    } catch (e) {
      setResult({
        isResult: true,
        isCorrect: false,
      });
      setIsLoadingProlongate(false);
    }
  };

  const zeroing = () => {
    setIsAnnualProductRenewal(false);
    setResult(DEFAULT_RESULT);
  };

  // Вычисление ширины экрана
  const isDesktop360 = useMediaQuery({
    query: `(min-width: ${desktop370}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const getObjectFit = () => {
    const isDesktop = isDesktop940;
    if (isDesktop) return 'fill';
    if (!isDesktop) return 'contain';
    return 'cover';
  };

  return (
    <StyledProlongationNotification>
      <Text className="prolongation__info" lineHeight="24px">
        {parseHtml(text)}
      </Text>
      <div className="prolongation__image">
        <Image
          loader={imageLoader}
          src={`${process.env.STATIC_SERVER}${image}`}
          alt="mic"
          layout="fill"
          objectFit={getObjectFit()}
          objectPosition="bottom"
          quality={100}
        />
      </div>
      <div className="prolongation__price-button">
        <H2>{`${formatNumber(prolongationInfo.prolongation.price)} ₽`}</H2>
        <Button onClick={getAgreement} loading={isAgreementLoading}>
          {isDesktop360 ? 'Продлить продукт' : 'Продлить'}
        </Button>
      </div>
      <Snoska>{parseHtml(legal)}</Snoska>
      <Portal>
        <SidePage
          show={isAnnualProductRenewal}
          width="832px"
          headerText={
            !result.isResult ? `Продление продукта ${seriesName}` : undefined
          }
          onCloseClick={zeroing}
          footerContainer={
            !result.isResult && (
              <AnnualProductRenewalWizardFooter
                isAgreement={!!agreement}
                prolongateProduct={prolongateProduct}
                isLoadingProlongate={isLoadingProlongate}
                prolongationInfo={prolongationInfo}
              />
            )
          }
          isOnlyMobileFooter
          removeScrollBar
        >
          <AnnualProductRenewalWizard
            agreement={agreement}
            seriesName={seriesName}
            result={result}
            prolongationInfo={prolongationInfo}
          />
        </SidePage>
      </Portal>
    </StyledProlongationNotification>
  );
};

export default observer(ProlongationNotification);
