/** библиотеки */
import React, { FC, useMemo, useState } from 'react';
import { formatNumber, isExternal } from '~/utils/utils';
import {
  Button,
  H2,
  H3,
  Icon,
  Icons,
  LinkButton,
  OutsideClickHelper,
  Select,
  SidePage,
  Snoska,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';
/** типы */
import {
  DeviceDetailedCardProps,
  ManualsProps,
} from '~/components/Blocks/Templates/Devices/interfaces';
import { OptionProp } from 'cordis-core-ui-planeta/dist/components/Select/interfaces';
/** константы */
import { DEFAULT_IMAGE } from '~/components/Blocks/Shared/DevicesCard/constants';
import {
  CONTACTS_LINK,
  DEFAULT_ERROR,
  DEMAND_CAUSE,
  DEMAND_REQUEST_ERRORS,
  OPERATING_STATE,
  WORKFLOW_IDS,
} from '~/constants/common';
import { TABS_DEVICE, NO_OWNERSHIP_STB_ID } from './DeviceTabs/constants';
import { desktop1100 } from '~/components/Grid/constants';
import {
  RESULT_TEXT,
  TOO_MUCH_REQUESTS_ERROR_TEXT,
  QP_DEVICE,
} from './constants';
import { CONTACT_TYPES } from '../Pab2c/Settings/constants';
/** стилевые компоненты */
import {
  StyledColorBullet,
  StyledDetailedDevice,
  StyledDeviceDetailedCardImage,
  StyledFailureSP,
} from '~/components/Blocks/Templates/Devices/style';
import { StyledIconLink } from '../Pab2c/Devices/DeviceCardWizard/style';
/* Утилиты */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { maskPhone } from '../Pab2c/Help/utils';
import LinkWrapper from '~/components/LinkWrapper';
/** api */
import {
  createDemand,
  getContactValueClasses,
  getContacts,
} from '~/api/apiPab2c';
/** components */
import Portal from '~/components/Portal/Portal';
/* stores */
import { useRootStore } from '~/stores/RootStore';
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
import useCallbackStore from '~/components/CallbackWizard/store/useCallbackStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

const DeviceDetailedCard: FC<DeviceDetailedCardProps> = ({
  item,
  setResult,
  setPhone,
}: DeviceDetailedCardProps) => {
  const {
    contractStateStore: { contractState },
    authStore: { isAuth, auth },
  } = useRootStore();
  const { toggleConnectionWizardVisible } = useConnectionStore();
  const { toggleCallbackWizardVisible } = useCallbackStore();
  const { toggleVisible } = useMakeAuthStore();
  const { annuity, price, ownershipPriceOn, id } = item;
  const router = useRouter();

  /** Ширина сайта */
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  /** Договор бездействует */
  const isZombie =
    contractState === OPERATING_STATE.DREGS ||
    contractState === OPERATING_STATE.STRAY;

  /** Select способа покупки */
  const [select, setSelect] = useState<OptionProp>({
    label: '',
    value: '',
  });

  // Возвращает массив табов
  const generateTabs = (): OptionProp[] => {
    let newTabs = [];
    if (price !== null) {
      newTabs.push(TABS_DEVICE.BUY);
    }
    // Исключение телеприставок UHD300X и Q-Box Ultra Wi-Fi, так как не могут быть предоставлены в пользование
    if (
      ownershipPriceOn !== null &&
      id !== NO_OWNERSHIP_STB_ID.UHD300X &&
      id !== NO_OWNERSHIP_STB_ID.QBOXULTRAWIFI
    ) {
      newTabs.push(TABS_DEVICE.USE);
    }
    if (annuity !== null) {
      newTabs.push(TABS_DEVICE.ANNUITY);
    }
    newTabs = newTabs.map((tab) => {
      const value = () => {
        switch (tab) {
          case TABS_DEVICE.BUY:
            return item.price;
          case TABS_DEVICE.USE:
            return item.ownershipPriceOn;
          case TABS_DEVICE.ANNUITY:
            return item.annuity;
          default:
            return item.price;
        }
      };
      return {
        value: value(),
        label: tab,
      };
    });

    if (!select.value) setSelect(newTabs[0]);
    return newTabs;
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  /** Заказать оборудование */
  const orderEquipment = async () => {
    setIsLoading(true);
    try {
      const [contacts, contactTypes] = await Promise.all([
        getContacts(),
        getContactValueClasses(),
      ]);
      const phoneContactTypeId = contactTypes.find(
        (type) => type.code === CONTACT_TYPES.PHONE,
      ).id;
      const emailContactTypeId = contactTypes.find(
        (type) => type.code === CONTACT_TYPES.EMAIL,
      ).id;
      const { phones, emails } = contacts.reduce(
        (acc, contact) => {
          if (
            contact.contactValueClassId === phoneContactTypeId &&
            !acc.phones.length
          ) {
            acc.phones.push(maskPhone(contact.value));
          } else if (
            contact.contactValueClassId === emailContactTypeId &&
            !acc.emails.length
          ) {
            acc.emails.push(contact.value);
          }
          return acc;
        },
        { phones: [], emails: [] },
      );
      setPhone(phones[0].label);
      const res = await createDemand(
        WORKFLOW_IDS.CONTACT_THE_CUSTOMER,
        'Заявка из раздела «Оборудование»',
        `Номер договора: ${auth.contractName}. ${item.typeName} ${
          item.vendorName
        } ${item.name}. ${select.label}. Цена: ${
          select?.value ?? item.price
        } руб.${select.label === TABS_DEVICE.BUY ? '' : ' в день.'} ${
          colorSelect?.value ? `Цвет: ${colorSelect?.label}.` : ''
        } ${
          select.label === TABS_DEVICE.USE
            ? 'Модель предоставляемого в пользование оборудования может быть заменена на аналогичную.'
            : ''
        }`,
        auth.clientName,
        phones.length ? phones[0].value : null,
        emails.length ? emails[0] : null,
        DEMAND_CAUSE.ADDITIONAL_SERVICES,
      );
      setResult({
        isResult: true,
        isCorrect: res.status === 200,
      });
    } catch (e) {
      const err = e?.errorMessage ? JSON.parse(e.errorMessage) : null;
      if (err?.Type === DEMAND_REQUEST_ERRORS.TOO_MUCH_REQUESTS) {
        setError(DEMAND_REQUEST_ERRORS.TOO_MUCH_REQUESTS);
        return;
      }
      setError(DEFAULT_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  // Табы оборудования
  const deviceTabs = useMemo(() => {
    return generateTabs();
  }, [item]);

  /** Цвета оборудования */
  const colorTabs = useMemo(() => {
    if (!item?.colors?.length) return [];
    return item.colors.map((color) => {
      return {
        value: color.hex,
        label: color.name,
        leftIcon: <StyledColorBullet hex={color.hex} />,
      };
    });
  }, [item]);

  /** Select способа покупки */
  const [colorSelect, setColorSelect] = useState<OptionProp>(colorTabs[0]);

  // Ссылка на изображение
  const getImageSrc = (): string => {
    const colorDeviceImage = colorSelect?.value
      ? item.colors.find((colorItem) => colorItem.hex === colorSelect?.value)
          .image
      : null;
    if (!item.images && !colorDeviceImage)
      return `${process.env.STATIC_SERVER}${item.images || DEFAULT_IMAGE}`;

    const img = colorDeviceImage ?? item.images;
    return isExternal(img) ? img : `${process.env.STATIC_SERVER}${img}`;
  };

  /** Руководство */
  const showDocuments = (manual: ManualsProps): JSX.Element => {
    const link = isExternal(manual.link)
      ? manual.link
      : `${process.env.STATIC_SERVER}${manual.link}`;

    return (
      <StyledIconLink className="devices__manual" key={manual.link}>
        <LinkWrapper href={link} target="_blank">
          <Icon icon={<Icons.DocumentIcon />} />
          <span>{manual.name}</span>
        </LinkWrapper>
      </StyledIconLink>
    );
  };

  /** Открыть СП визарда подключения с карточки оборудования */
  const showConnectionWizard = () => {
    router.replace(
      {
        query: {
          ...router.query,
          [QP_DEVICE.NAME]: item.name,
          [QP_DEVICE.PRICE]: select.value,
          [QP_DEVICE.IS_BUY]: select.label === TABS_DEVICE.BUY,
        },
      },
      undefined,
      {
        shallow: true,
      },
    );
    toggleConnectionWizardVisible();
  };

  return (
    <>
      <StyledDetailedDevice>
        <div className="devices__block">
          <StyledDeviceDetailedCardImage imgSrc={getImageSrc()} />
          <div className="devices__block__right">
            {!item.archive && !item?.isNotAvailable && (
              <>
                {colorTabs.length > 0 && (
                  <Select
                    className="devices__block__right__colors"
                    width="214px"
                    onOptionClick={(option) => setColorSelect(option)}
                    value={colorSelect?.value}
                    outsideClickHelper={OutsideClickHelper()}
                    data={colorTabs}
                  />
                )}
                {deviceTabs.length > 1 && (
                  <Select
                    width="214px"
                    onOptionClick={(option) => setSelect(option)}
                    value={select?.value}
                    outsideClickHelper={OutsideClickHelper()}
                    data={deviceTabs}
                  />
                )}
                {deviceTabs.length === 1 && (
                  <Text>
                    {select.label === TABS_DEVICE.BUY &&
                      'Доступна только покупка'}
                    {select.label === TABS_DEVICE.USE &&
                      'Доступно только приобретение в пользование'}
                    {select.label === TABS_DEVICE.ANNUITY &&
                      'Доступна только рассрочка'}
                  </Text>
                )}
                <div className="devices__price">
                  <H3>
                    {select.label === TABS_DEVICE.BUY && (
                      <>{formatNumber(select.value)} &#8381;</>
                    )}
                    {select.label === TABS_DEVICE.USE && (
                      <>
                        {isAuth ? '' : 'от'} {formatNumber(select.value)} ₽ в
                        день{isAuth ? '' : '*'}
                      </>
                    )}
                    {select.label === TABS_DEVICE.ANNUITY && (
                      <>{formatNumber(select.value)} &#8381; в день</>
                    )}
                  </H3>
                  {select.label === TABS_DEVICE.ANNUITY && (
                    <Snoska className="devices__price-snoska">
                      на 12 месяцев, 0 ₽ первоначальный взнос
                    </Snoska>
                  )}
                  {select.label === TABS_DEVICE.USE &&
                    !!item.ownershipOneTimeCharge && (
                      <Snoska className="devices__price-snoska">
                        Единовременный платеж {item.ownershipOneTimeCharge} ₽
                      </Snoska>
                    )}
                </div>
                {isAuth && !isZombie && (
                  <>
                    <Button
                      className="devices__button"
                      onClick={orderEquipment}
                      loading={isLoading}
                    >
                      Заказать
                    </Button>
                    {error && (
                      <Text
                        className="devices__error"
                        lineHeight="24px"
                        color={defaultTheme.colors.down}
                      >
                        {error}
                      </Text>
                    )}
                  </>
                )}
              </>
            )}
            {item.manuals?.length > 0 &&
              item.manuals.map((manual) => showDocuments(manual))}
          </div>
        </div>
        {!isAuth ? (
          <div className="devices__auth-warning">
            <Text lineHeight="24px">
              <Text
                className="devices__cursor"
                lineHeight="24px"
                color={defaultTheme.colors.planeta}
                onClick={() => toggleVisible()}
              >
                Войдите
              </Text>
              , чтобы заказать или{' '}
              <Text
                className="devices__cursor"
                lineHeight="24px"
                color={defaultTheme.colors.planeta}
                onClick={showConnectionWizard}
              >
                отправьте
              </Text>{' '}
              заявку на подключение к Планете.
            </Text>
          </div>
        ) : (
          <>
            {item.archive || item?.isNotAvailable ? (
              <div className="devices__auth-warning">
                <Text lineHeight="24px">Устройство недоступно для заказа.</Text>
              </div>
            ) : (
              <>
                {isZombie && (
                  <div className="devices__auth-warning__yellow">
                    <Text lineHeight="24px">
                      Ваш договор не&nbsp;обслуживается.{' '}
                      <LinkWrapper href={CONTACTS_LINK} target="_blank">
                        Обратитесь
                      </LinkWrapper>{' '}
                      в&nbsp;офис обслуживания или{' '}
                      <LinkButton onClick={toggleCallbackWizardVisible}>
                        позвоните нам
                      </LinkButton>
                      , чтобы восстановить его.
                    </Text>
                  </div>
                )}
              </>
            )}
          </>
        )}
        <div className="devices__content">
          {item.content && parseHtml(item.content)}
        </div>
      </StyledDetailedDevice>
      <Portal>
        <SidePage
          show={error === DEMAND_REQUEST_ERRORS.TOO_MUCH_REQUESTS}
          onCloseClick={() => setError(null)}
          removeScrollBar
        >
          <StyledFailureSP>
            <Icon icon={<Icons.NotOkBigIcon />} />
            {isDesktop1100 ? (
              <H2>{RESULT_TEXT.FAILURE}</H2>
            ) : (
              <H3>{RESULT_TEXT.FAILURE}</H3>
            )}
            <Text lineHeight="24px">{TOO_MUCH_REQUESTS_ERROR_TEXT}</Text>
          </StyledFailureSP>
        </SidePage>
      </Portal>
    </>
  );
};

export default observer(DeviceDetailedCard);
