/** libraries */
import { Dispatch, FC, Fragment, SetStateAction, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { defaultTheme, LeadingText, Text } from 'cordis-core-ui-planeta';
import { nanoid } from 'nanoid';
import {
  addDays,
  differenceInDays,
  formatISO,
  isAfter,
  parseISO,
  sub,
} from 'date-fns';
/** Components */
import Table from '../Components/Table/Table';
import Tr from '../Components/Table/Tr';
import Th from '../Components/Table/Th';
import Td from '../Components/Table/Td';
import MobileTable from '../Components/MobileTable/MobileTable';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { CATEGORIES, DAYS } from '../constants';
import { PRODUCT_CARD_STATE } from '../ProductCard/constants';
/** utils */
import { dateString } from '../utils';
import { formatNumber, pluralizeAll } from '~/utils/utils';
/** interfaces */
import {
  MoneyHistoryDataProps,
  TransformerHistory,
} from '../FinancialCalculations/interfaces';
/** styles */
import { StyledWriteOffs } from './styles';

interface DailyWriteOffsProps {
  data: MoneyHistoryDataProps;
  setCardState: Dispatch<SetStateAction<PRODUCT_CARD_STATE>>;
  setStartOfFinancialSettlementPeriod: Dispatch<SetStateAction<string>>;
  setEndOfFinancialSettlementPeriod: Dispatch<SetStateAction<string>>;
  transformerHistory: TransformerHistory[];
  setTRLimitData: Dispatch<SetStateAction<TransformerHistory>>;
  setBasicPackageTariffId: Dispatch<SetStateAction<number>>;
}

/* Ежедневные списания */
const DailyWriteOffs: FC<DailyWriteOffsProps> = ({
  data,
  setCardState,
  setStartOfFinancialSettlementPeriod,
  setEndOfFinancialSettlementPeriod,
  transformerHistory,
  setTRLimitData,
  setBasicPackageTariffId,
}: DailyWriteOffsProps) => {
  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const writeOffs = data.products.reduceRight((acc, item) => {
    const prevLines = acc.length
      ? acc.reduce((accum, all) => {
          accum.push(...all.lines);
          return accum;
        }, [])
      : [];

    const lines = data.lines.filter((line) => {
      return (
        !prevLines.includes(line) &&
        isAfter(parseISO(line.fromDate), sub(parseISO(item.date), { days: 1 }))
      );
    });

    /** По категориям */
    const linesByCategories = Object.values(CATEGORIES).map((category) => {
      switch (category) {
        case CATEGORIES.ProductCosts:
          return lines.filter(
            (line) => line.lineCode === 'dayTax' || line.lineCode === 'night',
          );
        default:
          return [];
      }
    });

    if (lines.length) {
      acc.push({
        product: item,
        lines,
      });
    }
    return acc;
  }, []);

  /** Генерация ключей для списка */
  const ids = useMemo(() => writeOffs.map(() => nanoid(5)), [writeOffs]);

  const amount = useMemo(() => {
    return writeOffs.reduce((acc, item) => {
      return (
        acc +
        item.lines.reduce((accum, line) => {
          return accum + line.sum;
        }, 0)
      );
    }, 0);
  }, writeOffs);

  if (!data.lines?.length) {
    return (
      <StyledWriteOffs>
        <Text lineHeight="24px">Нет данных за выбранный период</Text>
      </StyledWriteOffs>
    );
  }

  /** Клик на "Базовый продукт" или "Превышение лимита" */
  const lineClick = (item, isBase, isTransformerLimit, trProduct) => {
    if (isTransformerLimit) {
      setTRLimitData(trProduct);
      setStartOfFinancialSettlementPeriod(item.fromDate);
      setEndOfFinancialSettlementPeriod(item.trimDate);
      setCardState(PRODUCT_CARD_STATE.TRANSFORM_LIMIT_EXCEEDED);
    }
    if (isBase) {
      setBasicPackageTariffId(item.tariffId);
      setCardState(PRODUCT_CARD_STATE.BASIC_PACKAGE);
    }
  };

  return (
    <StyledWriteOffs>
      {isDesktop940 ? (
        <Table>
          <thead>
            <Tr>
              <Th width={260}>Услуга</Th>
              <Th textAlign="right">Количество</Th>
              <Th textAlign="right">Цена, ₽</Th>
              <Th textAlign="right" width={202}>
                Сумма, ₽
              </Th>
            </Tr>
          </thead>
          <tbody>
            {writeOffs.reverse().map((product, index) => {
              return (
                <Fragment key={ids[index]}>
                  <Tr backgroundColor={defaultTheme.colors.light}>
                    <Td>
                      <Text lineHeight="24px" fontWeightBold>
                        Продукт «{product.product.tariffName}»
                      </Text>
                    </Td>
                    <Td>&nbsp;</Td>
                    <Td>&nbsp;</Td>
                    <Td>
                      <Text lineHeight="24px" color={defaultTheme.colors.gray}>
                        действует с&nbsp;
                        {dateString(product.product.date, 'dd MMM yyyy')}
                      </Text>
                    </Td>
                  </Tr>
                  {product.lines.map((item) => {
                    const trim = isAfter(parseISO(item.trimDate), new Date())
                      ? formatISO(addDays(new Date(), 1))
                      : item.trimDate;
                    const days = differenceInDays(
                      parseISO(trim),
                      parseISO(item.fromDate),
                    );
                    const isBase = item.caption === 'Базовый пакет';
                    const trProduct = transformerHistory.find((tr) => {
                      return (
                        tr.tariffId === item.tariffId &&
                        isAfter(
                          addDays(parseISO(item.fromDate), 1),
                          parseISO(tr.dateFrom),
                        )
                      );
                    });
                    const isTransformerLimit =
                      trProduct && item.lineCode === 'transformer.overflow';

                    const stringDays = () => {
                      if (!item.fromDate && !item.trimDate) return '';
                      return `${
                        item.rate && item.sum
                          ? formatNumber(item.sum / item.rate)
                          : formatNumber(days)
                      } ${item.unitName === '-' ? '' : item.unitName}`;
                    };
                    return (
                      <Fragment key={item.lineCode}>
                        <Tr>
                          <Td>
                            <Text
                              className={`${
                                isTransformerLimit || isBase ? 'cursor' : ''
                              }`}
                              lineHeight="24px"
                              color={
                                isTransformerLimit || isBase
                                  ? defaultTheme.colors.planeta
                                  : defaultTheme.colors.black
                              }
                              onClick={() =>
                                lineClick(
                                  item,
                                  isBase,
                                  isTransformerLimit,
                                  trProduct,
                                )
                              }
                            >
                              {item.caption}
                            </Text>
                          </Td>
                          <Td textAlign="right">{stringDays()}</Td>
                          <Td textAlign="right">
                            {formatNumber(item.rate, false, ',', true)}
                          </Td>
                          <Td textAlign="right">
                            {formatNumber(item.sum, false, ',', true)}
                          </Td>
                        </Tr>
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
            <Tr backgroundColor={defaultTheme.colors.light}>
              <Td verticalAlign="top">
                <Text lineHeight="24px" fontWeightBold>
                  Итого за период
                </Text>
              </Td>
              <Td>&nbsp;</Td>
              <Td>&nbsp;</Td>
              <Td textAlign="right" verticalAlign="top">
                <Text lineHeight="24px" fontWeightBold>
                  {formatNumber(amount, false, ',', true)}
                </Text>
              </Td>
            </Tr>
          </tbody>
        </Table>
      ) : (
        <>
          {writeOffs.reverse().map((product, index) => {
            return (
              <Fragment key={ids[index]}>
                <MobileTable
                  products={[
                    {
                      name: product.product.tariffName,
                      date: product.product.date,
                    },
                  ]}
                  content={{
                    // header: 'Категория',
                    lines: product.lines.map((line) => {
                      const isBase = line.caption === 'Базовый пакет';
                      const trProduct = transformerHistory.find((tr) => {
                        return (
                          tr.tariffId === line.tariffId &&
                          isAfter(
                            addDays(parseISO(line.fromDate), 1),
                            parseISO(tr.dateFrom),
                          )
                        );
                      });
                      const isTransformerLimit =
                        trProduct && line.lineCode === 'transformer.overflow';
                      const days = differenceInDays(
                        parseISO(line.trimDate),
                        parseISO(line.fromDate),
                      );
                      return {
                        amount: line.sum,
                        info: line.caption,
                        comment: `${line?.rate ?? 0} ₽ x ${pluralizeAll(
                          line.rate && line.sum
                            ? Math.round(line.sum / line.rate)
                            : days,
                          DAYS,
                        )}`,
                        amountColor: defaultTheme.colors.black,
                        lineClick:
                          isTransformerLimit || isBase
                            ? () =>
                                lineClick(
                                  line,
                                  isBase,
                                  isTransformerLimit,
                                  trProduct,
                                )
                            : undefined,
                      };
                    }),
                  }}
                />
              </Fragment>
            );
          })}
          <div className="finance__table__mobile__total">
            <Text
              className="finance__table__mobile__total__header"
              lineHeight="24px"
            >
              ВСЕГО ЗА ПЕРИОД
            </Text>
            <br />
            <LeadingText color={defaultTheme.colors.black}>
              {formatNumber(amount, false, ',', true)} ₽
            </LeadingText>
          </div>
        </>
      )}
    </StyledWriteOffs>
  );
};

export default DailyWriteOffs;
