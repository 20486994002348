/** библиотеки */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
/** стилевые компоненты */
import { StyledPayment } from './style';
/** компоненты */
import PaymentForm from './PaymentForm';
import PaymentX2Form from './PaymentX2Form';
/* Утилиты */
import useWindowSize from '~/utils/useWindowSize';
import { HooksTyping } from '~/utils/typeScriptHelpers';
/** типы */
import { PaymentProps } from './types';
import { PAYMENT_TYPE, PromoProps } from '~/interfaces/PromoInterface';
/** константы */
import {
  desktop940,
  desktop1100,
  desktop1280,
  blockWidth400,
} from '~/components/Grid/constants';
import {
  CHECK_PAYMENT_SLUG,
  NO_PROMOTIONS,
  PAYMENT_BLOCK_ID,
} from './constants';
/** api */
import { getPaymentPromoInfo, setPromoAnswer } from '~/api/api';

/**
 * Блок "Оплата. Пополнение баланса"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=539432850
 * @param content
 */
const Payment: React.FC<PaymentProps> = ({ content }: PaymentProps) => {
  const router = useRouter();
  const [width] = useWindowSize();
  // Ширина блока
  const [blockWidth, setBlockWidth] = useState<number>(blockWidth400);
  // Размер блока
  const size2 = content.size === 2;

  const redirectToCheckPayment = (isCorrect?: boolean) => {
    const routerAmount = router?.query?.amount
      ? `&amount=${router.query?.amount}`
      : '';

    router.push(
      `/${CHECK_PAYMENT_SLUG}?isCorrect=${isCorrect}${routerAmount}`,
      `/${CHECK_PAYMENT_SLUG}?isCorrect=${isCorrect}${routerAmount}`,
    );
  };

  // Вычисление ширины блока
  useEffect(() => {
    switch (true) {
      case (width <= 445 && width > 430) || width >= desktop1280:
        if (blockWidth !== 400) setBlockWidth(400);
        break;
      case (width <= 430 && width > 360) ||
        (width < desktop1280 && width > desktop1100):
        if (blockWidth !== 340) setBlockWidth(340);
        break;
      case width <= 360 || (width <= desktop1100 && width >= desktop940):
        if (blockWidth !== 280) setBlockWidth(280);
        break;
      default:
        setBlockWidth(null);
    }
  }, [width]);

  /** Промо акции */
  const [promoListContent, setPromoListContent] = useState<PromoProps[]>([]);
  useEffect(() => {
    if (content.fields?.promo) {
      setPromoListContent(content.fields?.promo);
    }
  }, []);
  /** Список доступных акций */
  const [
    activePromotion,
    setActivePromotion,
  ]: HooksTyping<string> = useState<string>(null);

  /** Получает доступные акции по оплате */
  const getPromoInfo = async (
    contractName: string,
    amount: number,
    paymentType: PAYMENT_TYPE,
  ) => {
    try {
      const res = await getPaymentPromoInfo(contractName, amount, paymentType);
      setActivePromotion(!promoListContent[res] ? NO_PROMOTIONS : res);
    } catch (e) {
      setActivePromotion(NO_PROMOTIONS);
    }
  };

  /** Сохраняет информацию о выбранном способе оплате по акции */
  const setAnswer = async (
    contractName: string,
    amount: number,
    promoCode: string,
    paymentType: PAYMENT_TYPE,
    dontShowAgain: boolean,
  ) => {
    try {
      await setPromoAnswer(
        contractName,
        amount,
        promoCode,
        paymentType,
        dontShowAgain,
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <StyledPayment
      id={`${PAYMENT_BLOCK_ID}${content.size}`}
      hasBorder={content.fields?.hasBorder}
      size={content.size}
    >
      {!size2 ? (
        <PaymentForm
          setResult={redirectToCheckPayment}
          blockWidth={blockWidth}
          paymentStyle={content.fields?.style}
          promoListContent={promoListContent}
          activePromotion={activePromotion}
          setActivePromotion={setActivePromotion}
          getPromoInfo={getPromoInfo}
          setAnswer={setAnswer}
          cardLimit={content.fields?.cardLimit}
          sbpLimit={content.fields?.sbpLimit}
        />
      ) : (
        <PaymentX2Form
          setResult={redirectToCheckPayment}
          paymentStyle={content.fields?.style}
          promoListContent={promoListContent}
          activePromotion={activePromotion}
          setActivePromotion={setActivePromotion}
          getPromoInfo={getPromoInfo}
          setAnswer={setAnswer}
          cardLimit={content.fields?.cardLimit}
          sbpLimit={content.fields?.sbpLimit}
        />
      )}
    </StyledPayment>
  );
};

export default React.memo(Payment);
