/** libraries */
import { types, flow, Instance } from 'mobx-state-tree';
/** api */
import { getVacationInfo } from '~/api/api';
import {
  getVacationAgreement,
  vacationCancel,
  vacationStart,
  vacationStop,
} from '~/api/apiPab2c';
import createApiPathModel from '~/stores/models/createApiPathModel';
import { VACATION_STATE, VACATION_UNVAILABLE_REASON } from '../constants';

const RequestsStateModel = types.model('State', {
  getVacationInfo: createApiPathModel(
    'GET /OperatingState/OperatingState/GetVacationInfo',
  ),
  postVacationStart: createApiPathModel(
    'POST /OperatingState/OperatingState/VacationStart',
  ),
  postVacationStop: createApiPathModel(
    'POST /OperatingState/OperatingState/VacationStop',
  ),
  postVacationCancel: createApiPathModel(
    'POST /OperatingState/OperatingState/VacationCancel',
  ),
  getVacationAgreement: createApiPathModel(
    'GET /OperatingState/OperatingState/GetVacationAgreement',
  ),
});

const VacationInfoModel = types.model('VacationInfo', {
  isVacationInfo: types.maybe(types.boolean),
  scheduledDt: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  dateTrim: types.maybeNull(types.string),
  vacationState: types.maybeNull(
    types.enumeration(Object.values(VACATION_STATE)),
  ),
  unavailableReason: types.maybeNull(
    types.enumeration(Object.values(VACATION_UNVAILABLE_REASON)),
  ),
});

const VacationModel = types
  .model({
    requestsState: RequestsStateModel,
    isChecked: (types.boolean, false),
    isVacationConnectionOpen: (types.boolean, false),
    isVacationShutdownOpen: (types.boolean, false),
    agreement: (types.string, ''),
    vacationInfo: types.maybeNull(VacationInfoModel),
  })
  .views((self) => ({
    get isVacationInfo() {
      return !!self.vacationInfo;
    },
    get isActivated() {
      return self.vacationInfo?.vacationState === VACATION_STATE.activated;
    },
    get isOrdered() {
      return self.vacationInfo?.vacationState === VACATION_STATE.ordered;
    },
    get isAvailable() {
      return self.vacationInfo?.vacationState === VACATION_STATE.available;
    },
    get isUnavailable() {
      return self.vacationInfo?.vacationState === VACATION_STATE.unavailable;
    },
    get isNotEnoughMoney() {
      return (
        self.vacationInfo?.vacationState === VACATION_STATE.unavailable &&
        self.vacationInfo?.unavailableReason ===
          VACATION_UNVAILABLE_REASON.notEnoughMoney
      );
    },
    get isEnabledSuspending() {
      return (
        self.vacationInfo?.vacationState === VACATION_STATE.unavailable &&
        [VACATION_UNVAILABLE_REASON.enabledSuspending].includes(
          self.vacationInfo?.unavailableReason,
        )
      );
    },
    get isOrderSuspending() {
      return (
        self.vacationInfo?.vacationState === VACATION_STATE.unavailable &&
        [VACATION_UNVAILABLE_REASON.scheduledSuspending].includes(
          self.vacationInfo?.unavailableReason,
        )
      );
    },
    get isRemoveBlock() {
      return (
        self.vacationInfo?.vacationState === VACATION_STATE.unavailable &&
        ![
          VACATION_UNVAILABLE_REASON.enabledSuspending,
          VACATION_UNVAILABLE_REASON.notEnoughMoney,
          VACATION_UNVAILABLE_REASON.notCorrectContractState,
          VACATION_UNVAILABLE_REASON.scheduledSuspending,
        ].includes(self.vacationInfo?.unavailableReason)
      );
    },
    get activationDate() {
      return self.vacationInfo?.scheduledDt;
    },
    get price() {
      return self.vacationInfo?.price;
    },
    get dateTrim() {
      return self.vacationInfo?.dateTrim;
    },
    get isLoading() {
      return (
        self.requestsState.postVacationStart.isLoading ||
        self.requestsState.postVacationStop.isLoading ||
        self.requestsState.postVacationCancel.isLoading ||
        self.requestsState.getVacationAgreement.isLoading
      );
    },
    get isResult() {
      return (
        (!self.requestsState.postVacationStart.isLoading &&
          !self.requestsState.postVacationStop.isLoading &&
          !self.requestsState.postVacationCancel.isLoading &&
          (self.requestsState.postVacationStart.hasError ||
            self.requestsState.postVacationStop.hasError ||
            self.requestsState.postVacationCancel.hasError ||
            self.requestsState.postVacationStart.isSucceeded ||
            self.requestsState.postVacationStop.isSucceeded ||
            self.requestsState.postVacationCancel.isSucceeded)) ||
        self.requestsState.getVacationAgreement.hasError
      );
    },
    get isCorrect() {
      return (
        (self.requestsState.postVacationStart.isFetched ||
          self.requestsState.postVacationStop.isFetched ||
          self.requestsState.postVacationCancel.isFetched) &&
        !self.requestsState.getVacationAgreement.hasError
      );
    },
  }))
  .actions((self) => {
    return {
      getVacation: flow(function* () {
        self.requestsState.getVacationInfo.reset();
        self.requestsState.getVacationInfo.setLoading();
        try {
          const res = yield getVacationInfo();
          self.vacationInfo = res;
          self.requestsState.getVacationInfo.setSuccess();
        } catch (e) {
          self.requestsState.getVacationInfo.setFail();
        }
      }),
      resetVacation: () => {
        self.vacationInfo = null;
      },
      /** Заказывает услугу "Каникулы" */
      setVacationStart: flow(function* (updateVacation: () => Promise<void>) {
        self.requestsState.postVacationStart.reset();
        self.requestsState.postVacationStart.setLoading();
        try {
          yield vacationStart();
          self.isChecked = true;
          yield updateVacation();
          self.requestsState.postVacationStart.setSuccess();
        } catch (e) {
          self.requestsState.postVacationStart.setFail();
        }
      }),
      /** Отказывается от услуги Каникулы */
      setVacationStop: flow(function* (
        updateVacation: () => Promise<void>,
        updateAuthCondition?: () => Promise<void>,
      ) {
        self.requestsState.postVacationStop.reset();
        self.requestsState.postVacationStop.setLoading();
        try {
          yield vacationStop();
          self.isChecked = false;
          yield updateVacation();
          if (updateAuthCondition) yield updateAuthCondition();
          self.requestsState.postVacationStop.setSuccess();
        } catch (e) {
          self.requestsState.postVacationStop.setFail();
          self.isChecked = true;
        }
      }),
      /** Отменяет заказ услуги Каникулы */
      setVacationCancel: flow(function* (
        updateVacation: () => Promise<void>,
        updateAuthCondition?: () => Promise<void>,
      ) {
        self.requestsState.postVacationCancel.reset();
        self.requestsState.postVacationCancel.setLoading();
        try {
          yield vacationCancel();
          self.isChecked = false;
          yield updateVacation();
          if (updateAuthCondition) yield updateAuthCondition();
          self.requestsState.postVacationCancel.setSuccess();
        } catch (e) {
          self.requestsState.postVacationCancel.setFail();
          self.isChecked = true;
        }
      }),
      getVacationAgreement: flow(function* () {
        self.agreement = '';
        self.requestsState.getVacationAgreement.reset();
        self.requestsState.getVacationAgreement.setLoading();
        try {
          const res = yield getVacationAgreement();
          self.agreement = res;
          self.requestsState.getVacationAgreement.setSuccess();
        } catch (e) {
          self.requestsState.getVacationAgreement.setFail();
        }
      }),
      setIsChecked: (checked) => {
        self.isChecked = checked;
      },
      setIsVacationConnectionOpen: (isVacationConnectionOpen) => {
        self.isVacationConnectionOpen = isVacationConnectionOpen;
      },
      setIsVacationShutdownOpen: (isVacationShutdownOpen) => {
        self.isVacationShutdownOpen = isVacationShutdownOpen;
      },
      reset: () => {
        self.requestsState.postVacationStart.reset();
        self.requestsState.postVacationStop.reset();
        self.requestsState.postVacationCancel.reset();
      },
    };
  });

export default VacationModel;
export type IVacationStore = Instance<typeof VacationModel>;
