/** библиотеки */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';
/** стили */
import { StyledContract } from './style';
/** компоненты библиотеки */
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H2,
  H3,
  Icon,
  Icons,
  LeadingText,
  LinkButton,
  Loader,
  PriceTag,
  SidePage,
  Snoska,
  Tabs,
  TabsStyleTypes,
  Text,
  PriceTagBackgroundColor,
} from 'cordis-core-ui-planeta';
/** константы */
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';
import {
  CHATLE,
  HOURS,
  OPERATING_STATE,
  PRICES_CATEGORY,
  SUSPEND_STATE,
} from './constants';
import { TV_CHANNEL } from '../MyTV/constants';

/** компоненты */
import LinkWrapper from '~/components/LinkWrapper';
import ChatleWizard from '~/components/Blocks/Templates/Pab2c/Contract/ChatleWizard/ChatleWizard';
import ChatleWizardFooter from '~/components/Blocks/Templates/Pab2c/Contract/ChatleWizard/ChatleWizardFooter';
import ProductCard from './ProductCard/ProductCard';
import Portal from '~/components/Portal/Portal';
/** утилиты */
import { HooksTyping } from '~/utils/typeScriptHelpers';
import { formatNumber, pluralizeAll, removeLeadingZero } from '~/utils/utils';
/** hooks */
import useReserveChatles from '~/components/Blocks/Templates/Pab2c/Contract/ChatleWizard/ChatleWizardHooks';
/** api */
import { startContractTrustedResume } from '~/api/api';
/** типы */
import { ProductSelectorContentProps } from './types';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useVacationStore from '../../Vacation/store/useVacationStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

/**
 * Блок «ЛК. Договор»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=597419196
 */
const Pab2cContract: FC<ProductSelectorContentProps> = ({
  content,
}: ProductSelectorContentProps): JSX.Element => {
  const { blocks } = content;
  // Для чтения параметров из url
  const router = useRouter();

  const {
    authStore: { auth, isTemporaryTokenAuth, isLoadingAuth, updateAuth },
    pab2cBalanceStore: {
      chatleBudgetSaldo,
      chatleReluxSaldo,
      moneyBalance,
      lowBalanceThreshold,
      isErrorBalance,
      isLoadingBalance,
      getBalance,
    },
    /** Данные услуги "Отложенный платёж" */
    deferredPaymentStore: {
      isActivated,
      isAvailable,
      durationHours,
      durationSecondsLeft,
      getContractTrustedResume,
    },
    contractStateStore: {
      contractState,
      suspendCondition,
      updateAuthCondition,
    },
    autoPaymentInfoStore: { autoPaymentData },
    summaryDataStore: {
      dailyPriceInfo,
      thirtyDaysPriceInfo,
      seriesName,
      marketingGroupName,
      speedBaseText,
      tags,
      isTransformer,
      seriesCode,
      channelCount,
    },
  } = useRootStore();
  const { toggleVisible } = useMakeAuthStore();
  /** Каникулы */
  const { isOrdered, activationDate } = useVacationStore();

  const {
    reserveChatles,
    chatleAmount,
    setChatleAmount,
    maxChatleAmount,
    setMaxChatleAmount,
    isChatleVisible,
    setIsChatleCompleted,
    isChatleCompleted,
    setIsChatleVisible,
    validateChatleAmount,
    errorChatleAmount,
    setErrorChatleAmount,
    isChatleLoading,
    serverError,
  } = useReserveChatles();

  /** Зарезервированные чатлы больше 0 */
  const isReservedChatles = chatleBudgetSaldo > 0;

  /** Чатлы больше 0 */
  const isChatles = chatleReluxSaldo > 0;

  /** LightBox с карточкой продукта */
  const [isPopupOpen, setIsPopupOpen]: HooksTyping<boolean> = useState<boolean>(
    false,
  );

  /** Вычисление ширины экрана */
  const isMinDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isMinDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const isMinDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  /** Ширина для компонента PriceTag */
  const priceTagWidth = (): string => {
    if (isMinDesktop1280) return '292px';
    if (isMinDesktop1100) return '247px';
    if (isMinDesktop940) return '170px';
    if (!isMinDesktop940) return '100%';
    return null;
  };

  /** Текущая дата */
  const getTodayDate = () => {
    return format(parseISO(new Date().toISOString()), 'd MMMM', {
      locale: ru,
    });
  };

  /** Оставшиеся часы */
  const [hours, setHours] = useState<number>(null);

  /** Оставшиеся минуты */
  const [minutes, setMinutes] = useState<number>(null);

  // Вычисление оставшегося времени
  const duration = () => {
    const allMinutes = durationSecondsLeft / 60;
    setHours(Math.trunc(allMinutes / 60));
    setMinutes(Math.trunc(allMinutes % 60));
  };

  useEffect(() => {
    if (isActivated) duration();
  }, [isActivated]);

  useEffect(() => {
    setMaxChatleAmount(chatleReluxSaldo);
  }, [chatleReluxSaldo]);

  useEffect(() => {
    if (isChatleCompleted && !isChatleVisible) {
      updateAuth(null, true);
      setIsChatleCompleted(false);
    }
  }, [isChatleCompleted, isChatleVisible]);

  // Вывод оставшегося времени
  const timeOutput = `${hours ? `${hours} ч` : ''} ${
    minutes ? `${minutes} мин` : ''
  }`;

  // Активация услуги "Отложенный платёж"
  const activateDeferredPayment = async () => {
    if (isTemporaryTokenAuth) return;
    try {
      const start = await startContractTrustedResume();
      if (start.status && start.status === 200) {
        getContractTrustedResume();
        updateAuthCondition();
      }
    } catch (e) {
      console.error(e);
    }
  };

  /** логика для таба цен */
  const [priceIndex, setPriceIndex]: HooksTyping<number> = useState<number>(0);

  const tabPriceInfo = useMemo(() => {
    return {
      daily: dailyPriceInfo ?? 0,
      thirtyDays: thirtyDaysPriceInfo ?? 0,
    };
  }, [dailyPriceInfo, thirtyDaysPriceInfo]);
  const pricesCategoryNames = useMemo(
    () => Object.keys(tabPriceInfo).map((name) => PRICES_CATEGORY[name]),
    [thirtyDaysPriceInfo],
  );
  const processCategoryValue = Object.values(tabPriceInfo);

  /** JSX таба цен */
  const priceTabs = (
    <div className="contract__product-block__product-card__info__price-block">
      <Tabs
        value={pricesCategoryNames}
        onChange={setPriceIndex}
        activeTabIndex={priceIndex}
        styleType={TabsStyleTypes.SECONDARY}
      />
      <H3 className="value" color={defaultTheme.colors.black}>
        {formatNumber(processCategoryValue[priceIndex])} ₽
      </H3>
    </div>
  );

  /** JSX для карточки продукта */
  const microSummaryContent = (): JSX.Element => {
    /** если включена добровольная приостановка в будущем */
    if (
      [SUSPEND_STATE.order].includes(suspendCondition?.suspendState) ||
      isOrdered
    ) {
      const date = format(
        new Date(isOrdered ? activationDate : suspendCondition?.scheduledDt),
        'd MMMM',
        {
          locale: ru,
        },
      );
      return (
        <>
          <Text
            className="contract__product-block__product-card__info__suspended-by-operator-or-client"
            lineHeight="24px"
            color={defaultTheme.colors.shadow}
          >
            Приостановка обслуживания с&nbsp;{date}
          </Text>
          {priceTabs}
        </>
      );
    }
    switch (contractState) {
      case OPERATING_STATE.ON:
        return (
          <>
            <div className="contract__product-block__product-card__info__speed-block">
              <LeadingText color={defaultTheme.colors.black}>
                {speedBaseText}
              </LeadingText>
              <Text lineHeight="24px">
                {isTransformer
                  ? 'Выбери телеканалы сам'
                  : `${pluralizeAll(channelCount, TV_CHANNEL)}`}
              </Text>
            </div>
            {priceTabs}
          </>
        );
      case OPERATING_STATE.NEW:
        return (
          <Text
            className="contract__product-block__product-card__info__connection-incomplete"
            lineHeight="24px"
            color={defaultTheme.colors.shadow}
          >
            Подключение не завершено
          </Text>
        );
      case OPERATING_STATE.PROVIDER_BLOCK_DEBT:
        return (
          <>
            <Text
              className="contract__product-block__product-card__info__suspended-by-operator-or-client"
              lineHeight="24px"
              color={defaultTheme.colors.down}
            >
              Обслуживание приостановлено оператором
            </Text>
            {priceTabs}
          </>
        );
      case OPERATING_STATE.CLIENT_BLOCK:
        return (
          <>
            <Text
              className="contract__product-block__product-card__info__suspended-by-operator-or-client"
              lineHeight="24px"
              color={defaultTheme.colors.shadow}
            >
              Обслуживание приостановлено клиентом
            </Text>
            {priceTabs}
          </>
        );
      case OPERATING_STATE.DREGS:
        return (
          <Text
            className="contract__product-block__product-card__info__out-of-service"
            lineHeight="24px"
            color={defaultTheme.colors.down}
          >
            Договор не&nbsp;обслуживается
          </Text>
        );
      case OPERATING_STATE.STRAY:
        return (
          <Text
            className="contract__product-block__product-card__info__out-of-service"
            lineHeight="24px"
            color={defaultTheme.colors.down}
          >
            Договор не&nbsp;обслуживается
          </Text>
        );
      default:
        return <></>;
    }
  };

  /** Ссылка на категорию продукта */
  const [
    marketingCategoryFeedLink,
    setMarketingCategoryFeedLink,
  ]: HooksTyping<string> = useState<string>(null);

  /** Ссылка на продукт */
  const [
    productFeedLink,
    setProductFeedLink,
  ]: HooksTyping<string> = useState<string>(null);

  /** Устанавливает ссылки для продукта */
  useEffect(() => {
    if (!blocks) return;
    const sameBlock = blocks.filter(
      (block) => block.fields.seriesCode === seriesCode,
    );
    const marketingCategoryLink = sameBlock
      .map((item) => item.fields.marketingCategoryFeedLink)
      .toString();
    const productLink = sameBlock
      .map((item) => item.fields.productFeedLink)
      .toString();

    if (marketingCategoryLink)
      setMarketingCategoryFeedLink(marketingCategoryLink);
    if (productLink) setProductFeedLink(productLink);
  }, [seriesCode]);

  const getBackgroundColor = (): PriceTagBackgroundColor => {
    if (moneyBalance > 0) {
      if (lowBalanceThreshold > moneyBalance) {
        return PriceTagBackgroundColor.WARNING;
      }

      return PriceTagBackgroundColor.OK;
    }

    return PriceTagBackgroundColor.DOWN;
  };

  const getFontColor = () => {
    if (moneyBalance > 0) {
      if (lowBalanceThreshold > moneyBalance) {
        return defaultTheme.colors.warning;
      }

      return defaultTheme.colors.black;
    }

    return defaultTheme.colors.down;
  };

  const openWizard = () => {
    router.replace(
      {
        query: { ...router.query, showAutopayment: true },
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  /** Показывать описание в priceTag */
  const isSubscription = moneyBalance > 0 && lowBalanceThreshold > moneyBalance;

  return (
    <StyledContract
      isTransformer={isTransformer}
      isSubscription={isSubscription}
    >
      {isLoadingAuth || isLoadingBalance ? (
        <div className="contract__loader">
          <Loader />
        </div>
      ) : (
        <>
          <div className="contract__header">
            <div className="contract__header__logout-block">
              <Text
                className="contract__header__logout-block__contractName"
                lineHeight="24px"
              >
                № {removeLeadingZero(auth.contractName)}
              </Text>
              <LinkButton onClick={() => toggleVisible()}>
                Другой договор
              </LinkButton>
            </div>
            <H3>{auth.clientName}</H3>
          </div>
          <div className="contract__info-block">
            <div className="contract__info-block__balance">
              {isErrorBalance ? (
                <>
                  <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                    Баланс
                  </Text>
                  <div className="contract__update-icon__balance">
                    <Icon icon={<Icons.UpdateIcon />} onClick={getBalance} />
                  </div>
                </>
              ) : (
                <div className="contract__info-block__balance__price-tag">
                  {moneyBalance !== null && (
                    <PriceTag
                      header={`Баланс на ${getTodayDate()}`}
                      value={`${formatNumber(moneyBalance)} ₽`}
                      subscription={
                        isSubscription ? `Баланс близок к${'\u00A0'}нулю` : null
                      }
                      backgroundColor={getBackgroundColor()}
                      fontColor={getFontColor()}
                      width={priceTagWidth()}
                      headerType={isMinDesktop1280 ? 'H2' : 'H3'}
                    />
                  )}
                </div>
              )}
              {!isMinDesktop940 && (
                <>
                  <LinkWrapper href="/payment">
                    <Button className="contract__info-block__balance__top-up-button">
                      Пополнить
                    </Button>
                  </LinkWrapper>
                  <Button
                    className="contract__info-block__balance__auto-payment-button"
                    onClick={openWizard}
                    styleType={ButtonStyleTypes.SECONDARY}
                  >
                    {autoPaymentData?.isActive
                      ? 'Настроить автоплатёж'
                      : 'Автоплатёж'}
                  </Button>
                </>
              )}
              {isMinDesktop940 && (
                <>
                  {moneyBalance && moneyBalance > 0 ? (
                    <LinkButton
                      className="contract__info-block__balance__auto-payment-link"
                      onClick={openWizard}
                    >
                      {autoPaymentData?.isActive ? (
                        'Настроить автоплатёж'
                      ) : (
                        <>
                          {isMinDesktop1100
                            ? 'Подключить автоплатёж'
                            : 'Автоплатёж'}
                        </>
                      )}
                    </LinkButton>
                  ) : (
                    <LinkButton
                      className="contract__info-block__balance__auto-payment-link"
                      href="/payment"
                    >
                      Пополнить баланс
                    </LinkButton>
                  )}
                </>
              )}
              <Portal>
                <SidePage
                  show={isChatleVisible}
                  width="832px"
                  headerText={
                    isChatleCompleted ? null : 'Резервирование Чатлов'
                  }
                  onCloseClick={() => {
                    setIsChatleVisible(false);
                    setChatleAmount('');
                    setErrorChatleAmount(false);
                  }}
                  footerContainer={
                    !isChatleCompleted && (
                      <ChatleWizardFooter
                        reserveChatles={reserveChatles}
                        errorChatleAmount={errorChatleAmount}
                        isChatleLoading={isChatleLoading}
                        isChatleCompleted={isChatleCompleted}
                        chatleAmount={chatleAmount}
                        isTemporaryTokenAuth={isTemporaryTokenAuth}
                      />
                    )
                  }
                  isOnlyMobileFooter
                  removeScrollBar
                >
                  <ChatleWizard
                    maxChatleAmount={maxChatleAmount}
                    chatleAmount={chatleAmount}
                    setChatleAmount={setChatleAmount}
                    reserveChatles={reserveChatles}
                    validateChatleAmount={validateChatleAmount}
                    errorChatleAmount={errorChatleAmount}
                    isChatleLoading={isChatleLoading}
                    isChatleCompleted={isChatleCompleted}
                    serverError={serverError}
                  />
                </SidePage>
              </Portal>
            </div>
            <div className="contract__info-block__chatly">
              <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                Чатлы
              </Text>
              {isErrorBalance ? (
                <div className="contract__update-icon">
                  <Icon icon={<Icons.UpdateIcon />} onClick={getBalance} />
                </div>
              ) : (
                <>
                  {isMinDesktop1280 ? (
                    <H2 className="contract__info-block__chatly__number">
                      {formatNumber(chatleReluxSaldo)}
                    </H2>
                  ) : (
                    <H3 className="contract__info-block__chatly__number">
                      {formatNumber(chatleReluxSaldo)}
                    </H3>
                  )}
                </>
              )}
              {!isMinDesktop940 && (
                <Button
                  className="contract__info-block__chatly__reservation-button"
                  styleType={ButtonStyleTypes.SECONDARY}
                  disabled={!isChatles || isOrdered}
                  onClick={() => setIsChatleVisible(!isChatleVisible)}
                >
                  Зарезервировать
                </Button>
              )}
              {isMinDesktop940 && (
                <LinkButton
                  className={`${
                    (!isChatles || isOrdered) &&
                    'contract__info-block__chatly__reservation-link-disabled'
                  }`}
                  onClick={() => setIsChatleVisible(!isChatleVisible)}
                >
                  Зарезервировать
                </LinkButton>
              )}
              {isReservedChatles && isMinDesktop940 && (
                <Snoska
                  className="contract__info-block__chatly__reservation-info-snoska"
                  color={defaultTheme.colors.shadow}
                >
                  Зарезервировано{' '}
                  {pluralizeAll(chatleBudgetSaldo, CHATLE, null, null, true)}
                </Snoska>
              )}
              {isReservedChatles && !isMinDesktop940 && (
                <Text
                  className="contract__info-block__chatly__reservation-info-text"
                  lineHeight="24px"
                  color={defaultTheme.colors.shadow}
                >
                  Зарезервировано{' '}
                  {pluralizeAll(chatleBudgetSaldo, CHATLE, null, null, true)}
                </Text>
              )}
            </div>
            {(isAvailable || (!isAvailable && isActivated)) && (
              <div className="contract__info-block__deferred-payment">
                <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                  Отложенный платёж
                </Text>
                {isMinDesktop1280 ? (
                  <H2 className="contract__info-block__deferred-payment__hours">
                    {isActivated
                      ? timeOutput
                      : pluralizeAll(durationHours, HOURS)}
                  </H2>
                ) : (
                  <H3 className="contract__info-block__deferred-payment__hours">
                    {isActivated
                      ? timeOutput
                      : pluralizeAll(durationHours, HOURS)}
                  </H3>
                )}
                {!isActivated && isAvailable && (
                  <>
                    {!isMinDesktop940 && (
                      <Button
                        className="contract__info-block__deferred-payment__button"
                        styleType={ButtonStyleTypes.SECONDARY}
                        onClick={activateDeferredPayment}
                        disabled={isTemporaryTokenAuth}
                      >
                        Активировать
                      </Button>
                    )}
                    {isMinDesktop940 && (
                      <LinkButton
                        onClick={activateDeferredPayment}
                        color={
                          isTemporaryTokenAuth
                            ? defaultTheme.colors.disable
                            : defaultTheme.colors.planeta
                        }
                      >
                        Активировать
                      </LinkButton>
                    )}
                  </>
                )}
                {isActivated && (
                  <Text
                    className="contract__info-block__deferred-payment__description"
                    lineHeight="24px"
                    color={defaultTheme.colors.shadow}
                  >
                    Активирован, до окончания
                  </Text>
                )}
              </div>
            )}
          </div>
          <div className="contract__product-block">
            <Text lineHeight="24px">Ваш продукт</Text>
            <div className="contract__product-block__product-card">
              <div className="contract__product-block__product-card__name">
                <LinkWrapper href={productFeedLink}>
                  <LeadingText
                    className="contract__product-block__product-card__name__seriesName"
                    title={seriesName}
                    color={defaultTheme.colors.white}
                  >
                    {seriesName}
                  </LeadingText>
                </LinkWrapper>
                <LinkWrapper href={marketingCategoryFeedLink}>
                  <Text
                    title={marketingGroupName}
                    lineHeight="24px"
                    color={defaultTheme.colors.pink}
                  >
                    {marketingGroupName}
                  </Text>
                </LinkWrapper>
              </div>
              <div className="contract__product-block__product-card__info">
                {microSummaryContent()}
                <Icon
                  className="contract__product-block__product-card__info__icon"
                  icon={<Icons.GrayInfoIcon />}
                  onClick={() => setIsPopupOpen(true)}
                />
                {isPopupOpen && (
                  <ProductCard
                    isPopupOpen={isPopupOpen}
                    setIsPopupOpen={setIsPopupOpen}
                    pricesCategoryNames={pricesCategoryNames}
                    processCategoryValue={processCategoryValue}
                    seriesName={seriesName}
                    marketingGroupName={marketingGroupName}
                    tags={tags}
                    productFeedLink={productFeedLink}
                    marketingCategoryFeedLink={marketingCategoryFeedLink}
                    speedBaseText={speedBaseText}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </StyledContract>
  );
};

export default observer(Pab2cContract);
