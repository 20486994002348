/** Библиотеки */
import React, { FC } from 'react';
/** Стили */
import { StyledFooter } from './ChatleWizard.style';
/** Компоненты */
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
/** Типы */
import { ChatleWizardFooterProps } from './ChatleWizard.types';

const ChatleWizardFooter: FC<ChatleWizardFooterProps> = ({
  reserveChatles,
  isChatleLoading,
  errorChatleAmount,
  chatleAmount,
  isTemporaryTokenAuth,
}) => {
  return (
    <StyledFooter className="wizard__button">
      <Button
        type="button"
        onClick={() => reserveChatles()}
        disabled={
          errorChatleAmount || !chatleAmount.length || isTemporaryTokenAuth
        }
        loading={isChatleLoading}
      >
        Зарезервировать
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default React.memo(ChatleWizardFooter);
